import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Modal from "react-modal";
import Header from "../header";
import About from "../about";
import MainBottom from "../mainBottom";
import NoImage from "../../../img/noimage.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Star from "../../../img/Star.png";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa";
import { BASE_URL, token } from "../../../component/token";
export default function MenuItems() {
  //state manage //
  const navigate = useNavigate();
  const [menuModal, setMenuModal] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [timeModal, setTimeMOdal] = useState(false);
  const [urlimage, setUrlImage] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [menuUrl, setMenuUrl] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [direction, setDirection] = useState([]);
  const [longitude, setLongitude] = useState("");
  const [resturantData, setResturantData] = useState([]);
  const [resturantNear, setResturantNear] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [modalsIsOpen, setModalsIsOpen] = useState(false);
  const [galleryModalsIsOpen, setGalleryModalsIsOpen] = useState(false);
  const [modalsLogin, setModalsLogin] = useState(false);
  const [closeResturantP, setCloseResturantP] = useState([]);
  const [closeResturantH, setCloseResturantH] = useState([]);
  const [closeResturantN, setCloseResturantN] = useState([]);
  const [popupOpen, setPopupOpen] = useState(true);
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState(false);
  const [modifiedName, setModifiedName] = useState("");
  const [isFixed, setIsFixed] = useState(false);
  const [click, setClick] = useState(false);
  const [displayText, setDisplayText] = useState(true);
  const [menuShowData, setMenuDataShow] = useState(false);
  const [gallerySHowData, setGalleryShowData] = useState(false);
  const [reviewDataShow, setReviewDataShow] = useState(false);
  const [modalsMenu, setModalsMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovereds, setIsHovereds] = useState(false);
  const [valueCity, setValueCity] = useState(false);
  const [resturantMessage, setResturantMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [reviewMessage, setReviewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [ratingVlaue, setRatingValue] = useState("");
  const [ads, setAds] = useState([]);
  const [review, setReview] = useState({
    user_review: "",
  });
  const [store, setStore] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [storeData, setStoreData] = useState({
    email: "",
    phone: "",
  });
  //function
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnters = () => {
    setIsHovereds(true);
  };
  const handleMouseLeaves = () => {
    setIsHovereds(false);
  };
  //start rating
  const handleRatingClick = (value) => {
    setRating(value);
    setRatingValue(value);
  };
  //handle menudata//
  const handleClick = () => {
    setDisplayText(true);
    setMenuDataShow(false);
    setGalleryShowData(false);
    setReviewDataShow(false);
  };
  const handleMenu = () => {
    setMenuDataShow(true);
    setDisplayText(false);
    setGalleryShowData(false);
    setReviewDataShow(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds smooth scrolling animation
    });
  };
  const handleGallery = () => {
    setGalleryShowData(true);
    setMenuDataShow(false);
    setDisplayText(false);
    setReviewDataShow(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds smooth scrolling animation
    });
  };
  const handleReview = () => {
    setReviewDataShow(true);
    setGalleryShowData(false);
    setMenuDataShow(false);
    setDisplayText(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds smooth scrolling animation
    });
  };
  //set validation of email
  const ValidateEmail = () => {
    setLoader(false);
    var validRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (validRegex.test(store.email || storeData)) {
      setLoader(true);
      setClick(false);

      return true;
    } else setClick(true);
    {
      return false;
    }
  };
  //event fire for input box
  const handleText = (e) => {
    setStore({ ...store, [e.target.name]: e.target.value });
  };
  const handleImageUpload = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  const handleData = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };
  const handleReviewData = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.document.documentElement.scrollTop;
      setIsFixed(scrollTop > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //slider//
  const slideProperties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: false,
    indicators: true,
    arrows: false,
  };
  let params = useParams();
  const id = params?.id.split("-")[1];
  console.log(id, "this is id");
  const modifiedPanelName = params?.modifiedPanelName;
  console.log(id, "this is id");
  //modal css//
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 9,
    },
  };
  const gallerycustomStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 99999,
    },
  };
  const customMenu = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "100vh",
      overflowY: "scroll",
      width: "100%",
    },
  };
  const customGallery = {
    content: {
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      width: "100%",
      backgroundColor: "white",
      zIndex: 9,
      background: "white",
      borderwidth: "none",
    },
  };
  const settings = {
    // dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3, // Show three images at a time
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };
  const center = {
    lat: direction,
    lng: longitude,
  };
  useEffect(() => {
    let ridValue;
    if (id) {
      ridValue = id;
    } else if (modifiedName) {
      ridValue = modifiedName;
      console.log(modifiedName, "this is modified");
    } else {
      return;
    }
    fetch(`${BASE_URL}/wye-api/getRestaurant`, {
      method: "POST",
      body: JSON.stringify({ rid: ridValue }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoader(true);
        setUrlImage(data?.result.gallery_url);
        setResturantData(data?.result.restaurant);
        setReviews(data?.result?.review);
        if (data?.result?.review?.length > 0) {
          if (typeof setReviewData === "function") {
            setReviewData(true);
          } else {
            console.error("setReviewData is not a function");
          }
        }
        const restaurant = data?.result.restaurant?.[0];
        if (restaurant) {
          const { lat, log } = restaurant;
          setDirection(lat);
          setLongitude(log);
        }
        setDataImage(data?.result.gallery);
        setMenuData(data?.result.menu);
        setMenuUrl(data?.result.menu_url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    setLoader(false);
    const fetchData = async () => {
      const response = await fetch(`${BASE_URL}/wye-api/getRestaurantNear`, {
        method: "POST",
        body: JSON.stringify({
          rid: ridValue,
          latitude: direction,
          longitude: longitude,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      setResturantNear(data?.result.recentOpening);
      setCloseResturantP(data?.result.closestRestaurantP);
      setCloseResturantH(data?.result.closestRestaurantH);
      setCloseResturantN(data?.result.closestRestaurantN);
      setLoader(true);
    };
    fetchData();
  }, [id, direction, longitude]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${BASE_URL}/wye-api/data-by-ads`, {
        method: "POST",
        body: JSON.stringify({
          rid: ridValue,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      setAds(data.result);

      setLoader(true);
    };
    fetchData();
  }, []);
  //subscription fetch
  const Fetchsubscription = async () => {
    ValidateEmail();
    let ridValue;
    if (id) {
      ridValue = id;
    } else if (modifiedName) {
      ridValue = modifiedName;
    } else {
      return;
    }
    const response = await fetch(`${BASE_URL}/wye-api/subscription`, {
      method: "POST",
      body: JSON.stringify({
        rid: ridValue,
        ...storeData,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    });
    if (response.status === "done") {
      setStoreData("");
    }
    const data = await response.json();
    setMessage(data.message);
    setLoader(true);
  };
  //form//
  const handleForm = async () => {
    let ridValue;
    if (id) {
      ridValue = id;
    } else if (modifiedName) {
      ridValue = modifiedName;
    } else {
      return;
    }
    try {
      ValidateEmail();
      const response = await fetch(`${BASE_URL}/wye-api/restaurant-message`, {
        method: "POST",

        body: JSON.stringify({
          ...store,
          id: ridValue,
          restaurant: valueCity,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      if (data.status === "done") {
        setResturantMessage(true);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleReviews = async (event) => {
    let ridValue;
    if (id) {
      ridValue = id;
    } else if (modifiedName) {
      ridValue = modifiedName;
    } else {
      return;
    }
    try {
      ValidateEmail();
      const response = await fetch(`${BASE_URL}/wye-api/insert-review`, {
        method: "POST",
        body: JSON.stringify({
          ...review,
          rating: ratingVlaue,
          rid: ridValue,
          // restaurant: valueCity,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "done") {
        setReviewMessage(data.message);
        // setReview();
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  let ridValue;
  if (id) {
    ridValue = id;
  } else if (modifiedName) {
    ridValue = modifiedName;
  } else {
    return;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("rest_id", ridValue);
    formData.append("image", selectedImage);
    try {
      const response = await fetch(`${BASE_URL}/wye-api/insert-image`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      if (response.ok) {
        const resData = await response.json();
      } else {
        console.error("Image upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // const DraggableMarker = () => {
  //   const [position, setPosition] = useState(center);
  //   const markerRef = useRef(null);
  //   const eventHandlers = useMemo(
  //     () => ({
  //       dragend() {
  //         const marker = markerRef.current;
  //         if (marker != null) {
  //           setPosition(marker.getLatLng());
  //         }
  //       },
  //     }),
  //     []
  //   );
  //   return (
  //     <Marker
  //       draggable={draggable}
  //       eventHandlers={eventHandlers}
  //       position={center}
  //       zoom={zoomLevel}
  //       ref={markerRef}
  //       open={true}
  //     ></Marker>
  //   );
  // };
  const handleButtonClick = () => {
    window.scrollTo({ top: 1200, behavior: "smooth" });
  };
  return (
    <>
      <div className=" px-2">
        <Modal
          isOpen={modalsIsOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="bg-white lg:w-[400px] w-full">
            <div className="flex  justify-center items-center ">
              <div className="text-2xl w-full bg-red-900 py-5 px-2 text-white">
                Add User Photos
              </div>
              <button
                onClick={() => {
                  setModalsIsOpen(false);
                }}
                className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
              >
                <svg
                  className="h-5 w-5 "
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="bg-green-600 rounded-md px-4 py-4 mt-2 ">
              <div className="text-lg text-white ">Add Files...</div>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageUpload}
                required
              />
            </div>
            <div className="flex justify-between mt-5">
              <div>
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500   lg:w-40 w-24 h-8 text-white rounded-md"
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setModalsIsOpen(false);
                  }}
                  className="bg-red-900  lg:w-40 w-24 h-8 text-white rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className=" ">
        <Modal
          isOpen={reviewModal}
          style={galleryModal}
          contentLabel="Example Modal"
        >
          <div className="w-full">
            <div className="flex items-center justify-between h-auto  gap-2 py-2 ">
              <div className="font-bold text-2xl">Reviews</div>

              <div className="flex items-center justify-center gap-2">
                <div className="bg-green-600 lg:p-2 lg:text-lg px-1 py-1 rounded-md cursor-pointer text-white lg:font-bold text-sm">
                  Write Review
                </div>
                <div
                  onClick={() => {
                    setReviewModal(false);
                  }}
                  className="cursor-pointer h-auto flex items-center justify-around bg-black gap-2 p-2 rounded-md"
                >
                  <div className="text-white font-bold ">CLOSE</div>
                  <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="grid grid-flow-row w-full gap-4 h-auto mt-4">
              {reviews.length > 0 ? (
                <>
                  {reviews.map((ko, index) => (
                    <div className="border border-black py-4 h-auto ">
                      <div className="flex gap-2 px-2 items-center">
                        {ko.rating ? (
                          Array.from(
                            { length: parseInt(ko.rating) },
                            (_, index) => (
                              <div
                                className="bg-orange-500 h-5 w-5  flex items-center p-1  rounded-full"
                                key={index}
                              >
                                <svg
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 576 512"
                                >
                                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                </svg>
                              </div>
                            )
                          )
                        ) : (
                          <div className="font semibold">0</div>
                        )}
                        <div className="ml-2 font-semibold">{ko.rating}</div>
                      </div>
                      <div className=" text-black px-2 ">{ko.user_review}</div>
                      <div className=" text-black px-2 ">{ko.username}</div>
                      <div className=" text-black px-2 ">{ko.date}</div>
                      <div className="flex items-center gap-2">
                        <div className=" px-2 text-slate-400">
                          Recenty Posted by
                        </div>
                        <div className="bg-orange-400 p-1 rounded-full">
                          <svg
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                          </svg>
                        </div>
                        <div className="bg-black p-1 rounded-full">
                          <svg
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2H464c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48H294.5c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7V192v48 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384H96c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32C14.3 96 0 110.3 0 128V352c0 17.7 14.3 32 32 32z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div>No Review</div>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
      <div className=" ">
        <Modal
          isOpen={modalsLogin}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="bg-white  rounded-md lg:w-[550px] w-full">
            <div className="flex  p-4 bg-slate-200 rounded-md  items-center  ">
              <div className="text-lg  font-semibold ml-2 ">LOGIN</div>
              <div className="flex justify-center w-full   items-center gap-2">
                <div className="flex gap-4">
                  <div className="text-sm">No Account?</div>
                  <div className="text-sm">Register</div>
                </div>
                <div
                  onClick={() => {
                    setModalsLogin(false);
                  }}
                  className="bg-red-600 rounded-lg p-2 cursor-pointer  absolute  right-8 border border-white "
                >
                  <svg
                    className="h-5 w-5 "
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div>Email or Phone # to Login</div>
              <input className="w-full border border-black h-10 p-1 rounded" />
              <div className="mt-2">Password</div>
              <input
                type="password"
                className="w-full border border-black p-1 rounded"
              />
            </div>
            <div className="mt-3 flex items-center justify-between px-2 gap-2">
              <div className="text-red-600">Forgot Account Details?</div>
              <button className="text-white bg-green-600 w-40    rounded-md text-center p-1">
                Login
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="">
        <Modal
          isOpen={modalsMenu}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="bg-white  lg:w-[500px] w-full">
            <div className="flex  p-2 bg-slate-200 p items-center  ">
              <div className="text-xl  font-semibold ml-2 ">Add Menu Photo</div>
              <div className="flex justify-center w-full   items-center gap-2">
                <div
                  onClick={() => {
                    setModalsMenu(false);
                  }}
                  className="bg-red-600  top-5  p-2 cursor-pointer  absolute  right-5 border border-white "
                >
                  <svg
                    className="h-5 w-5 "
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="bg-green-600 w-full p-2 flex">
              <div>Add files</div>
              <input type="file" />
            </div>
            <div>Title</div>
            <input className="h-12 w-full border border-black" />
            <div className="flex justify-between mt-5">
              <button className="bg-blue-400 p-2 px-2 py-2 text-white rounded-md">
                Submit
              </button>
              <button className="p-2 bg-red-900 px-2 py-2 text-white rounded-md">
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <Modal isOpen={modal} style={customStyles} contentLabel="Example Modal">
        <div className=" bg-white lg:w-[400px] w-full px-2">
          <div className="flex  justify-center items-center ">
            <div className="text-2xl w-full bg-red-900 py-1 rounded-md text-center px-2 text-white">
              Restaurant Message
            </div>
            <button
              onClick={() => {
                setModal(false);
                window.location.reload();
              }}
              className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
            >
              <svg
                className="h-5 w-5 "
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          {resturantMessage ? (
            <>
              <div className="flex justify-center">
                <div className="text-green-600 text-2xl mt-4 text-center w-64 ">
                  We have received your message. Thank You!
                </div>
              </div>
            </>
          ) : null}
          <input
            name="name"
            value={store.name}
            onChange={handleText}
            className=" w-full border  border-black p-2 mt-4 rounded-md"
            placeholder="Your Name"
          />
          {click && store.name == "" ? (
            <div className="text-red-600 ">Required to fill name</div>
          ) : null}
          <input
            name="email"
            value={store.email}
            onChange={handleText}
            className="w-full mt-2 border border-black p-2 rounded-md"
            placeholder="email"
          />
          {click && store.email == "" ? (
            <div className="text-red-600 ">Required to fill email id</div>
          ) : null}
          <input
            name="phone"
            value={store.phone}
            onChange={handleText}
            type="number"
            className=" no-spinner w-full mt-2 border border-black p-2 rounded-md"
            placeholder="Phone Number"
          />
          {click && store.phone == "" ? (
            <div className="text-red-600 ">Required to fill phone number</div>
          ) : null}
          <textarea
            name="message"
            value={store.message}
            onChange={handleText}
            className="w-full h-24 border border-black p-2 mt-2 rounded-md"
            placeholder="write your message"
          />
          {click && store.message == "" ? (
            <div className="text-red-600 ">Required to fill message</div>
          ) : null}
          <button
            onClick={handleForm}
            className="w-full mt-2 p-2 bg-red-900 rounded-md text-white"
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-white lg:w-[400px] w-full px-2">
          <div className="flex  justify-center items-center ">
            <div className="text-2xl w-full bg-red-900 py-1 rounded-md text-center px-2 text-white">
              Add User Photos
            </div>
            <button
              onClick={() => {
                setModalIsOpen(false);
              }}
              className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
            >
              <svg
                className="h-5 w-5 "
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="mt-3">
            *WhereYouEat is a local restaurant search engine - we are not
            necessarily affiliated with any restaurant listed on our guide. We
            try to maintain accuracy of listings, but the data below is not
            verified or guaranteed, and may be outdated.
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-white lg:w-[700px] w-full px-2 overflow-hidden">
          <div className="flex  justify-center items-center ">
            {resturantData.map((ok) => {
              return (
                <>
                  <div className="text-2xl w-full overflow-hidden bg-red-900 py-1 rounded-md text-center px-2 text-white">
                    Review For {ok.rname}
                  </div>
                </>
              );
            })}
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
            >
              <svg
                className="h-5 w-5 "
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="text-green-600 text-center">{reviewMessage}</div>
          <div className="mt-4">Write Questions, Comment Or Post</div>
          <div className="mt-2">
            <textarea
              onChange={handleReviewData}
              name="user_review"
              value={review.reviews}
              className="border p-2  border-red-500 h-40 w-full"
            />
          </div>
          <div>(Optionally)</div>
          <div className="mt-6 text-center">
            Turn this post into a review with a rating
          </div>
          <div className="flex justify-center ">
            {[...Array(5)].map((_, index) => {
              const starValue = index + 1;
              return (
                <FaStar
                  className=""
                  key={index}
                  size={30}
                  color={starValue <= rating ? "#ffc107" : "#e4e5e9"}
                  onClick={() => handleRatingClick(starValue)}
                  style={{ cursor: "pointer" }}
                />
              );
            })}
          </div>
          <div className="border-t-2 mt-2"></div>
          <div className="text-center p-3">
            <div className="text-lg font-semibold">Posting as Anonomyous</div>
            <div className="font-semibold">Login to post as user</div>
          </div>
          <button
            onClick={handleReviews}
            className="w-full p-2 rounded-md text-white bg-red-900"
          >
            Post Comment
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={timeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{ zIndex: 9999 }}
          className=" bg-white lg:w-[600px] w-full px-2"
        >
          <div className="flex  justify-center items-center ">
            <button
              onClick={() => {
                setTimeMOdal(false);
              }}
              className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
            >
              <svg
                className="h-5 w-5 "
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="">
            {resturantData.map((times, index) => {
              const scheduleArray = times.hours.split("+");
              {
                return (
                  <>
                    <div key={index}>
                      {scheduleArray.map((schedule, subIndex) => (
                        <p
                          className="border-b border-red-900 py-2 w-full"
                          key={subIndex}
                        >
                          {schedule}
                        </p>
                      ))}
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </Modal>
      <Modal isOpen={menuModal} style={customMenu} contentLabel="Example Modal">
        <div className="bg-white  w-full px-2">
          <div className="   mx-auto px-2   ">
            <div className="flex justify-between text-xl">
              <div className="font-semibold">Scanned Menu</div>
              <div
                onClick={() => {
                  setMenuModal(false);
                }}
                className="font-bold text-2xl cursor-pointer"
              >
                Close
              </div>
            </div>
            <div className=" ">
              {menuData.length > 0 ? (
                <>
                  {menuData.map((menuurls, index) => {
                    const imageFilenames = menuurls.image.split("~-~");
                    const menuImages = imageFilenames.map(
                      (filename) => `${menuUrl}${filename}`
                    );

                    return (
                      <div className="flex justify-center ">
                        <div className="">
                          {menuImages.map((imageUrl, index) => (
                            <div
                              key={index}
                              className="carousel-slide flex justify-center"
                            >
                              <img src={imageUrl} />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="font-normal text-xl text-center">No Menu</div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={galleryModal}
        style={customGallery}
        contentLabel="Example Modal"
      >
        <div className="bg-white  w-full px-2">
          <div className="   mx-auto px-2   ">
            <div className="flex  px-4  justify-between items-center mb-2 text-xl">
              <div className="font-semibold">Gallery</div>
              <div className="flex gap-4 items-center">
                <div
                  onClick={() => {
                    setGalleryModalsIsOpen(true);
                  }}
                  className="font-bold bg-green-700 p-1 rounded-md text-lg text-white cursor-pointer"
                >
                  Add Photos
                </div>
                <div
                  onClick={() => {
                    setGalleryModal(false);
                  }}
                  className="font-bold text-xl cursor-pointer"
                >
                  Close
                </div>
              </div>
            </div>
            <div className=" grid lg:grid-cols-3 mt-5 gap-5">
              <>
                {dataImage.length > 0 ? (
                  <>
                    {dataImage.map((oky) => {
                      return (
                        <>
                          <img
                            className="w-full "
                            src={`${urlimage}${oky?.image}`}
                          />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        <Modal
          isOpen={galleryModalsIsOpen}
          style={gallerycustomStyles}
          contentLabel="Example Modal"
        >
          <div className="bg-white lg:w-[400px] w-full">
            <div className="flex  justify-center items-center ">
              <div className="text-2xl w-full bg-red-900 py-5 px-2 text-white">
                Add User Photos
              </div>
              <button
                onClick={() => {
                  setGalleryModalsIsOpen(false);
                }}
                className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
              >
                <svg
                  className="h-5 w-5 "
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="bg-green-600 rounded-md px-4 py-4 mt-2 ">
              <div className="text-lg text-white ">Add Files...</div>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageUpload}
                required
              />
            </div>
            <div className="flex justify-between mt-5">
              <div>
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500   lg:w-40 w-24 h-8 text-white rounded-md"
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setModalsIsOpen(false);
                  }}
                  className="bg-red-900  lg:w-40 w-24 h-8 text-white rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Modal>
      <Header />
      <div className="lg:border-b-2 lg:border-[#990000]"></div>
      <div className="container  w-full lg:w-[1170px]    mx-auto ">
        <div className=" lg:flex   ">
          <div className="  md:w-full  ">
            <div className="  lg:w-[270px] lg:border-r px-2 lg:sticky top-0 bg-white">
              {resturantData.map((res) => {
                return (
                  <div>
                    <div key={res.id} className="  mt-2  ">
                      <div key={res.id} className="mt-2 ">
                        <div className="flex items-center  ">
                          <div className="text-sm ml-2">{res.city}</div>
                          <svg
                            fill="#88001b"
                            className="w-2 h-2 mt-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                          </svg>
                        </div>
                        <div className="flex flex-wrap items-center lg:flex ml-2">
                          {res.cuisines.map((cuisine) => (
                            <div
                              onClick={() => {
                                const modifi = cuisine.name.replace(/\s/g, "");
                                const cityName = res.city;
                                const modified = "cuisine"
                                  .replace(/\s/g, "")
                                  .toLowerCase();
                                const url = `/cuisine/-${modifi}-${modified}-${cityName}-${res.id}`;
                                navigate(url);
                              }}
                              className="cursor-pointer"
                              key={cuisine.id}
                            >
                              {cuisine.name}
                              <svg
                                fill="#88001b"
                                className="w-3 h-3 inline-block"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                              </svg>
                            </div>
                          ))}
                          <div
                            onClick={() => {
                              const modified = "zip".replace(/\s/g, "");
                              const city = "".replace(/\s/g, "");
                              const cityName = res.city;
                              const url = `/cuisine/-${city}-${modified}-${cityName}-${res.zip}`;
                              navigate(url);
                            }}
                            className="cursor-pointer"
                          >
                            {res.zip}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="font-bold text-3xl py-2">{res.rname}</div>
                    <div className="flex mt-2">
                      <div>
                        <svg
                          className="w-3 h-3 mt-2"
                          fill="#88001b"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                        </svg>
                      </div>
                      <div className="ml-2">{res.address}</div>
                    </div>
                    <div className="flex mt-2 items-center">
                      <div>
                        <svg
                          className="w-3 h-3"
                          fill="#88001b"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                        </svg>
                      </div>
                      <a href={`tel:-${res.phone1}`} className="ml-2">
                        {res.phone1}
                      </a>
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center mt-3">
                <div>
                  <svg
                    fill="#88001b"
                    className="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                </div>
                <button
                  onClick={() => {
                    setTimeMOdal(true);
                  }}
                  className="ml-2 "
                >
                  11:00am-09:30Pm
                </button>
              </div>
              {/* <div className="mt-3 cursor-pointer mb-2 bg-green-600 p-2 flex rounded justify-center">
                <div className="flex  border-4 border-white rounded-md  items-center px-1  ">
                  <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                  </svg>
                </div>
                <div className="text-xl text-white ml-2 hover:text-white">
                  Visit Website
                </div>
              </div> */}
              {resturantData.map((ko) => {
                return (
                  <>
                    <div className="flex mt-2 flex-cols-2  gap-2 justify-around items-center">
                      <button
                        onClick={() => {
                          setValueCity(ko.rname);

                          setModal(true);
                        }}
                        className="border p-2 w-full h-10 text-center border-black hover:bg-[#990000] rounded-md hover:text-white"
                      >
                        Message
                      </button>
                      <button
                        onClick={handleButtonClick}
                        className="p-2 border h-10 w-full text-center border-black hover:bg-[#990000] rounded-md hover:text-white"
                      >
                        Get Deals
                      </button>
                    </div>
                  </>
                );
              })}
              <div
                onClick={() => {
                  setModalsLogin(true);
                }}
                className="flex cursor-pointer mt-2 flex-cols-2 gap-2 justify-around items-center"
              >
                <div
                  className={`flex border border-black h-10 p-2 w-full text-center rounded-md justify-center items-center ${
                    isHovered
                      ? "hover:bg-red-900 hover:text-white"
                      : "hover:bg-red-900 hover:text-white"
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <svg
                    fill="currentColor"
                    className={`w-5 h-5 hover:bg-red-900 ${
                      isHovered ? "text-white" : "text-red-900"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                  </svg>
                  <div className="ml-2">Favorite</div>
                </div>
                <div
                  onClick={() => {
                    setModalsLogin(true);
                  }}
                  className={`flex cursor-pointer border-black rounded-md  hover:bg-red-900 hover:text-white justify-center h-10 items-center p-2 border w-full text-center  rounded-md${
                    isHovereds
                      ? "hover:bg-red-900 hover:text-white"
                      : "hover:bg-red-900 hover:text-white"
                  }`}
                  onMouseEnter={handleMouseEnters}
                  onMouseLeave={handleMouseLeaves}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    className={`w-5 h-5 hover:bg-red-900 ${
                      isHovereds ? "text-white" : "text-red-900"
                    }`}
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                  <div className=" hover:text-white ml-2 ">Try List</div>
                </div>
              </div>
              <div className="border border-dashed mt-4 border-black"></div>
              <div className="border-dotted mt-5 p-2">
                <div className="flex flex-row lg:flex-col gap-4 justify-center">
                  <div
                    onClick={handleClick}
                    className="cursor-pointer text-orange-900"
                  >
                    HOME
                  </div>
                  <div className="hidden lg:block md:hidden border-dotted border border-black"></div>
                  <div
                    onClick={handleGallery}
                    className="cursor-pointer hover:text-orange-900"
                  >
                    GALLERY
                  </div>
                  <div className="hidden lg:block md:hidden border-dotted border border-black"></div>
                  <div
                    onClick={handleMenu}
                    className="cursor-pointer hover:text-orange-900"
                  >
                    MENUS
                  </div>
                  <div className="hidden lg:block md:hidden border-dotted border border-black"></div>
                  <div
                    onClick={handleReview}
                    className="cursor-pointer hover:text-orange-900"
                  >
                    REVIEWS
                  </div>
                  <div className="hidden lg:block md:hidden border-dotted border border-black"></div>
                </div>
                <div className="border-dotted border border-black hidden lg:hidden md:block"></div>
              </div>
            </div>
          </div>
          <div className=" ">
            <div className="   ">
              <div className="lg:w-[900px] px-2 ">
                {displayText ? (
                  <>
                    <div className="text-slate-400">*Ads</div>
                    <div className="overflow-x-auto mt-2 ">
                      <div className="flex gap-4  w-max justify-center items-center">
                        {ads.map((ad) => {
                          return (
                            <>
                              <div className="lg:block md:block hidden">
                                <div className="flex w-[400px] shadow-xl ">
                                  <div>
                                    {ad.a_url_image ? (
                                      <>
                                        <img
                                          className="h-[220px] w-full"
                                          src={ad.a_url_image}
                                        />
                                      </>
                                    ) : ad.image ? (
                                      <>
                                        <img
                                          className="h-[220px] w-full"
                                          src={ad.image}
                                        />
                                      </>
                                    ) : (
                                      <img
                                        className="h-[220px] w-full"
                                        src={NoImage}
                                      />
                                    )}
                                  </div>
                                  <div className="p-2">
                                    <div className="text-black w-1/2">
                                      {ad.a_promotional_text}
                                    </div>
                                    {ad.a_url_title ? (
                                      <>
                                        <div className="">{ad.a_url_title}</div>
                                      </>
                                    ) : (
                                      <>
                                        <div>Order NOw</div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <div className="flex px-2 py-2 gap-1 items-center h-8  hover:shadow-lg w-52 w  justify-center mt-2 rounded-lg bg-[#eeeeee] border-black">
                        <svg
                          className="w-5 h-5 py-1 px-1 bg-black rounded-full "
                          fill="white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                        >
                          <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
                        </svg>
                        <button
                          onClick={() => {
                            setModalIsOpen(true);
                          }}
                          className="text-sm font-extralight ml-1"
                        >
                          Info about Content Below
                        </button>
                      </div>
                      <div className="flex justify-between mt-5">
                        <div className="font-semibold text-[24px] text-[#333333]">
                          Menus
                        </div>
                        <button
                          onClick={() => {
                            setMenuModal(true);
                          }}
                          className="text-slate-400 border-black p-1 px-2 shadow-lg cursor-pointer border  rounded"
                        >
                          SEE MENU SCAN
                        </button>
                      </div>
                      <div className="h-auto px-5">
                        {menuData.length > 0 ? (
                          <>
                            {menuData.map((menuurls, index) => {
                              const imageFilenames =
                                menuurls.image.split("~-~");
                              const menuImages = imageFilenames.map(
                                (filename) => `${menuUrl}${filename}`
                              );

                              return (
                                <Slider {...settings}>
                                  {menuImages.map((imageUrl, index) => (
                                    <div
                                      onClick={() => {
                                        setMenuModal(true);
                                      }}
                                      key={index}
                                      className="carousel-slide flex   cursor-pointer "
                                    >
                                      <img className="" src={imageUrl} />
                                    </div>
                                  ))}
                                </Slider>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="font-normal text-xl">No Menu</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="border border-dashed border-black mt-5"></div>
                    <div className="flex  justify-between mb-5 items-center">
                      <div className="text-xl font-bold text-[#212529]  ">
                        Gallery
                      </div>
                      <div className="flex mt-4 justify-around gap-3 ">
                        <button
                          onClick={() => {
                            setGalleryModal(true);
                          }}
                          className="rounded text-lg cursor-pointer font-medium shadow-lg text-slate-700 px-2 border border-black text-center hover:text-black "
                        >
                          See All
                        </button>
                        <div
                          onClick={() => {
                            setModalsIsOpen(true);
                          }}
                          className="  shadow-lg text-lg px-2 font-medium text-slate-700 cursor-pointer rounded border border-black text-center hover:text-black"
                        >
                          Add Photos
                        </div>
                      </div>
                    </div>
                    {loader ? (
                      <>
                        <div className=" grid lg:grid-cols-4  md:grid-cols-2 gap-2   w-full px-2   ">
                          {dataImage.length > 0 ? (
                            <>
                              {dataImage.slice(0, 4).map((gall) => {
                                return (
                                  <>
                                    <img
                                      onClick={() => {
                                        setGalleryModal(true);
                                      }}
                                      className="w-full mx-auto  h-[160px] cursor-pointer"
                                      src={`${urlimage}${gall?.image}`}
                                    />
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="font-normal text-xl ">
                                No Image
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center items-center">
                          <Vortex
                            visible={true}
                            height="60"
                            width="60"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={[
                              "white",
                              "red",
                              "orange",
                              "green",
                              "yellow",
                              "orange",
                            ]}
                          />
                        </div>
                      </>
                    )}
                    <div className="border border-dashed border-black mt-5"></div>
                    <div className="font-bold text-xl mt-4 mb-3 text-[#212529] ">
                      Recent Opening Nearby
                    </div>
                    {loader ? (
                      <>
                        <div className="overflow-x-auto ">
                          <div className="flex gap-4  w-max justify-center items-center">
                            {resturantNear?.map((near) => {
                              return (
                                <>
                                  <div
                                    onClick={() => {
                                      const modifiedPanelName = near.rname
                                        .replace(/\s/g, "-")
                                        .toLowerCase();
                                      setModifiedName(near.id);
                                      navigate(
                                        `/menu/-${near.id}-${modifiedPanelName}`
                                      );
                                      window.location.reload();
                                    }}
                                    className="hover:scale-90  cursor-pointer shadow-lg px-3 py-1  transition duration-300 ease-in-out"
                                  >
                                    {near.image ? (
                                      <>
                                        <div className="flex justify-center">
                                          <img
                                            className="h-[180px] w-full"
                                            src={near.image}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="flex justify-center">
                                          <img
                                            className="h-[180px] w-full"
                                            src={NoImage}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {/* <div className="text-center text-white absolute right-0 top-24 bg-red-600 p-2 px-4 py-2">
                                      {near.rdate}
                                    </div> */}
                                    <div className="text-center text-lg font-bold">
                                      {near.rname}
                                    </div>
                                    <div className="text-center">
                                      {near.address}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center items-center">
                          <Vortex
                            visible={true}
                            height="60"
                            width="60"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={[
                              "white",
                              "red",
                              "orange",
                              "green",
                              "yellow",
                              "orange",
                            ]}
                          />
                        </div>
                      </>
                    )}
                    <div className="border border-black mt-10 ">
                      <div className="text-[20px] text-center text-[#383b41]  font-semibold  mt-10">
                        Subscribe to EXCLUSIVE DEALS
                      </div>
                      <div className="text-green-600 text-center">
                        {message}
                      </div>
                      <div className="grid md:grid-cols-2 px-6 p-3  grid-cols-1 gap-2 justify-center items-center">
                        <div>
                          <input
                            onChange={handleData}
                            value={storeData.phone}
                            name="phone"
                            type="number"
                            className="  no-spinner border h-10 p-2 rounded-md border-black w-full"
                            placeholder="Type your Phone"
                          />
                          {click && storeData.phone == "" ? (
                            <div className="text-red-600 ">
                              Required to fill phone number
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <input
                            onChange={handleData}
                            value={storeData.email}
                            name="email"
                            className="border p-2 h-10 rounded-md border-black w-full"
                            placeholder="Type your Email"
                          />
                          {click && storeData.email == "" ? (
                            <div className="text-red-600 ">
                              Required to fill email
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="px-4 ">
                        <div className="flex px-2 justify-center">
                          <button
                            onClick={Fetchsubscription}
                            className="bg-green-600    p-2 hover:bg-green-900 text-center text-white rounded-md w-full  text-xl"
                          >
                            Subscribe
                          </button>
                        </div>
                        <div className="mt-2 mb-10 text-slate-500 text-center">
                          Subscribe for Email and/or Subscribe for SMS phone
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#f6f6f6] shadow-lg border border-[#f6f6f6] px-2 mt-5">
                      <div className="flex justify-between items-center  mt-2  lg:px-5 p-2">
                        <div className="text-2xl font-semibold  text-[#383d41]">
                          See what people are saying
                        </div>
                        <div className="flex justify-center items-center gap-2 mt-2">
                          {reviewData ? (
                            <>
                              <button
                                onClick={() => {
                                  setReviewModal(true);
                                }}
                                className=" text-white lg:py-2 py-1 text-center  bg-[#990000] rounded-md px-3 text-sm lg:text-lg"
                              >
                                View All Review
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          <button
                            onClick={() => {
                              setOpenModal(true);
                            }}
                            className="  text-white lg:py-2 py-1 text-center  bg-[#990000] rounded-md px-3 lg:text-lg text-sm"
                          >
                            Write Review
                          </button>
                        </div>
                      </div>
                      <div style={{ zIndex: 1 }} className="slide-container">
                        {reviews.length > 0 ? (
                          <>
                            <Zoom {...slideProperties}>
                              {reviews.map((ko, index) => (
                                <div className="mt-3">
                                  <div className="text-center  mb-2">
                                    {ko.user_review}
                                  </div>
                                  <div className="flex items-center justify-center gap-2">
                                    {ko.rating ? (
                                      Array.from(
                                        { length: parseInt(ko.rating) },
                                        (_, index) => (
                                          <div
                                            className="bg-orange-500 h-5 w-5  flex justify-center items-center p-1  rounded-full"
                                            key={index}
                                          >
                                            <svg
                                              fill="white"
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="1em"
                                              viewBox="0 0 576 512"
                                            >
                                              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div>
                                    <div className=" text-black font-normal px-2  text-center">
                                      {ko.username}
                                    </div>
                                    {(() => {
                                      const dateObj = new Date(ko.date);
                                      return isNaN(dateObj) ? (
                                        <p>OPENED: Invalid Date</p>
                                      ) : (
                                        <div className="text-black px-2 text-center">
                                          "
                                          {dateObj.toLocaleString("default", {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                          })}
                                          "
                                        </div>
                                      );
                                    })()}
                                  </div>
                                </div>
                              ))}
                            </Zoom>
                          </>
                        ) : (
                          <>
                            <div></div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="text-xl mt-5 text-[#212529] font-bold">
                      Location & Details
                    </div>
                    <div className="text-lg mt-3">
                      Wing World Located in New Dorp neighborhood of Staten
                      Island. Wing World is open Today. Tuesday April 4th from
                      11:00 am -until 09:30 pm Delivery, is available. Serving
                      Chicken Cuisine
                    </div>
                    {resturantData.map((pky) => {
                      return (
                        <>
                          <div className="flex items-center mt-2 gap-2">
                            <div className="flex items-center gap-2">
                              <svg
                                fill="#88001b"
                                className="w-4 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                              >
                                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                              </svg>
                              <div>{pky.city}</div>
                            </div>
                            <div className="flex items-center gap-2">
                              <svg
                                fill="#88001b"
                                className="w-4 h-5 "
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                              </svg>
                              <div>{pky.phone1}</div>
                            </div>
                            <div className="flex items-center gap-2">
                              <svg
                                fill="#88001b"
                                className="w-4 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                              </svg>
                              <div>Wed: 11:00 am - 09:00 pm</div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="mt-5"></div>
                    {resturantData.length > 0 ? (
                      <>
                        <div className="relative z-0 overflow-hidden ">
                          <MapContainer
                            center={[40.6138, -74.1683]}
                            zoom={10}
                            scrollWheelZoom={true}
                            style={{ height: "400px" }}
                          >
                            <TileLayer
                              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {resturantData?.map((panel) => {
                              return (
                                <>
                                  <Marker
                                    draggable={popupOpen}
                                    open={true}
                                    position={[panel.lat, panel.log]}
                                  >
                                    <Popup
                                      open={true}
                                      draggable={true}
                                      onClose={() => setPopupOpen(false)}
                                    >
                                      <div className="font-bold text-xl text-red-900">
                                        {panel.rname}
                                      </div>
                                      <div className="'text-lg text-center">
                                        {panel.address}
                                      </div>
                                    </Popup>
                                  </Marker>
                                </>
                              );
                            })}
                          </MapContainer>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center text-2xl">
                          No data found
                        </div>
                      </>
                    )}
                    {resturantData?.map((sunnny) => {
                      return (
                        <>
                          <div className="text-xl font-semibold ml-5 mt-3 ">
                            Near Here Search "{sunnny.address}"
                          </div>
                        </>
                      );
                    })}
                    <div className="text-xl mt-3  ml-3">
                      *These Results are "Near Me" search and may contain
                      sponsors part of this result
                    </div>
                  </>
                ) : null}
                {menuShowData ? (
                  <>
                    <div className=" ">
                      <div className="flex items-center justify-between mt-4 mb-2 p-2">
                        <div className="font-bold text-2xl">Menu</div>
                        <div
                          onClick={() => {
                            setModalsMenu(true);
                          }}
                          className="flex p-2 cursor-pointer items-center border rounded-md"
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 448 512"
                            >
                              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                            </svg>
                          </div>
                          <div className="cursor-pointer ml-2 text-xl">
                            Add Menu
                          </div>
                        </div>
                      </div>
                      {menuData.length > 0 ? (
                        <>
                          {menuData.map((menuurls, index) => {
                            const imageFilenames = menuurls.image.split("~-~");
                            const menuImages = imageFilenames.map(
                              (filename) => `${menuUrl}${filename}`
                            );

                            return (
                              <div className="flex justify-center items-center ">
                                <div className="">
                                  {menuImages.map((imageUrl, index) => (
                                    <div key={index} className="">
                                      <img src={imageUrl} />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div className="font-normal text-center text-xl">
                            No Menu
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                {gallerySHowData ? (
                  <>
                    <div className="flex  px-4 mt-5  justify-between items-center mb-2 text-xl">
                      <div className="font-semibold text-2xl">Gallery</div>
                      <div className="flex gap-4 items-center">
                        <div
                          onClick={() => {
                            setModalsIsOpen(true);
                          }}
                          className="font-bold bg-green-700 p-1 rounded-md text-lg text-white cursor-pointer"
                        >
                          Add Photos
                        </div>
                      </div>
                    </div>
                    <div className=" grid lg:grid-cols-3 mt-5 gap-5">
                      <>
                        {dataImage.length > 0 ? (
                          <>
                            {dataImage.map((oky) => {
                              return (
                                <>
                                  <img
                                    className="lg:h-[260px] "
                                    src={`${urlimage}${oky?.image}`}
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div></div>
                            <div className="text-center  font-bold  text-2xl">
                              No Gallery Data
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </>
                ) : null}
                {reviewDataShow ? (
                  <>
                    <div className="flex items-center justify-between   gap-2 py-2 mt-4 ">
                      <div className="font-bold text-2xl">Reviews</div>
                      <div className="flex items-center justify-center gap-2">
                        <div
                          onClick={() => {
                            setOpenModal(true);
                          }}
                          className=" bg-red-900 lg:p-2 lg:text-lg px-1 py-1 rounded-md cursor-pointer text-white lg:font-bold text-sm"
                        >
                          Write Review
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-flow-row w-full gap-4 mt-4">
                      {reviews.length > 0 ? (
                        <>
                          {reviews.map((ko, index) => (
                            <div className="border  py-4 bg-[#e2e3e5] ">
                              <div className="flex gap-2 ml-2">
                                {ko.rating ? (
                                  Array.from(
                                    { length: parseInt(ko.rating) },
                                    (_, index) => (
                                      <div
                                        className="bg-orange-500 h-5 w-5  flex items-center p-1  rounded-full gap-2"
                                        key={index}
                                      >
                                        <svg
                                          fill="white"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="1em"
                                          viewBox="0 0 576 512"
                                        >
                                          <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                        </svg>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div className="font semibold">0</div>
                                )}
                                {/* <div className="ml-2 font-semibold">
                                  {ko.rating}
                                </div> */}
                              </div>
                              <div className=" text-black px-2 ">
                                {ko.user_review}
                              </div>
                              <div className=" text-black px-2 ">
                                {ko.username}
                              </div>
                              <div className=" text-black px-2 ">{ko.date}</div>

                              <div className="flex items-center gap-2">
                                <div className=" px-2 text-slate-400">
                                  Recenty Posted by
                                </div>
                                <div className="bg-orange-400 p-1 rounded-full">
                                  <svg
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                  </svg>
                                </div>
                                <div className="bg-black p-1 rounded-full">
                                  <svg
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2H464c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48H294.5c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7V192v48 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384H96c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32C14.3 96 0 110.3 0 128V352c0 17.7 14.3 32 32 32z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="text-center font-bold text-2xl">
                            No Review
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                {loader ? (
                  <>
                    <div className="grid md:grid-cols-2 lg:gap-5  gap-5 mt-5 grid-cols-1 mb-5">
                      {closeResturantP && closeResturantP.length > 0 ? (
                        <>
                          {closeResturantP.map((closeres) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modifiedPanelName = closeres.rname
                                      .replace(/\s/g, "-")
                                      .toLowerCase();
                                    setModifiedName(closeres.id);
                                    navigate(
                                      `/menu/-${closeres.id}-${modifiedPanelName}`
                                    );
                                    window.location.reload();
                                  }}
                                  className=" cursor-pointer  h-[200px]  hover:scale-90  transition duration-200 ease-in-out "
                                >
                                  <div className="grid grid-cols-2  border-red-900 border-2  ">
                                    {closeres.image ? (
                                      <>
                                        <img
                                          className="w-full lg:h-[150px] h-full max-h-48 mx-auto"
                                          src={closeres.image}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="w-full lg:h-[150px] h-full max-h-48  mx-auto"
                                          src={NoImage}
                                        />
                                      </>
                                    )}
                                    <div className="ml-2">
                                      <div className="text-xl leading-6 font-medium  text-orange-900 ">
                                        {closeres.rname}
                                      </div>
                                      <div className=" leading-6 text-slate-600">
                                        {closeres.address}
                                      </div>
                                      <div className="text-slate-600">
                                        {Math.round(closeres.distance * 100) /
                                          100}{" "}
                                        Miles away
                                      </div>
                                      <div className="flex justify-end">
                                        <div></div>
                                        <img src={closeres.openClose} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                      {closeResturantH && closeResturantH.length > 0 ? (
                        <>
                          {closeResturantH.map((closeresH) => {
                            return (
                              <>
                                <div className="cursor-pointer  h-[200px]  hover:scale-90  transition duration-200 ease-in-out">
                                  <img
                                    className="absolute h-10 w-10  "
                                    src={Star}
                                  />
                                  <div
                                    onClick={() => {
                                      const modifiedPanelName = closeresH.rname
                                        .replace(/\s/g, "-")
                                        .toLowerCase();
                                      setModifiedName(closeresH.id);
                                      navigate(
                                        `/menu/-${closeresH.id}-${modifiedPanelName}`
                                      );
                                      window.location.reload();
                                    }}
                                    className="  "
                                  >
                                    <div className="grid grid-cols-2 ">
                                      {closeresH.image ? (
                                        <>
                                          <img
                                            className="w-full lg:h-[150px] h-full max-h-48 mx-auto"
                                            src={closeresH.image}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="w-full lg:h-[150px] h-full max-h-48  mx-auto"
                                            src={NoImage}
                                          />
                                        </>
                                      )}
                                      <div className="ml-2">
                                        <div className="text-xl leading-6 font-medium  text-orange-900 ">
                                          {closeresH.rname}
                                        </div>
                                        <div className=" leading-6 text-slate-600">
                                          {closeresH.address}
                                        </div>
                                        <div className="text-slate-600">
                                          {Math.round(
                                            closeresH.distance * 100
                                          ) / 100}{" "}
                                          Miles away
                                        </div>
                                        <div className="flex justify-end">
                                          <div></div>
                                          <img src={closeresH.openClose} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center items-center">
                      <Vortex
                        visible={true}
                        height="60"
                        width="60"
                        ariaLabel="vortex-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={[
                          "yellow",
                          "red",
                          "orange",
                          "green",
                          "yellow",
                          "orange",
                        ]}
                      />
                    </div>
                  </>
                )}
                {closeResturantN && closeResturantN.length > 0 ? (
                  <>
                    {closeResturantN.map((closeresN) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              const modifiedPanelName = closeresN.rname
                                .replace(/\s/g, "-")
                                .toLowerCase();
                              setModifiedName(closeresN.id);
                              navigate(
                                `/menu/-${closeresN.id}-${modifiedPanelName}`
                              );
                              window.location.reload();
                            }}
                            className=" cursor-pointer  h-[200px]  hover:scale-90  transition duration-200 ease-in-out "
                          >
                            <div className="grid grid-cols-2    ">
                              {closeresN.image ? (
                                <>
                                  <img
                                    className="w-full lg:h-[150px] h-full max-h-48 mx-auto"
                                    src={closeresN.image}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    className="w-full lg:h-[150px] h-full max-h-48  mx-auto"
                                    src={NoImage}
                                  />
                                </>
                              )}
                              <div className="ml-2">
                                <div className="text-xl leading-6 font-medium  text-orange-900 ">
                                  {closeresN.rname}
                                </div>
                                <div className=" leading-6 text-slate-600">
                                  {closeresN.address}
                                </div>
                                <div className="text-slate-600">
                                  {Math.round(closeresN.distance * 100) / 100}{" "}
                                  Miles away
                                </div>
                                <div className="flex justify-end">
                                  <div></div>
                                  <img src={closeresN.openClose} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainBottom headingText="WhereYouEat.com Near Me Search scover Local Restaurants" />
      <About />
    </>
  );
}
