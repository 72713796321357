import React from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function FaqPage() {
  return (
    <div className="">
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto lg:px-24 p-2 ">
        <div className="text-2xl font-bold mt-10 leading-1 mb-5">FAQ</div>
        <div className="font-bold text-1xl leading-1 text-2xl">What is WhereYouEat?</div>
        <div className=" text-1xl leading-1 mt-2">
          Since 2007, our mission is to help consumers discover local
          neighborhood restaurants,
        </div>
        <div className="text-lg leading-1">
          we are a comprehensive dining guide listing accurate local data, grand
          openings around your neighborhood.
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
