import React from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function AboutPage() {
  return (
    <div className="">
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto lg:px-20 px-2  p-2 ">
        <div className="text-2xl font-bold mt-3  leading-1">About</div>
        <div className="font-bold text-2xl leading-1 mt-4">
          WhereYouEat.com / Discover Local Restaurants
        </div>
        <div className="font-bold text-2xl">
          Menus, Grand Openings & Specials
        </div>
        <div className="mt-10 text-slate-400">Welcome to NYC's most Comprehensive Dining guide</div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
