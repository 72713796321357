import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/component/css/style.css";
import ContactPage from "./component/Contact";
import AboutPage from "./component/AboutPage";
import PrivacyPolicy from "./component/PrivacyPolicy";
import Menu from "./component/menu";
import InfoUser from "./component/InfoUser";
import InfoRestaurant from "./component/InfoResturant";
import FaqPage from "./component/FaqPage";
import DockAndDine from "./component/dock-and-dine";
import ScannedMenu from "./component/ScannedMenu";
import NewOpening from "./common/components/newOpening";
import Gallery from "./common/components/Gellery";
import Term from "./component/Term";
import GetStart from "./component/GetStart";
import CityPage from "./component/City";
import RichmondAvenue from "./component/richmondAvenue";
import Signuptowhereyoueat from "./common/components/sign-up-to-whereyoueat";
import Restaurant from "./component/resturant";
import NotFound from "./component/notFound";
import { useEffect, useState } from "react";
import SearchCity from "./component/searchcity";
import { Vortex } from "react-loader-spinner";
import MainBottom from "./common/components/mainBottom";
import About from "./common/components/about";
import Header from "./common/components/header";
import Account from "./common/components/Account";
import Dashboard from "./component/dashboard";
export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    },2000); 
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter>
      {isLoading ? (
        <>
          <Header />
          <div className="loader flex justify-center items-center mt-14 mb-14">
            <Vortex
              visible={true}
              height="80"
              width="80"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["indigo", "red", "yellow", "green", "yellow", "orange"]}
            />
          </div>
          <MainBottom headingText="Search for Restaurants Near You" />
          <About />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<GetStart />} />
          <Route path="richmondavenue/:cityName" element={<RichmondAvenue />} />
          <Route path="city/:cityName" element={<CityPage />} />
          <Route path="aboutpage/" element={<AboutPage />} />
          <Route path="faqpage/" element={<FaqPage />} />
          <Route path="inforestaurant/" element={<InfoRestaurant />} />
          <Route path="infouser/" element={<InfoUser />} />
          <Route path="contact/" element={<ContactPage />} />
          <Route path="term/" element={<Term />} />
          <Route path="privacypolicy/" element={<PrivacyPolicy />} />
          <Route path="menu/:id" element={<Menu />} />
          <Route path="gallery/:id" element={<Gallery />} />
          <Route path="newOpening" element={<NewOpening />} />
          <Route path="scannedMenu/" element={<ScannedMenu />} />
          <Route path="dock-and-dine/" element={<DockAndDine />} />
          <Route path="searchCity/:resturant" element={<SearchCity />} />
          <Route path="account/" element={<Account />} />
          <Route path="dashboard/" element={<Dashboard />} />

          <Route
            path="sign-up-to-whereyoueat/"
            element={<Signuptowhereyoueat />}
          />
          <Route path="cuisine/:modified" element={<Restaurant />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
