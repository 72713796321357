import React, { useEffect, useState } from "react";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";
import About from "../common/components/about";
import { useNavigate } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import { BASE_URL, token } from "./token";

export default function ScannedMenu() {
  const [menuUrl, setMenuUrl] = useState([]);
  const [loader, setLoader] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [resturantData, setResturantData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getRestaurant`, {
      method: "POST",
      body: JSON.stringify({ rid: "6" }),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setResturantData(data?.result.restaurant);
        setLoader(true);
        setMenuData(data?.result.menu);
        setMenuUrl(data?.result.menu_url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <Header />
      <div className="border border-red-700"></div>
      <div className="container mx-auto  lg:px-28 px-4">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold">Scanned Menu</div>
          <button
            onClick={() => {
              navigate("/menu");
            }}
            className="flex mt-2  items-center p-2"
          >
            <div className="bg-black w-24 text-center cursor-pointer p-2 text-white  rounded-md">
              Close
            </div>
            <svg
              className="w-5 h-5"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
        {loader ? (
          <>
            <div className=" flex items-center justify-center ">
              {menuData.map((dataurl) => {
                return (
                  <>
                    <div className="flex justify-center items-center">
                      <img
                        className="w-full "
                        src={`${menuUrl}${dataurl.image
                          .split("~-~")
                          .filter((str) => str !== "")
                          .join("")}`}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center items-center">
              <Vortex
                visible={true}
                height="60"
                width="60"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["white", "red", "orange", "green", "yellow", "orange"]}
              />
            </div>
          </>
        )}
      </div>
      <MainBottom />
      <About />
    </>
  );
}
