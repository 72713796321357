import React, { Fragment, useEffect, useRef, useState } from "react";
import Logo from "../../img/logo.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Vortex } from "react-loader-spinner";
import logoImage from "../../img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, token } from "../../component/token";
import Modal from "react-modal";

export default function Header() {
  const navigate = useNavigate();
  const [click,setClick]=useState(false)
  const [activeButton, setActiveButton] = useState("button1");
  const [modalsLogin, setModalsLogin] = useState(false);
  const [showData, setShowData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showItems, setShowItems] = useState(true);
  const [showItem, setShowItem] = useState(true);
  const [showItemsData, setShowItemsData] = useState(true);
  const [cities, setCities] = useState([]);
  const [cityZips, setCityZips] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedCity, setSelectedCity] = useState("staten-island");
  const [searchCity, setSearchCity] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [isDataVisible, setIsDataVisible] = useState(false);
  const [isDataStaten, setIsDataStaten] = useState(false);
  const [isDataBrokliyn, setIsDataBrokliyn] = useState(false);
  const [isDataQueen, setIsDataQueen] = useState(false);
  const [isDataBronx, setIsDataBronx] = useState(false);
  const [neighborhood, setNeighborhood] = useState([]);
  const [showFeature, setShowFeature] = useState([]);
  const [showCuisines, setShowCuisines] = useState(false);
  const [dataShow, setDataShow] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [zipDataShow, setZipDataShow] = useState(false);
  const [neighborhoodShow, setNeighborhoodShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("staten island");
  const [selectedZip, setSelectedZip] = useState("");
  const [showServices, setShowServices] = useState(false);
  const [modelData, setModalData] = useState(false);
  const [loginData, setLoginData] = useState(false);
  const [anchor,setAnchor]=useState(null);
  const [store,setStore]=useState("")
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const ValidateEmail = () => {
    setClick(true);
    var validRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (validRegex.test(store.email)) {
      return true;
    } else setClick(true);
    {
      return false;
    }
  };
  const handleText = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleZipChange = (event) => {
    setSelectedZip(event.target.value);
  };
  let cityNameId = 0;
  if (selectedValue === "staten island") {
    cityNameId = 1;
  } else if (selectedValue === "brooklyn") {
    cityNameId = 2;
  } else if (selectedValue === "queens") {
    cityNameId = 3;
  } else if (selectedValue === "the-bronx") {
    cityNameId = 4;
  } else if (selectedValue === "manhattan") {
    cityNameId = 5;
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      zIndex: 9999,
      borderRadius: "10px",
    },
  };
  const handleLoginData = () => {
    setLoginData(!loginData);
  };
  const handleRegisterData = () => {
    setModalData(true);
  };
  //show hide data
  const FetchCuisines = () => {
    setShowCuisines(!showCuisines);
    setZipDataShow(false);
    setShowFeature(false);
    setNeighborhoodShow(false);
    cuisinData();
  };
  const FetchZip = () => {
    fetchCityZips();
    setZipDataShow(!zipDataShow);
    setShowCuisines(false);
    setShowFeature(false);
    setNeighborhoodShow(false);
  };
  const FeatureService = () => {
    cuisinData();
    setShowFeature(!showFeature);
    setShowCuisines(false);
    setZipDataShow(false);
    setNeighborhoodShow(false);
  };
  const FetchNeigh = () => {
    fetchNeigh();
    setNeighborhoodShow(!neighborhoodShow);
    setShowFeature(false);
    setShowCuisines(false);
    setShowFeature(false);
    setZipDataShow(false);
  };
  const FetchServices = () => {
    cuisinData();
    setShowServices(!showServices);
    setNeighborhoodShow(false);
    setShowFeature(false);
    setShowCuisines(false);
    setShowFeature(false);
    setZipDataShow(false);
  };
  const Staten = () => {
    setIsDataStaten(!isDataStaten);
    setSelectedValue("staten-island");
  };
  const Queen = () => {
    setIsDataQueen(!isDataQueen);
    setSelectedValue("queens");
  };
  const Brokline = () => {
    setIsDataBrokliyn(!isDataBrokliyn);
    setSelectedValue("brooklyn");
  };
  const Bronx = () => {
    setIsDataBronx(!isDataBronx);
    setSelectedValue("the-bronx");
  };
  const toggleDataVisibility = () => {
    setIsDataVisible(!isDataVisible);
    setSelectedValue("manhattan");
  };
  //delete
  const handleDelete = (index) => {
    const updatedSearchData = [...searchCity];
    updatedSearchData.splice(index);
    setSearchCity(updatedSearchData);
  };
  const handleNum = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");
    setSearchCity(sanitizedInput);
    if (value.length < 3) {
      setSearchData([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getRestaurantByName`, {
        method: "POST",
        body: JSON.stringify({
          keyword: searchCity,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      setSearchData(data.result.restaurant);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  //search Api
  const handleChange = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");

    setSearchTerm(sanitizedInput);
    if (value.length < 3) {
      setResults([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/search`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
        body: JSON.stringify({
          keyword: searchTerm,
        }),
      });
      const data = await response.json();
      setResults(data.result);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleLogin = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/login`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "done") {
          navigate("/dashboard");
        } else {
        }
      } else {
      }
    } catch (error) {
      // Handle network errors or other exceptions
    }
  };
  //city Api
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getCity`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCities(data?.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //cuisine feature
  const cuisinData = () => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: "all" }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDataShow(data?.result?.feature);
        setCuisines(data?.result?.cusine);
        setServiceData(data?.result?.service);
        // bronxData(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //neighborhood api
  const fetchNeigh = () => {
    fetch(`${BASE_URL}/wye-api/getNeighborhood`, {
      method: "POST",
      body: JSON.stringify({ city: selectedCity }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNeighborhood(data?.result);
      })
      .catch((error) => {
        console.log(error, "this is errror");
      });
  };
  const fetchCityZips = () => {
    fetch(`${BASE_URL}/wye-api/getZip`, {
      method: "POST",
      body: JSON.stringify({ city: selectedCity }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCityZips(data?.result);
      })
      .catch((error) => {
        console.log(error, "this is error");
        console.error(error);
      });
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    fetchCityZips(event.target.value);
  };
  function handleShowItems() {
    setShowItems(true);
  }
  function handleFour() {
    setShowData(true);
  }
  function handleOpen() {
    setShowItem(true);
  }
  function handleShow() {
    setShowItemsData(true);
  }
  const handleClick = (button) => {
    setActiveButton(button);
  };
  const [state, setState] = useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const toggle = (anchors, on) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchors]: on });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const removeData = () => {
    setSearchData(!searchData);
  };
  const listData = () => (
    <>
      <div className="ml-3">
        <div className="flex justify-between  items-center gap-10 px-5 py-5 ">
          <img className="w-full h-8  " src={logoImage} />
          <svg
            onClick={toggleDrawer(anchor, false)}
            className="h-5 cursor-pointer w-5"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div className="flex justify-between">
          <select>
            <option>Login</option>
          </select>
          <select>
            <option>Favorites</option>
            <option>Try List</option>
            <option>Photos</option>
            <option>Reviews</option>
          </select>
        </div>
      </div>
    </>
  );
  const list = (anchor) => (
    <>
      <div className="ml-3">
        <div className="flex justify-between  items-center gap-10 px-5 py-5 ">
          <img className="w-full h-8  " src={logoImage} />
          <svg
            onClick={toggleDrawer(anchor, false)}
            className="h-5 cursor-pointer w-5"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div className="flex justify-between mr-2  mb-3 ">
          <div>
            <div className="px-4">
              <button onClick={handleShowItems} className=" font-bold">
                Explore Our City Dining Guides
              </button>
              {showItems && (
                <div className=" py-2 flex ml-4  ">
                  <div className="">
                    <div className="font-bold mb-2">
                      <Link to="https://reactjs.orderingspace.net/city/staten-island">
                        Staten Island
                      </Link>
                    </div>
                    <div className="font-bold mb-2">
                      <Link to="https://reactjs.orderingspace.net/city/brooklyn">
                        Brooklyn
                      </Link>
                    </div>
                    <div className="font-bold mb-2">
                      <Link to="https://reactjs.orderingspace.net/city/queens">
                        Queens
                      </Link>
                    </div>
                    <div className="font-bold mb-2">
                      <Link to="https://reactjs.orderingspace.net/city/the-bronx">
                        The Bronx
                      </Link>
                    </div>
                    <div className="font-bold mb-2">
                      <Link to="https://reactjs.orderingspace.net/city/manhattan">
                        Manhattan
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="border border-slate-300"></div>
          </div>
          <svg
            className="h-4 w-4 mt-2 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
        </div>
        <div className="flex justify-between  mb-3 mr-2">
          <div>
            <div className="px-4">
              <button onClick={handleOpen} className="font-bold mb-2">
                Restaurant Owners
              </button>
              {showItem && (
                <div className="ml-4">
                  <div className="font-bold">
                    <Link to="/sign-up-to-whereyoueat mb-2">
                      Sign Up To WhereYouEat
                    </Link>
                  </div>
                  <div className="font-bold mb-2 mt-2">
                    <Link to="/contactpage">Contact Us</Link>
                  </div>
                </div>
              )}
            </div>
            <div className="border border-b border-slate-300"></div>
          </div>
          <svg
            onClick={handleOpen}
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
        </div>
        <div className="flex justify-between  mb-3 mr-2">
          <div>
            <div className="px-4">
              <button onClick={handleShow} className="font-bold">
                Support
              </button>
              {showItemsData && (
                <div className=" py-2 ml-4">
                  <div className="mt-2 mb-2 font-bold">
                    <Link to="/contact ">Suggestions & Comments</Link>
                  </div>
                  <div className="font-bold mb-2">Report A Problem</div>
                  <div className="font-bold">800.865.8997</div>
                </div>
              )}
            </div>
            <div className="border border-b border-slate-300 mt-2"></div>
          </div>
          <svg
            onClick={handleShow}
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
        </div>
        <div></div>
        <button className="text-orange-800 px-5 mt-4 font-bold ">
          Call@800.865.8997
        </button>
      </div>
    </>
  );
  const listItem = (anchors) => (
    <>
      <div className="">
        <div className="flex justify-between  items-center gap-10 px-5 py-5 ">
          <img className="w-full h-10  " src={logoImage} />
          <svg
            onClick={toggle(anchors, false)}
            className="h-5 w-5 cursor-pointer"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div className="flex ml-4 px-2">
          <input
            value={searchCity}
            onChange={handleNum}
            className="border h-10 p-2  bg-[#990000]mb-2"
          />
          <div className="border flex justify-center items-center bg-[#990000] h-10 w-10">
            <svg
              fill="white"
              className="h-5 w-5 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
          </div>
          <div className="scrollable-list fixed  mt-10 z-50 px-4  bg-white overflow-hidden ">
            <div className="">
              {searchData.length > 3 && !isScrolling ? (
                <>
                  {searchData.map((pk, index) => (
                    <>
                      <div
                        onClick={() => {
                          const modified = pk.rname.replace(/\s/g, "");
                          navigate(`/menu/-${pk.id}-${modified}`);
                          window.location.reload();
                        }}
                        className="cursor-pointer "
                      >
                        <div className="">
                          <div
                            className="  pt-2  font-bold text-red-900    "
                            key={index}
                          >
                            {pk.rname}
                          </div>
                          <div
                            className="   border-b text-sm pb-2 text-slate-600 border-slate-500   font-semibold "
                            key={index}
                          >
                            {pk.address}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className=" border-b mt-4 border-slate-300  mb-3">
          <div className="flex justify-between items-center">
            <button className="font-bold ml-3 mb-2" onClick={Staten}>
              STATEN ISLAND
            </button>
            <div>
              <svg
                onClick={Staten}
                className="h-4 w-4 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
          {isDataStaten && (
            <>
              <div className="flex justify-between items-center px-2 border-b border-slate-300">
                <div
                  onClick={FetchCuisines}
                  className="font-normal mt-2 ml-3 cursor-pointer "
                >
                  By Cuisine
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {showCuisines && (
                <>
                  <div className="grid grid-cols-2 gap-2 border-b ml-3">
                    {cuisines.length > 0 ? (
                      <>
                        {cuisines.map((dummy) => {
                          return (
                            <>
                              <button
                                onClick={() => {
                                  const modifi = dummy.name.replace(/\s/g, "");
                                  const modified = "cuisine"
                                    .replace(/\s/g, "")
                                    .toLowerCase();
                                  const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                                  navigate(url);
                                }}
                                className="mt-2 cursor-pointer "
                              >
                                {dummy.name}
                              </button>
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </>
              )}
              <div className="flex justify-between items-center px-2 border-slate-300 border-b ">
                <div
                  onClick={FetchNeigh}
                  className="font-normal mt-2 ml-3  cursor-pointer"
                >
                  By Neighborhood
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {neighborhoodShow && (
                <>
                  <div className="grid grid-cols-2 gap-2  ml-3">
                    {neighborhood.map((neigh) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              const modified = neigh.neighborhood_name.replace(
                                /\s/g,
                                ""
                              );
                              const url = `/cuisine/-${modified}-neighborhood-${selectedValue}-${neigh.id}`;

                              navigate(url);
                            }}
                            className="mt-2 cursor-pointer"
                          >
                            {neigh.neighborhood_name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
              <div className="flex justify-between items-center border-b px-2 border-slate-300">
                <div
                  onClick={FetchZip}
                  className="font-normal mt-2 cursor-pointer  ml-3 "
                >
                  By Zip
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {zipDataShow && (
                <>
                  <div className="grid grid-cols-2">
                    {cityZips.length > 0 ? (
                      <>
                        {cityZips.map((zips) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  const modified = "zip".replace(/\s/g, "");
                                  const city = "".replace(/\s/g, "");

                                  const url = `/cuisine/-${city}-${modified}-${selectedValue}-${zips.zip}`;
                                  navigate(url);
                                }}
                                className="px-4 cursor-pointer ml-3 "
                              >
                                {zips.zip}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}

              <div className="flex justify-between items-center px-2  border-b border-slate-300">
                <div
                  onClick={FeatureService}
                  className="font-normal mt-2 cursor-pointer  ml-3"
                >
                  By Features
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>

              {showFeature && (
                <>
                  <div className="grid grid-cols-2 gap-2 ">
                    {dataShow.map((feature) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              const modified = feature.feature_name.replace(
                                /\s/g,
                                ""
                              );

                              const url = `/cuisine/-${modified}-feature-${selectedValue}-${feature.id}`;

                              navigate(url);
                              window.location.reload();
                            }}
                            className="mt-2"
                          >
                            {feature.feature_name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
              <div className="flex justify-between items-center px-2">
                <div
                  onClick={FetchServices}
                  className="font-normal mt-2 cursor-pointer  ml-3 "
                >
                  By Services
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {showServices && (
                <>
                  <div className="grid grid-cols-2">
                    {serviceData.map((user) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              const modified = user.service_name.replace(
                                /\s/g,
                                ""
                              );

                              const url = `/cuisine/-${modified}-service-${selectedValue}-${user.id}`;

                              navigate(url);
                              window.location.reload();
                            }}
                            className="px-4 cursor-pointer py-1"
                          >
                            {user.service_name}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className=" border-b  border-slate-300  mb-3">
          <div className="flex justify-between ml-3 items-center">
            <button className="font-bold " onClick={Brokline}>
              BROOKLYN
            </button>
            <svg
              onClick={Brokline}
              className="h-4 w-4 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          </div>
          {isDataBrokliyn && (
            <>
              <div className=" flex justify-between items-center px-2 border-b border-slate-300 ">
                <div
                  onClick={FetchCuisines}
                  className="font-normal mt-2 cursor-pointer  ml-3"
                >
                  By Cuisine
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {showCuisines && (
                <>
                  <div className="grid grid-cols-2 gap-2 border-b">
                    {cuisines.map((dummy) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              const modifi = dummy.name.replace(/\s/g, "");
                              const modified = "cuisine"
                                .replace(/\s/g, "")
                                .toLowerCase();
                              const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                              navigate(url);
                            }}
                            className="mt-2 cursor-pointer"
                          >
                            {dummy.name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
              <div className="flex justify-between border-b border-slate-300 items-center px-2 ">
                <div
                  onClick={FetchNeigh}
                  className="font-normal mt-2  ml-3 cursor-pointer "
                >
                  By Neighborhood
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {showFeature && (
                <>
                  <div className="grid grid-cols-2 gap-2">
                    {neighborhood.map((neigh) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              const modified = neigh.neighborhood_name.replace(
                                /\s/g,
                                ""
                              );
                              const url = `/cuisine/-${modified}-neighborhood-${selectedValue}-${neigh.id}`;

                              navigate(url);
                            }}
                            className="mt-2 cursor-pointer"
                          >
                            {neigh.neighborhood_name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              <div className=" flex justify-between items-center px-2  border-b border-slate-300 mt-2 ">
                <div
                  onClick={FetchZip}
                  className="cursor-pointer  font-normal ml-3 "
                >
                  By Zip
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {zipDataShow && (
                <>
                  <div className="grid grid-cols-2">
                    {cityZips.length > 0 ? (
                      <>
                        {cityZips.map((zips) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  const modified = "zip".replace(/\s/g, "");
                                  const city = "".replace(/\s/g, "");

                                  const url = `/cuisine/-${city}-${modified}-${selectedValue}-${zips.zip}`;
                                  navigate(url);
                                }}
                                className="px-4 cursor-pointer "
                              >
                                {zips.zip}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
              <div className="flex justify-between items-center px-2 border-b border-slate-300 ">
                <button
                  onClick={FeatureService}
                  className="font-normal mt-2 cursor-pointer ml-3  "
                >
                  By Features
                </button>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              {showFeature && (
                <>
                  <div className="grid grid-cols-2 gap-2">
                    {dataShow.map((feature) => {
                      return (
                        <>
                          <button
                            onClick={() => {
                              const modified = feature.feature_name.replace(
                                /\s/g,
                                ""
                              );

                              const url = `/cuisine/-${modified}-feature-${selectedValue}-${feature.id}`;

                              navigate(url);
                            }}
                            className="mt-2"
                          >
                            {feature.feature_name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              <div className="flex justify-between items-center px-2">
                <div
                  onClick={FeatureService}
                  className="font-normal mt-2 ml-3 cursor-pointer "
                >
                  By Services
                </div>
                <div>
                  <svg
                    onClick={handleFour}
                    className="h-4 w-4 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
              </div>
              <div className="grid grid-cols-2">
                {serviceData.map((user) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          const modified = user.service_name.replace(/\s/g, "");

                          const url = `/cuisine/-${modified}-service-${selectedValue}-${user.id}`;

                          navigate(url);
                        }}
                        className="px-4 cursor-pointer py-1"
                      >
                        {user.service_name}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="border-b  border-slate-300  mb-3">
        <div className="ml-3">
          <div className="flex justify-between items-center">
            <button className="font-bold" onClick={Queen}>
              QUEENS
            </button>
            <div>
              <svg
                onClick={Queen}
                className="h-4 w-4 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
          {isDataQueen && (
            <>
              <div className="">
                <div className=" border-b border-slate-300 flex  justify-between items-center px-2">
                  <div
                    onClick={FetchCuisines}
                    className="font-normal mt-2  cursor-pointer"
                  >
                    By Cuisine
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showCuisines && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {cuisines.map((dummy) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modifi = dummy.name.replace(/\s/g, "");
                                const modified = "cuisine"
                                  .replace(/\s/g, "")
                                  .toLowerCase();
                                const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {dummy.name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2">
                  <div
                    onClick={FetchNeigh}
                    className="font-normal mt-2 cursor-pointer border-b border-slate-300"
                  >
                    By Neighborhood
                  </div>

                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {neighborhood.map((neigh) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified =
                                  neigh.neighborhood_name.replace(/\s/g, "");
                                const url = `/cuisine/-${modified}-neighborhood-${selectedValue}-${neigh.id}`;

                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {neigh.neighborhood_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FetchZip}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Zip
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {zipDataShow && (
                  <>
                    <div className="grid grid-cols-2">
                      {cityZips.length > 0 ? (
                        <>
                          {cityZips.map((zips) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modified = "zip".replace(/\s/g, "");
                                    const city = "".replace(/\s/g, "");

                                    const url = `/cuisine/-${city}-${modified}-${selectedValue}-${zips.zip}`;
                                    navigate(url);
                                  }}
                                  className="px-4 cursor-pointer "
                                >
                                  {zips.zip}
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-slate-300 border-b">
                  <div
                    onClick={FeatureService}
                    className="font-normal cursor-pointer mt-2 "
                  >
                    By Features
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2">
                      {dataShow.map((feature) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified = feature.feature_name.replace(
                                  /\s/g,
                                  ""
                                );
                                const url = `/cuisine/-${modified}-feature-${selectedValue}-${feature.id}`;

                                navigate(url);
                              }}
                              className="mt-2"
                            >
                              {feature.feature_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}

                <div className="flex justify-between items-center px-2">
                  <div
                    onClick={FeatureService}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Services
                  </div>
                  <svg
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </div>
                <div className="grid grid-cols-2 ">
                  {serviceData.map((user) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            const modified = user.service_name.replace(
                              /\s/g,
                              ""
                            );

                            const url = `/cuisine/-${modified}-service-${selectedValue}-${user.id}`;

                            navigate(url);
                          }}
                          className="px-4 cursor-pointer py-1"
                        >
                          {user.service_name}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="  border-b  border-slate-300  mb-3">
        <div className="ml-3">
          <div className="flex justify-between items-center">
            <button className="font-bold" onClick={Bronx}>
              THE BRONX
            </button>
            <div>
              <svg
                onClick={handleFour}
                className="h-4 w-4 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
          {isDataBronx && (
            <>
              <div className="">
                <div className="flex justify-between px-2 items-center border-b border-slate-300">
                  <div
                    onClick={FetchCuisines}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Cuisine
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showCuisines && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {cuisines.map((dummy) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modifi = dummy.name.replace(/\s/g, "");
                                const modified = "cuisine"
                                  .replace(/\s/g, "")
                                  .toLowerCase();
                                const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {dummy.name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center border-b border-slate-300 px-2">
                  <div
                    onClick={FetchNeigh}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Neighborhood
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {neighborhood.map((neigh) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified =
                                  neigh.neighborhood_name.replace(/\s/g, "");
                                const url = `/cuisine/-${modified}-neighborhood-${selectedValue}-${neigh.id}`;

                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {neigh.neighborhood_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center  border-b border-slate-300 px-2 ">
                  <div
                    onClick={FetchZip}
                    className="font-normal mt-2 cursor-pointer"
                  >
                    By Zip
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {zipDataShow && (
                  <>
                    <div className="grid grid-cols-2">
                      {cityZips.length > 0 ? (
                        <>
                          {cityZips.map((zips) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modified = "zip".replace(/\s/g, "");
                                    const city = "".replace(/\s/g, "");
                                    const url = `/cuisine/-${city}-${modified}-${selectedValue}-${zips.zip}`;
                                    navigate(url);
                                  }}
                                  className="px-4 cursor-pointer "
                                >
                                  {zips.zip}
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FeatureService}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Features
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {dataShow.map((feature) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified = feature.feature_name.replace(
                                  /\s/g,
                                  ""
                                );

                                const url = `/cuisine/-${modified}-feature-${selectedValue}-${feature.id}`;

                                navigate(url);
                              }}
                              className="mt-2"
                            >
                              {feature.feature_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}

                <div className="flex justify-between items-center px-2">
                  <div
                    onClick={FeatureService}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Services
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  {serviceData.map((user) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            const modified = user.service_name.replace(
                              /\s/g,
                              ""
                            );

                            const url = `/cuisine/-${modified}-service-${selectedValue}-${user.id}`;

                            navigate(url);
                          }}
                          className="px-4 cursor-pointer py-1"
                        >
                          {user.service_name}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="  border-b  border-slate-300  mb-3">
        <div className="ml-3">
          <div className="flex justify-between items-center">
            <button className="font-bold" onClick={toggleDataVisibility}>
              MANHATTAN
            </button>
            <div>
              <svg
                onClick={toggleDataVisibility}
                className="h-4 w-4 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
          {isDataVisible && (
            <>
              <div className="">
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FetchCuisines}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Cuisine
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showCuisines && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {cuisines.map((dummy) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modifi = dummy.name.replace(/\s/g, "");
                                const modified = "cuisine"
                                  .replace(/\s/g, "")
                                  .toLowerCase();
                                const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {dummy.name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                {showCuisines && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {cuisines.map((dummy) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modifi = dummy.name.replace(/\s/g, "");
                                const modified = "cuisine".replace(/\s/g, "");
                                const url = `/cuisine/-${modifi}-${modified}-${selectedValue}-${dummy.id}`;
                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {dummy.name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FetchNeigh}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Neighborhood
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {neighborhood.map((neigh) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified =
                                  neigh.neighborhood_name.replace(/\s/g, "");
                                const url = `/cuisine/-${modified}-neighborhood-${selectedValue}-${neigh.id}`;

                                navigate(url);
                              }}
                              className="mt-2 cursor-pointer"
                            >
                              {neigh.neighborhood_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FetchZip}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Zip
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {zipDataShow && (
                  <>
                    <div className="grid grid-cols-2">
                      {cityZips.length > 0 ? (
                        <>
                          {cityZips.map((zips) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const city = "".replace(/\s/g, "");
                                    const modified = "zip".replace(/\s/g, "");
                                    const url = `/cuisine/-${city}-${modified}-${selectedValue}-${zips.zip}`;
                                    navigate(url);
                                  }}
                                  className="px-4 cursor-pointer "
                                >
                                  {zips.zip}
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2 border-b border-slate-300">
                  <div
                    onClick={FeatureService}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Features
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                {showFeature && (
                  <>
                    <div className="grid grid-cols-2 gap-2 border-b">
                      {dataShow.map((feature) => {
                        return (
                          <>
                            <button
                              onClick={() => {
                                const modified = feature.feature_name.replace(
                                  /\s/g,
                                  ""
                                );

                                const url = `/cuisine/-${modified}-feature-${selectedValue}-${feature.id}`;

                                navigate(url);
                              }}
                              className="mt-2"
                            >
                              {feature.feature_name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center px-2">
                  <div
                    onClick={FeatureService}
                    className="font-normal mt-2 cursor-pointer "
                  >
                    By Services
                  </div>
                  <div>
                    <svg
                      onClick={handleFour}
                      className="h-4 w-4 cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  {serviceData.map((user) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            const modified = user.service_name.replace(
                              /\s/g,
                              ""
                            );
                            const url = `/cuisine/-${modified}-service-${selectedValue}-${user.id}`;
                            navigate(url);
                          }}
                          className="px-4 cursor-pointer py-1"
                        >
                          {user.service_name}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  return (
    <div>
      <div className="">
        <>
          <Modal
            isOpen={modalsLogin}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="bg-white p-2 rounded-md  lg:w-[500px] w-full">
              <div className="flex   bg-slate-200 rounded-md p-4  items-center  ">
                <div className="text-lg  font-semibold ml-2">LOGIN</div>
                <div className="flex justify-center w-full   items-center gap-2">
                  <div className="flex gap-4 ">
                    <div className="text-sm cursor-pointer">No Account?</div>
                    <div
                      onClick={handleRegisterData}
                      className="text-sm cursor-pointer"
                    >
                      Register
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setModalsLogin(false);
                    }}
                    className="bg-red-600 rounded-lg  p-2 cursor-pointer  absolute  right-8 border border-white "
                  >
                    <svg
                      className="h-5 w-5  "
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div>Email or Phone # to Login</div>
                <input
                  onChange={handleText}
                  value={data.username}
                  name="username"
                  className="w-full border border-black h-10 p-1 rounded"
                />
                <div className="mt-2">Password</div>
                <input
                  onChange={handleText}
                  value={data.password}
                  name="password"
                  type="password"
                  className="w-full border border-black p-1 rounded"
                />
              </div>
              <div className=" flex items-center justify-between  px-2 gap-2 mt-10">
                <div className="text-red-600">Forgot Account Details?</div>
                <button
                  onClick={handleLogin}
                  className="text-white bg-green-600 w-40 p-2 rounded-md text-center "
                >
                  Login
                </button>
              </div>
            </div>
          </Modal>
        </>
      </div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className=" ">
            <div>
              <div className="px-2 flex justify-evenly items-center container mx-auto lg:w-[1170px]  w-full     ">
                <div className=" flex  gap-2   items-center">
                  <svg
                    onClick={toggleDrawer(anchor, true)}
                    className="lg:w-10 cursor-pointer  w-8  "
                    fill="#88001b"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                  </svg>

                  {["right"].map((anchors) => (
                    <React.Fragment key={anchors}>
                      <button
                        onClick={toggle(anchors, true)}
                        className="h-10  flex justify-center items-center lg:w-20 lg:h-12 w-10 rounded-md bg-[#88001b]"
                      >
                        <svg
                          fill="#fff"
                          className="lg:w-8 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                        </svg>
                      </button>
                      <SwipeableDrawer
                        anchor={anchors}
                        open={state[anchors]}
                        onClose={toggle(anchors, false)}
                        onOpen={toggle(anchors, true)}
                      >
                        {listItem(anchors)}
                      </SwipeableDrawer>
                    </React.Fragment>
                  ))}
                  <Link
                    style={{ cursor: "pointer" }}
                    to="/"
                    className="lg:w-full w-2/4 ml-2 lg:mx-auto container "
                  >
                    <img src={Logo} />
                  </Link>
                </div>
                <div className=" lg:block hidden">
                  <div className="flex   w-full lg:gap-10 gap-2  item-center justify-center  lg:text-xl text-sm  ">
                    <div className=" px-1  ml-2  lg:text-lg md:text-sm p-1">
                      Search By:-
                    </div>
                    <button
                      to="#"
                      className={
                        activeButton === "button1"
                          ? "activeone btn btn-solid  lg:text-lg md:text-sm px-2  font-semibold  rounded"
                          : "btn btn-solid lg:text-lg md:text-sm"
                      }
                      onClick={() => handleClick("button1")}
                    >
                      Name
                    </button>
                    <button
                      to="#"
                      className={
                        activeButton === "button2"
                          ? "activeone btn btn-solid lg:text-lg md:text-sm   hover:underline  font-semibold rounded"
                          : " btn btn-solid lg:text-lg md:text-sm"
                      }
                      onClick={() => handleClick("button2")}
                    >
                      Near Me
                    </button>
                    <button
                      to="#"
                      className={
                        activeButton === "button3"
                          ? "activeone btn btn-solid lg:text-lg md:text-sm px-2   font-semibold rounded"
                          : "btn btn-solid lg:text-lg md:text-sm"
                      }
                      onClick={() => handleClick("button3")}
                    >
                      Zip Code
                    </button>
                  </div>
                  <div className="mx-auto w-full mb-2     justify-center items-center py-1 flex   ">
                    {activeButton === "button1" ? (
                      <div className="">
                        <div className="flex    lg:ml-10 ml-2  justify-between border border-red-900 rounded-md w-full ">
                          <input
                            className="lg:w-[380px] w-full"
                            value={searchCity}
                            onChange={handleNum}
                            style={{
                              outline: "none",
                              padding: 2,
                              marginLeft: 4,
                            }}
                            placeholder="Type restaurant name"
                          />
                          <div
                            onClick={removeData}
                            className="flex gap-2 cursor-pointer  justify-center items-center"
                          >
                            <div className="">
                              <svg
                                onClick={handleDelete}
                                className="h-5 w-5  "
                                fill="#88001b"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                              >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                              </svg>
                            </div>
                            <div className="border-l p-3 h-8 flex justify-center items-center border-[#990000] ">
                              <svg
                                fill="#88001b"
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="scrollable-list fixed  z-50 lg:w-[300px] lg:ml-10   bg-white overflow-hidden ">
                          <div className="">
                            {searchData.length > 3 && !isScrolling ? (
                              <>
                                {searchData.map((pk, index) => (
                                  <>
                                    <div
                                      onClick={() => {
                                        const modified = pk.rname.replace(
                                          /\s/g,
                                          ""
                                        );
                                        navigate(`/menu/-${pk.id}-${modified}`);
                                        window.location.reload();
                                      }}
                                      className="cursor-pointer mt-2"
                                    >
                                      <div className="">
                                        <div
                                          className="  pt-2   text-red-900  ml-1  "
                                          key={index}
                                        >
                                          {pk.rname}
                                        </div>
                                        <div
                                          className="   border-b text-sm pb-2 text-slate-600 border-slate-500  ml-1  "
                                          key={index}
                                        >
                                          {pk.address}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {activeButton === "button2" ? (
                      <div>
                        <div className="flex w-full  ml-2 lg:ml-10 items-center mx-auto justify-center ">
                          <div
                            style={{
                              borderTopLeftRadius: 10,
                              borderBottomLeftRadius: 10,
                            }}
                            className="h-8 w-16 bg-white border-[#990000] border-t border-b border-l justify-center items-center flex p-2"
                          >
                            <svg
                              fill="#88001b"
                              className="w-7 h-7"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                            </svg>
                          </div>
                          <input
                            onChange={handleChange}
                            value={searchTerm}
                            style={{
                              outline: "none",
                              borderTopRightRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                            className="border border-[#990000] lg:w-[420px]  w-full h-8 p-2    "
                            placeholder="Type Your Address Here"
                          />
                        </div>
                        <ul className="scrollable-list fixed   z-50 lg:w-[300px]   bg-white overflow-hidden">
                          {results.length > 3 ? (
                            <>
                              {results.map((result, index) => {
                                const modifiedPanelName = result.address
                                  .replace(/\W/g, "")
                                  .toLowerCase();

                                // Check if modifiedPanelName contains only digits
                                if (/^\d+$/.test(modifiedPanelName)) {
                                  return (
                                    <li
                                      onClick={() => {
                                        navigate(
                                          `/searchCity/${modifiedPanelName}`
                                        );
                                      }}
                                      className="px-6 py-2 leading-3 font-bold text-red-900"
                                      key={index}
                                    >
                                      {result.address}
                                    </li>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          const cityName = result.rname.replace(
                                            /\s/g,
                                            ""
                                          );
                                          navigate(
                                            `/menu/}-${result.id}-${cityName}`
                                          );
                                        }}
                                        className="border-b mb-2 mt-2 slate-300 cursor-pointer"
                                      >
                                        <li
                                          className="px-6   font-bold text-red-900"
                                          key={index}
                                        >
                                          {result.rname}
                                        </li>
                                        <li className="px-6   " key={index}>
                                          {result.address}
                                        </li>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    ) : null}
                    {activeButton === "button3" ? (
                      <div className="      ">
                        <div className="  px-2 py-1  lg:ml-14   ml-2 bg-white  ">
                          <div className="flex gap-2 items-center">
                            <div>
                              <select
                                value={selectedCity}
                                onChange={handleCityChange}
                                className="  h-8 lg:w-[250px]  w-full px-3  bg-white border border-slate-500 rounded-md  "
                              >
                                <option value="">Select a City</option>
                                {cities.map((city) => {
                                  return (
                                    <>
                                      <option
                                        value={city.id}
                                        key={city.id}
                                        className=" leading-6"
                                      >
                                        {city.cityName.toUpperCase()}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                            <div>
                              <select
                                onChange={handleZipChange}
                                onClick={fetchCityZips}
                                className="h-8 lg:w-[250px]  w-full px-3  bg-white border border-slate-500 rounded-md  "
                              >
                                <option className="text-center">
                                  select Zip
                                </option>
                                {cityZips.length == 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    {cityZips.map((user, index) => {
                                      return (
                                        <>
                                          <option
                                            key={index}
                                            value={user.zip}
                                            className="  leading-6 text-center"
                                          >
                                            {user.zip}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="px-2 ml-2">
                          <button
                            onClick={() => {
                              navigate(`/searchCity/${selectedZip}`);
                              window.location.reload();
                            }}
                            className="bg-[#990000] flex w-full lg:ml-12 lg:w-[510px] border border-black justify-center items-center  mt-2 h-8"
                          >
                            {loader ? (
                              <Vortex
                                visible={true}
                                height="40"
                                width="40"
                                ariaLabel="vortex-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                colors={[
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                  "white",
                                ]}
                              />
                            ) : (
                              <div className="text-white font-semibold text-1xl">
                                SEARCH
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex">
                  <div
                    onClick={() => {
                      setModalsLogin(true);
                    }}
                    className="  lg:ml-14 cursor-pointer"
                  >
                    <svg
                      fill="#88001b"
                      className="lg:w-20 lg:h-10 h-8 w-10 "
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    <div className="cursor-pointer lg:font-bold text-center lg:text-[14px] text-[10px] ">
                      Try List
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setModalsLogin("true");
                    }}
                    className="cursor-pointer"
                  >
                    <svg
                      fill="#88001b"
                      className="lg:w-20 lg:h-10 h-8 w-10 "
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                    </svg>
                    <div className="lg:font-bold text-center lg:text-[14px] text-[10px] ">
                      Fav's
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <svg
                      fill="#88001b"
                      className="lg:w-20 lg:h-10 h-8 w-10 "
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                    </svg>
                    <div className="lg:font-bold text-center lg:text-[14px] text-[10px]">
                      Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <div className="border border-red-900 lg:hidden block"></div>
      <div className=" lg:hidden block">
        <div className="flex   w-full lg:gap-10 gap-2  item-center justify-center  lg:text-xl text-sm  ">
          <div className=" px-1  ml-2  lg:text-lg md:text-sm p-1">
            Search By:-
          </div>
          <button
            to="#"
            className={
              activeButton === "button1"
                ? "activeone btn btn-solid  lg:text-lg md:text-sm px-2  font-semibold  rounded"
                : "btn btn-solid lg:text-lg md:text-sm"
            }
            onClick={() => handleClick("button1")}
          >
            Name
          </button>
          <button
            to="#"
            className={
              activeButton === "button2"
                ? "activeone btn btn-solid lg:text-lg md:text-sm   hover:underline  font-semibold rounded"
                : " btn btn-solid lg:text-lg md:text-sm"
            }
            onClick={() => handleClick("button2")}
          >
            Near Me
          </button>
          <button
            to="#"
            className={
              activeButton === "button3"
                ? "activeone btn btn-solid lg:text-lg md:text-sm px-2   font-semibold rounded"
                : "btn btn-solid lg:text-lg md:text-sm"
            }
            onClick={() => handleClick("button3")}
          >
            Zip Code
          </button>
        </div>
        <div className="mx-auto w-full mb-2     justify-center items-center py-1 flex   ">
          {activeButton === "button1" ? (
            <div className="">
              <div className="flex    lg:ml-10 ml-2  justify-between border border-red-900 rounded-md w-full ">
                <input
                  className="lg:w-[380px] w-full"
                  value={searchCity}
                  onChange={handleNum}
                  style={{
                    outline: "none",
                    padding: 2,
                    marginLeft: 4,
                  }}
                  placeholder="Type restaurant name"
                />
                <div
                  onClick={removeData}
                  className="flex gap-2 cursor-pointer  justify-center items-center"
                >
                  <div className="">
                    <svg
                      onClick={handleDelete}
                      className="h-5 w-5  "
                      fill="#88001b"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </div>
                  <div className="border-l p-3 h-8 flex justify-center items-center border-[#990000] ">
                    <svg
                      fill="#88001b"
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="scrollable-list fixed  z-50 lg:w-[300px] lg:ml-10   bg-white overflow-hidden ">
                <div className="">
                  {searchData.length > 3 && !isScrolling ? (
                    <>
                      {searchData.map((pk, index) => (
                        <>
                          <div
                            onClick={() => {
                              const modified = pk.rname.replace(/\s/g, "");
                              navigate(`/menu/-${pk.id}-${modified}`);
                              window.location.reload();
                            }}
                            className="cursor-pointer mt-2"
                          >
                            <div className="">
                              <div
                                className="  pt-2   text-red-900  ml-1  "
                                key={index}
                              >
                                {pk.rname}
                              </div>
                              <div
                                className="   border-b text-sm pb-2 text-slate-600 border-slate-500  ml-1  "
                                key={index}
                              >
                                {pk.address}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === "button2" ? (
            <div>
              <div className="flex w-full  ml-2 lg:ml-10 items-center mx-auto justify-center ">
                <div
                  style={{
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                  className="h-8 w-16 bg-white border-[#990000] border-t border-b border-l justify-center items-center flex p-2"
                >
                  <svg
                    fill="#88001b"
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                </div>
                <input
                  onChange={handleChange}
                  value={searchTerm}
                  style={{
                    outline: "none",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                  className="border border-[#990000] lg:w-[420px]  w-full h-8 p-2    "
                  placeholder="Type Your Address Here"
                />
              </div>
              <ul className="scrollable-list fixed   z-50 lg:w-[300px]   bg-white overflow-hidden">
                {results.length > 3 ? (
                  <>
                    {results.map((result, index) => {
                      const modifiedPanelName = result.address
                        .replace(/\W/g, "")
                        .toLowerCase();

                      // Check if modifiedPanelName contains only digits
                      if (/^\d+$/.test(modifiedPanelName)) {
                        return (
                          <li
                            onClick={() => {
                              navigate(`/searchCity/${modifiedPanelName}`);
                            }}
                            className="px-6 py-2 leading-3 font-bold text-red-900"
                            key={index}
                          >
                            {result.address}
                          </li>
                        );
                      } else {
                        return (
                          <>
                            <div
                              onClick={() => {
                                const cityName = result.rname.replace(
                                  /\s/g,
                                  ""
                                );
                                navigate(`/menu/}-${result.id}-${cityName}`);
                              }}
                              className="border-b mb-2 mt-2 slate-300 cursor-pointer"
                            >
                              <li
                                className="px-6   font-bold text-red-900"
                                key={index}
                              >
                                {result.rname}
                              </li>
                              <li className="px-6   " key={index}>
                                {result.address}
                              </li>
                            </div>
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          ) : null}
          {activeButton === "button3" ? (
            <div className="      ">
              <div className="  px-2 py-1  lg:ml-14   ml-2 bg-white  ">
                <div className="flex gap-2 items-center">
                  <div>
                    <select
                      value={selectedCity}
                      onChange={handleCityChange}
                      className="  h-8 lg:w-[250px]  w-full px-3  bg-white border border-slate-500 rounded-md  "
                    >
                      <option value="">Select a City</option>
                      {cities.map((city) => {
                        return (
                          <>
                            <option
                              value={city.id}
                              key={city.id}
                              className=" leading-6"
                            >
                              {city.cityName.toUpperCase()}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <select
                      onChange={handleZipChange}
                      onClick={fetchCityZips}
                      className="h-8 lg:w-[250px]  w-full px-3  bg-white border border-slate-500 rounded-md  "
                    >
                      <option className="text-center">select Zip</option>
                      {cityZips.length == 0 ? (
                        <></>
                      ) : (
                        <>
                          {cityZips.map((user, index) => {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={user.zip}
                                  className="  leading-6 text-center"
                                >
                                  {user.zip}
                                </option>
                              </>
                            );
                          })}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="px-2 ml-2">
                <button
                  onClick={() => {
                    navigate(`/searchCity/${selectedZip}`);
                    window.location.reload();
                  }}
                  className="bg-[#990000] flex w-full lg:ml-12 lg:w-[510px] border border-black justify-center items-center  mt-2 h-8"
                >
                  {loader ? (
                    <Vortex
                      visible={true}
                      height="40"
                      width="40"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={[
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                      ]}
                    />
                  ) : (
                    <div className="text-white font-semibold text-1xl">
                      SEARCH
                    </div>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
