import React from "react";
import { useParams } from "react-router-dom";
import { MapUrl } from "../../component/token";
import MapImage from "../../img/staten-island-map.jpg";
import brokliyn from "../../img/brooklyn-map.jpg";
import bronxMap from "../../img/the-bronx-map.jpg";
import queenMap from "../../img/queens-map.jpg";
import manhattan from "../../img/manhattan-map.jpg";
import MapImageMobile from "../../img/staten-island-mobile-map.jpeg";
import brokliynMobile from "../../img/brooklyn-mobile-map.jpeg";
import bronxMapMobile from "../../img/the-bronx-mobile-map.jpeg";
import queenMapMobile from "../../img/queens-mobile-map.jpeg";
import manhattanMobile from "../../img/manhattan-mobile-map.jpeg";
export default function MapData() {
  let params = useParams();
  const cityName = params?.cityName;
  localStorage.setItem("cityName", cityName);
  localStorage.getItem("cityName", cityName);
  console.log(cityName,"this is cityname")
  let cityNameId = 0;
  if (params.cityName === "staten-island") {
    cityNameId = 1;
  } else if (params.cityName === "brooklyn") {
    cityNameId = 2;
  } else if (params.cityName === "queens") {
    cityNameId = 3;
  } else if (params.cityName === "the-bronx") {
    cityNameId = 4;
  } else if (params.cityName === "manhattan") {
    cityNameId = 5;
  }
 
  return (
    <>
    
      {cityName == "staten-island" ? (
        <>
          <div className="maparea forDesktop mt-2">
            <img
              src={MapImage}
              className="map img-responsive"
              usemap="#overlay"
            />
            <map name="overlay">
              <area 
                shape="poly"
                coords="230, 67, 234, 91, 234, 98, 226, 109, 223, 117, 203, 115, 198, 124, 202, 133, 197, 137, 178, 136, 172, 139, 157, 139, 147, 133, 141, 118, 123, 115, 112, 110, 131, 78, 143, 59, 165, 58, 170, 53, 176, 63, 188, 65, 201, 71, 215, 71"
                alt="Link"
                href={`${MapUrl}--zip-staten-island-10303`}
                toggle="0"
                data-maphilight='{"strokeColor":"#110600","strokeWidth":2,"fillColor":"#ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="267, 63, 268, 116, 252, 121, 246, 129, 248, 133, 239, 138, 241, 147, 233, 156, 235, 164, 230, 170, 225, 163, 227, 150, 232, 138, 230, 129, 232, 124, 226, 115, 238, 99, 232, 66, 241, 65, 250, 58"
                href={`${MapUrl}--zip-staten-island-10302`}
                toggle="1"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="308, 119, 298, 113, 282, 113, 273, 116, 268, 118, 268, 64, 289, 49, 304, 39, 309, 41, 310, 61, 313, 61, 320, 112, 316, 118"
                href={`${MapUrl}--zip-staten-island-10310`}
                toggle="2"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="391, 83, 370, 84, 363, 73, 355, 82, 362, 94, 354, 96, 359, 119, 349, 129, 363, 139, 354, 150, 344, 147, 339, 152, 331, 159, 337, 175, 318, 171, 314, 165, 316, 155, 305, 149, 315, 143, 325, 138, 319, 127, 309, 122, 320, 116, 319, 91, 315, 66, 310, 55, 314, 42, 334, 41, 347, 28, 369, 28, 382, 33, 386, 71"
                toggle="3"
                href={`${MapUrl}--zip-staten-island-10301`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="369, 196, 345, 236, 344, 223, 334, 224, 320, 251, 302, 236, 300, 224, 293, 239, 278, 240, 280, 230, 294, 226, 303, 205, 305, 177, 321, 175, 336, 179, 335, 157, 346, 150, 358, 151, 364, 137, 355, 127, 362, 114, 357, 96, 361, 95, 357, 81, 365, 75, 374, 84, 390, 86, 396, 114"
                toggle="5"
                href={`${MapUrl}--zip-staten-island-10304`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="388, 267, 436, 186, 435, 173, 428, 161, 424, 146, 403, 115, 394, 116, 370, 198, 346, 235, 338, 245, 341, 253, 359, 266, 373, 265, 381, 269"
                href={`${MapUrl}--zip-staten-island-10305`}
                toggle="6"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="330, 354, 345, 338, 343, 329, 354, 327, 373, 306, 372, 297, 389, 270, 375, 271, 370, 264, 360, 267, 348, 261, 340, 254, 336, 247, 344, 232, 341, 225, 337, 228, 325, 241, 317, 251, 301, 240, 300, 228, 294, 241, 284, 246, 276, 246, 277, 254, 275, 257, 251, 262, 251, 287, 239, 294, 229, 304, 223, 311, 222, 321, 222, 321, 229, 319, 237, 309, 240, 303, 249, 313, 251, 323, 253, 329, 259, 334, 254, 337, 255, 342, 261, 341, 266, 342, 271, 347, 268, 353, 275, 360, 282, 367, 292, 360, 301, 357, 305, 364, 306, 372, 298, 382, 292, 395, 288, 402, 292, 403, 299, 397, 307, 391, 313, 383, 314, 378, 317, 371, 324, 366, 331, 364"
                toggle="7"
                href={`${MapUrl}--zip-staten-island-10306`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="258, 401, 266, 392, 271, 383, 271, 373, 277, 367, 268, 352, 268, 345, 260, 345, 253, 342, 255, 332, 248, 327, 250, 318, 241, 307, 232, 315, 228, 320, 220, 326, 223, 347, 229, 359, 230, 370, 241, 388, 250, 397"
                href={`${MapUrl}--zip-staten-island-10308`}
                toggle="8"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="256, 403, 244, 394, 230, 370, 229, 358, 220, 347, 220, 323, 194, 317, 186, 305, 175, 304, 167, 305, 158, 316, 151, 320, 143, 328, 136, 329, 140, 345, 142, 351, 142, 360, 136, 365, 140, 389, 146, 401, 148, 408, 149, 423, 162, 421, 166, 435, 169, 439, 175, 445, 181, 444, 183, 452, 183, 455, 190, 452, 199, 452, 207, 439, 215, 440, 223, 433, 232, 428, 235, 422, 243, 423, 249, 413"
                toggle="9"
                href={`${MapUrl}--zip-staten-island-10312`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="180, 458, 180, 447, 173, 444, 161, 431, 159, 424, 148, 422, 145, 405, 136, 386, 134, 361, 141, 356, 140, 347, 137, 332, 128, 327, 124, 328, 116, 325, 116, 335, 97, 344, 85, 339, 77, 347, 62, 354, 61, 363, 50, 365, 47, 379, 45, 391, 54, 400, 52, 440, 62, 443, 68, 449, 71, 458, 76, 468, 79, 480, 80, 495, 83, 505, 80, 508, 100, 508, 114, 496, 119, 484, 137, 479, 145, 471, 150, 480, 157, 485, 166, 476, 172, 465"
                toggle="10"
                href={`${MapUrl}--zip-staten-island-10309`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="77, 508, 77, 485, 73, 471, 39, 486, 24, 484, 19, 493, 21, 506, 26, 514, 31, 519, 24, 523, 32, 527, 43, 527, 55, 523, 62, 523, 68, 514"
                href={`${MapUrl}--zip-staten-island-10307`}
                toggle="11"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area 
                shape="poly"
                coords="137, 326, 149, 321, 162, 311, 171, 301, 189, 303, 196, 315, 210, 320, 218, 320, 220, 309, 243, 285, 249, 284, 249, 259, 273, 256, 277, 242, 275, 229, 283, 224, 291, 223, 301, 206, 302, 185, 304, 173, 315, 174, 310, 165, 313, 156, 302, 148, 306, 139, 319, 139, 324, 134, 298, 117, 280, 117, 256, 123, 248, 132, 249, 139, 239, 139, 243, 147, 236, 157, 237, 169, 226, 170, 220, 164, 225, 143, 228, 132, 228, 122, 210, 120, 203, 123, 203, 134, 196, 141, 178, 137, 165, 144, 147, 139, 140, 127, 137, 116, 121, 117, 112, 112, 109, 122, 114, 133, 117, 144, 122, 155, 129, 166, 125, 180, 132, 191, 126, 213, 118, 233, 116, 249, 115, 254, 117, 259, 135, 261, 138, 270, 132, 277, 131, 284, 119, 289, 113, 293, 112, 303, 112, 313, 116, 322, 123, 326"
                toggle="4"
                href={`${MapUrl}--zip-staten-island-10314`}
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
            </map>
          </div>
          <div class="maparea forMobile">
            <img src={MapImageMobile} />
            <map name="image-map">
              <area
                target=""
                alt="10303"
                title="10303"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10303`}
                coords="89,63,93,57,97,50,101,44,106,41,112,41,118,40,123,44,130,46,137,48,142,50,150,51,158,50,163,47,165,64,167,71,161,79,159,84,147,84,143,91,143,97,127,99,113,101,102,90,98,83,88,83,80,80,82,72"
                shape="poly"
              />
              <area
                target=""
                alt="10302"
                title="10302"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10302`}
                coords="173,44,178,40,182,43,189,44,189,85,182,88,178,93,176,98,170,99,170,104,170,110,167,116,166,122,160,120,158,113,162,105,162,96,163,89,159,84,162,77,167,71,165,60,164,51"
                shape="poly"
              />
              <area
                target=""
                alt="10310"
                title="10310"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10310`}
                coords="219,42,223,44,222,51,224,62,225,73,227,81,220,86,213,83,205,82,198,83,193,85,189,79,189,43,195,42,200,40,205,36,210,32,215,30,220,29,220,35"
                shape="poly"
              />
              <area
                target=""
                alt="10301"
                title="10301"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10301`}
                coords="256,17,266,23,271,26,273,34,273,42,274,47,275,53,277,59,271,61,263,59,259,53,253,56,253,62,257,66,252,70,253,75,255,80,255,85,250,89,250,94,255,98,256,104,250,108,243,109,237,112,236,119,238,125,233,128,227,126,222,122,222,115,217,109,219,103,224,103,229,100,228,94,222,91,217,88,226,84,228,78,226,72,225,65,223,58,222,50,222,43,220,37,220,31,227,29,235,29,241,21,248,17"
                shape="poly"
              />
              <area
                target=""
                alt="10304"
                title="10304"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity&quoquot;:0.6}'
                href={`${MapUrl}--zip-staten-island-10304`}
                coords="260,54,263,59,268,61,276,61,278,66,279,73,280,79,281,84,279,91,276,101,270,120,264,141,246,168,243,162,237,166,232,172,227,179,221,176,215,172,214,165,209,174,199,175,197,168,202,166,213,159,215,149,215,141,216,133,220,128,227,126,233,128,239,126,238,119,239,112,244,109,250,108,256,105,257,98,251,93,255,86,256,79,254,72,258,66,254,61,254,55"
                shape="poly"
              />
              <area
                target=""
                alt="10305"
                title="10305"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10305`}
                coords="302,109,302,117,310,127,307,139,276,196,268,195,262,192,254,194,239,181,262,142,282,84,287,84,299,102"
                shape="poly"
              />
              <area
                target=""
                alt="10306"
                title="10306"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10306`}
                coords="238,181,253,195,263,194,268,197,274,197,265,218,265,223,249,239,244,242,245,248,235,259,234,265,228,267,224,271,223,277,209,295,202,293,204,287,211,279,215,268,212,261,205,263,200,268,194,262,190,257,189,248,181,249,181,240,177,235,170,221,161,232,154,233,156,226,170,210,176,208,177,192,192,187,195,179,199,176,208,174,212,166,214,172,223,180,238,163,243,166,243,171,240,175"
                shape="poly"
              />
              <area
                target=""
                alt="10308"
                title="10308"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10308`}
                coords="169,222,177,236,180,240,178,247,188,249,189,256,193,261,197,268,192,272,191,278,183,293,172,284,163,270,163,261,158,256,155,234,161,233,165,227"
                shape="poly"
              />
              <area
                target=""
                alt="10307"
                title="10307"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10307`}
                coords="50,342,54,354,54,361,55,369,43,381,32,383,18,384,18,376,14,370,12,361,16,351,29,351"
                shape="poly"
              />
              <area
                target=""
                alt="10309"
                title="10309"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10309`}
                coords="128,335,120,342,119,346,115,350,110,354,104,351,103,345,99,347,94,350,89,353,86,353,83,359,79,366,73,371,63,372,56,371,55,359,54,351,51,342,48,330,40,321,36,324,35,314,36,293,31,273,37,265,42,263,46,257,51,253,56,247,61,249,66,251,72,249,80,244,81,236,86,238,89,239,93,238,97,241,97,251,100,255,101,261,95,264,97,276,99,288,104,295,103,307,112,308,123,323,128,324"
                shape="poly"
              />
              <area
                target=""
                alt="10312"
                title="10312"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10312`}
                coords="182,293,176,298,175,304,172,308,168,309,164,313,157,315,153,320,147,320,143,327,141,330,134,330,130,334,129,323,123,321,115,310,112,307,104,305,104,295,99,285,97,276,96,266,97,263,101,260,100,253,98,250,97,241,104,235,108,230,116,227,120,221,125,220,131,220,136,228,141,230,141,231,147,233,152,233,155,234,155,241,156,250,157,256,163,260,162,268,171,283,177,288"
                shape="poly"
              />
              <area
                target=""
                alt="10314"
                title="10314"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10314`}
                coords="83,82,90,82,96,83,98,88,104,99,111,102,119,102,125,99,131,100,139,99,143,93,144,85,152,85,162,90,164,99,161,104,160,111,160,121,167,121,167,112,170,100,176,99,177,92,186,87,193,86,198,83,210,82,231,97,226,102,218,105,218,110,222,115,226,122,221,129,215,132,215,145,213,152,211,161,206,164,197,166,197,173,200,175,195,182,195,188,180,189,176,193,176,201,176,208,167,215,158,221,157,235,146,233,139,230,133,221,123,221,117,225,109,230,103,236,96,240,87,237,81,235,76,223,76,211,91,209,93,200,95,194,89,191,82,189,79,181,86,165,91,150,90,138,88,133,89,120,84,113,79,101,77,86"
                shape="poly"
              />
              <area
                target=""
                alt="10311"
                title="10311"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10311`}
                coords="141,99,143,91,147,84,158,86,162,90,162,97,161,102,158,110,157,118,163,123,167,116,170,109,170,100,176,98,182,88,188,87,194,86,199,83,211,83,227,94,226,100,221,103,218,103,216,108,222,114,220,119,89,149,90,138,86,131,89,124,87,115,83,109,79,97,77,86,79,80,84,84,97,83,100,93,107,100,113,101,121,101,127,99,134,98"
                shape="poly"
              />
              <area
                target=""
                alt="10313"
                title="10313"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10313`}
                coords="132,220,136,226,145,232,154,232,156,224,172,209,176,208,176,198,177,190,182,188,192,187,195,179,196,172,83,190,91,190,95,193,93,199,93,205,78,211,77,226,80,234,83,236,87,238,92,238,97,239,104,234,107,229,115,227,120,220,126,219"
                shape="poly"
              />
              <area
                target=""
                alt="10314"
                title="10314"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-staten-island-10314`}
                coords="89,150,88,159,82,169,80,179,81,186,82,190,195,171,197,165,207,164,214,148,215,132,220,127,225,124,219,119,96,148,89,150"
                shape="poly"
              />
            </map>
          </div>
        </>
      ) : null}
      {cityName == "brooklyn" ? (
        <>
          <div className="maparea forDesktop mt-2">
            <img
              src={brokliyn}
              usemap="#overlay"
              className="map img-responsive"
            />
            <map name="overlay">
              <area
                shape="poly"
                coords="198, 76, 212, 80, 210, 83, 211, 88, 215, 88, 221, 86, 223, 91, 230, 93, 256, 93, 257, 79, 264, 78, 271, 78, 276, 76, 271, 68, 258, 65, 247, 58, 243, 47, 230, 39, 214, 33, 205, 33, 197, 39, 193, 44, 197, 55, 197, 63"
                href={`${MapUrl}--zip-staten-island-11222`}
                onmouseover="mapOnMouseOver(0)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="275, 76, 268, 81, 262, 79, 258, 86, 258, 90, 255, 96, 223, 94, 221, 87, 216, 88, 207, 85, 209, 80, 197, 74, 182, 100, 177, 119, 178, 129, 189, 145, 193, 152, 201, 155, 208, 150, 215, 139, 223, 131, 224, 118, 239, 116, 256, 113, 284, 109, 282, 89"
                href={`${MapUrl}--zip-brooklyn-11201`}
                onmouseover="mapOnMouseOver(1)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="275, 145, 265, 138, 259, 115, 251, 113, 239, 118, 230, 118, 224, 119, 224, 131, 220, 139, 209, 147, 208, 152, 215, 154, 218, 180, 240, 174, 261, 170, 262, 160"
                href={`${MapUrl}--zip-brooklyn-11206`}
                onmouseover="mapOnMouseOver(2)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="284, 108, 258, 113, 269, 140, 294, 161, 302, 157, 307, 158, 319, 170, 330, 176, 333, 174, 329, 167, 325, 158, 318, 155, 315, 145, 306, 135, 294, 126, 290, 119"
                href={`${MapUrl}--zip-brooklyn-11237`}
                onmouseover="mapOnMouseOver(3)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="317, 170, 302, 193, 249, 199, 241, 201, 236, 201, 236, 193, 232, 175, 263, 171, 262, 161, 278, 147, 294, 162, 299, 161, 303, 158"
                href={`${MapUrl}--zip-brooklyn-11221`}
                onmouseover="mapOnMouseOver(7)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="191, 154, 188, 145, 177, 126, 168, 124, 165, 128, 170, 140, 167, 142, 156, 134, 151, 144, 153, 151, 157, 156"
                href={`${MapUrl}--zip-brooklyn-11251`}
                onmouseover="mapOnMouseOver(4)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="159, 157, 202, 157, 209, 154, 214, 155, 214, 169, 219, 179, 172, 186, 166, 183, 168, 178, 164, 170, 156, 169"
                href={`${MapUrl}--zip-brooklyn-11205`}
                onmouseover="mapOnMouseOver(5)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="166, 179, 169, 172, 160, 170, 156, 165, 156, 154, 150, 147, 149, 141, 155, 134, 140, 131, 130, 137, 124, 132, 102, 157, 102, 162, 95, 178, 100, 186, 107, 187, 113, 189, 128, 196, 140, 182, 155, 185, 156, 180"
                href={`${MapUrl}--zip-brooklyn-11201`}
                onmouseover="mapOnMouseOver(6)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="390, 279, 388, 274, 394, 273, 387, 261, 380, 245, 377, 229, 374, 215, 375, 208, 373, 201, 367, 198, 361, 196, 357, 198, 351, 196, 348, 201, 339, 193, 339, 185, 337, 176, 332, 176, 321, 172, 307, 191, 306, 193, 332, 214, 335, 243, 339, 266, 346, 281, 348, 288, 357, 293, 363, 300, 364, 297, 369, 300, 374, 296, 372, 288, 382, 281"
                href={`${MapUrl}--zip-brooklyn-11207`}
                onmouseover="mapOnMouseOver(15)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="388, 282, 389, 274, 395, 275, 389, 262, 380, 238, 375, 219, 376, 208, 373, 201, 366, 197, 398, 171, 408, 163, 416, 170, 417, 181, 418, 198, 428, 208, 433, 221, 433, 229, 439, 235, 442, 250, 442, 260, 439, 268, 441, 273, 433, 273, 428, 279, 429, 282, 417, 274, 406, 280, 400, 286"
                href={`${MapUrl}--zip-brooklyn-11208`}
                onmouseover="mapOnMouseOver(16)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="305, 194, 331, 213, 330, 224, 280, 252, 280, 227, 273, 224, 268, 224, 267, 212, 257, 212, 250, 210, 251, 201"
                href={`${MapUrl}--zip-brooklyn-11233`}
                onmouseover="mapOnMouseOver(13)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="249, 206, 249, 199, 239, 201, 233, 195, 232, 182, 231, 176, 219, 176, 219, 181, 211, 182, 209, 184, 209, 198, 211, 214, 208, 227, 205, 236, 227, 239, 227, 223, 230, 220, 234, 220, 241, 221, 241, 212, 243, 206"
                href={`${MapUrl}--zip-brooklyn-11216`}
                onmouseover="mapOnMouseOver(11)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="195, 257, 196, 246, 195, 238, 206, 237, 211, 217, 207, 183, 168, 187, 168, 191, 172, 197, 170, 201, 175, 204, 173, 211, 173, 215, 170, 218, 173, 224, 172, 227, 181, 233"
                href={`${MapUrl}--zip-brooklyn-11238`}
                onmouseover="mapOnMouseOver(10)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="170, 228, 172, 223, 169, 218, 172, 215, 172, 207, 171, 202, 170, 196, 166, 188, 166, 183, 155, 181, 153, 186, 142, 184, 136, 189, 130, 202, 135, 209"
                href={`${MapUrl}--zip-brooklyn-11217`}
                onmouseover="mapOnMouseOver(9)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="94, 178, 104, 190, 111, 188, 127, 198, 128, 204, 133, 208, 127, 223, 118, 224, 108, 238, 98, 249, 86, 247, 83, 252, 79, 257, 69, 257, 64, 248, 62, 238, 67, 240, 69, 249, 74, 253, 80, 245, 81, 240, 71, 232, 63, 220, 62, 214, 71, 204, 84, 196, 92, 191"
                href={`${MapUrl}--zip-brooklyn-11231`}
                onmouseover="mapOnMouseOver(8)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="200, 280, 194, 274, 192, 267, 195, 260, 180, 234, 166, 228, 134, 212, 129, 222, 124, 227, 117, 224, 111, 236, 118, 248, 116, 254, 123, 260, 124, 263, 121, 266, 123, 271, 129, 274, 133, 271, 147, 281, 155, 277, 159, 278, 166, 272, 170, 285, 170, 293, 173, 297"
                href={`${MapUrl}--zip-brooklyn-11215`}
                onmouseover="mapOnMouseOver(17)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="199, 279, 216, 281, 228, 280, 227, 265, 239, 265, 240, 254, 235, 252, 231, 252, 225, 245, 220, 241, 196, 235, 198, 251, 196, 260, 194, 265"
                href={`${MapUrl}--zip-brooklyn-11225`}
                onmouseover="mapOnMouseOver(19)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="238, 251, 241, 257, 265, 260, 280, 250, 279, 228, 269, 223, 266, 213, 251, 213, 249, 206, 242, 209, 241, 219, 236, 220, 225, 223, 226, 239, 226, 252"
                href={`${MapUrl}--zip-brooklyn-11213`}
                onmouseover="mapOnMouseOver(12)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="292, 285, 315, 286, 325, 283, 332, 283, 343, 275, 335, 252, 333, 223, 265, 261, 279, 272, 287, 284"
                href={`${MapUrl}--zip-brooklyn-11212`}
                onmouseover="mapOnMouseOver(14)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="130, 315, 152, 308, 153, 301, 149, 283, 135, 273, 131, 275, 120, 270, 124, 264, 117, 256, 115, 247, 110, 237, 99, 247, 94, 254, 89, 255, 81, 261, 72, 265, 72, 267, 63, 273, 52, 283, 48, 287, 50, 292, 48, 296, 65, 305, 76, 292, 81, 294, 108, 318, 120, 307"
                href={`${MapUrl}--zip-brooklyn-11232`}
                onmouseover="mapOnMouseOver(18)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="149, 283, 155, 280, 161, 282, 165, 274, 169, 293, 173, 298, 174, 303, 181, 306, 182, 328, 185, 337, 158, 357, 156, 346, 153, 342, 146, 340, 130, 325, 130, 319, 144, 312, 153, 306"
                href={`${MapUrl}--zip-brooklyn-11218`}
                onmouseover="mapOnMouseOver(26)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="190, 349, 187, 339, 182, 307, 178, 303, 175, 297, 180, 292, 200, 281, 227, 282, 231, 331, 217, 331, 209, 333"
                href={`${MapUrl}--zip-brooklyn-11226`}
                onmouseover="mapOnMouseOver(20)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="232, 329, 249, 333, 249, 340, 256, 339, 257, 345, 274, 337, 290, 320, 290, 294, 286, 284, 277, 271, 269, 262, 263, 259, 239, 257, 238, 262, 235, 267, 229, 267, 230, 292"
                href={`${MapUrl}--zip-brooklyn-11203`}
                onmouseover="mapOnMouseOver(21)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="287, 284, 310, 287, 322, 283, 332, 286, 344, 276, 347, 285, 347, 289, 354, 292, 362, 298, 363, 305, 367, 308, 367, 313, 377, 323, 383, 331, 391, 337, 395, 344, 381, 359, 381, 367, 373, 366, 363, 377, 352, 385, 344, 382, 338, 384, 326, 372, 294, 356, 293, 335, 291, 316, 291, 297"
                href={`${MapUrl}--zip-brooklyn-11236`}
                onmouseover="mapOnMouseOver(22)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="396, 343, 408, 344, 413, 334, 406, 321, 402, 312, 405, 312, 411, 320, 419, 320, 424, 318, 432, 320, 439, 317, 440, 309, 441, 303, 438, 296, 431, 284, 424, 282, 416, 275, 406, 282, 396, 287, 386, 283, 377, 285, 374, 292, 378, 298, 371, 300, 363, 300, 367, 310, 373, 320, 381, 327, 390, 334"
                href={`${MapUrl}--zip-brooklyn-11239`}
                onmouseover="mapOnMouseOver(23)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="291, 320, 294, 337, 293, 354, 297, 360, 315, 367, 327, 373, 335, 384, 340, 385, 345, 383, 349, 383, 353, 393, 351, 399, 350, 405, 356, 410, 364, 413, 365, 419, 361, 425, 356, 430, 348, 435, 346, 436, 354, 437, 368, 436, 371, 434, 379, 433, 384, 452, 389, 473, 397, 490, 396, 499, 393, 506, 385, 514, 372, 516, 361, 519, 350, 516, 346, 508, 351, 506, 344, 491, 336, 491, 322, 494, 314, 494, 306, 492, 299, 492, 268, 459, 271, 455, 264, 445, 263, 437, 270, 433, 256, 424, 253, 424, 233, 402, 242, 391, 249, 382, 259, 375, 258, 346, 278, 335"
                href={`${MapUrl}--zip-brooklyn-11234`}
                onmouseover="mapOnMouseOver(32)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="214, 413, 226, 408, 232, 403, 243, 387, 253, 380, 259, 369, 257, 352, 257, 347, 256, 340, 252, 339, 248, 334, 242, 333, 230, 333, 224, 332, 218, 332, 209, 337, 204, 343"
                href={`${MapUrl}--zip-brooklyn-11210`}
                onmouseover="mapOnMouseOver(31)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="201, 344, 213, 413, 191, 417, 171, 421, 166, 416, 166, 408, 163, 403, 165, 398, 161, 376, 161, 370, 152, 361, 165, 353, 181, 344, 188, 340, 190, 349"
                href={`${MapUrl}--zip-brooklyn-11230`}
                onmouseover="mapOnMouseOver(30)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="167, 419, 162, 416, 164, 408, 161, 401, 165, 399, 160, 376, 160, 370, 151, 361, 158, 355, 157, 345, 151, 342, 106, 397, 136, 426, 156, 423"
                href={`${MapUrl}--zip-brooklyn-11204`}
                onmouseover="mapOnMouseOver(29)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="110, 388, 148, 343, 126, 325, 129, 319, 120, 307, 75, 361"
                href={`${MapUrl}--zip-brooklyn-11219`}
                onmouseover="mapOnMouseOver(25)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="74, 360, 109, 319, 79, 294, 75, 291, 66, 303, 62, 304, 48, 295, 42, 300, 34, 307, 34, 313, 25, 318, 19, 323, 21, 331"
                href={`${MapUrl}--zip-brooklyn-11220`}
                onmouseover="mapOnMouseOver(24)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="63, 440, 57, 437, 54, 431, 53, 426, 42, 420, 42, 411, 46, 397, 55, 385, 61, 376, 64, 368, 66, 357, 19, 331, 9, 372, 9, 392, 16, 410, 29, 428, 39, 440, 52, 441, 60, 444"
                href="/https://reactjs.orderingspace.net/cuisine/--zip-brooklyn-11209"
                onmouseover="mapOnMouseOver(27)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="68, 443, 111, 389, 65, 355, 64, 369, 60, 381, 50, 392, 44, 400, 45, 409, 41, 416, 41, 419, 50, 422, 55, 423, 52, 428, 58, 434, 57, 436"
                href={`${MapUrl}--zip-brooklyn-11228`}
                onmouseover="mapOnMouseOver(28)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="105, 399, 136, 425, 144, 496, 132, 508, 124, 506, 127, 501, 120, 501, 112, 503, 106, 498, 110, 493, 107, 487, 112, 485, 109, 476, 106, 466, 89, 455, 78, 445, 69, 445"
                href={`${MapUrl}--zip-brooklyn-11214`}
                onmouseover="mapOnMouseOver(33)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="138, 428, 192, 419, 199, 470, 183, 471, 184, 493, 167, 497, 145, 495"
                href={`${MapUrl}--zip-brooklyn-11223`}
                onmouseover="mapOnMouseOver(34)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="296, 490, 289, 494, 283, 497, 277, 491, 275, 482, 269, 479, 269, 469, 256, 467, 244, 469, 222, 472, 207, 474, 198, 470, 193, 418, 212, 415, 234, 403, 243, 418, 253, 426, 257, 423, 267, 432, 262, 438, 259, 441, 270, 455, 268, 459"
                href={`${MapUrl}--zip-brooklyn-11229`}
                onmouseover="mapOnMouseOver(35)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="182, 493, 170, 498, 144, 496, 132, 510, 125, 514, 117, 510, 112, 506, 94, 503, 93, 507, 80, 508, 75, 514, 75, 524, 83, 530, 88, 532, 96, 531, 99, 535, 107, 540, 124, 539, 141, 540, 164, 539, 180, 535, 191, 533, 200, 531, 204, 530, 202, 524, 189, 526, 181, 523, 181, 515, 185, 502"
                href={`${MapUrl}--zip-brooklyn-11224`}
                onmouseover="mapOnMouseOver(36)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
              <area
                shape="poly"
                coords="315, 507, 314, 496, 306, 493, 296, 492, 290, 494, 284, 496, 275, 491, 276, 483, 269, 479, 266, 467, 247, 468, 235, 471, 219, 473, 203, 474, 195, 471, 185, 470, 184, 493, 184, 503, 181, 511, 180, 522, 186, 526, 202, 525, 205, 529, 223, 529, 239, 527, 265, 527, 271, 520, 266, 509, 256, 503, 246, 506, 230, 502, 241, 500, 251, 501, 266, 501, 280, 502, 299, 506, 307, 508"
                href={`${MapUrl}--zip-brooklyn-11235`}
                onmouseover="mapOnMouseOver(37)"
                onmouseout="mapOnMouseOut()"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
              />
            </map>
          </div>
          <div class="maparea forMobile">
            <img src={brokliynMobile} />
            <map name="image-map">
              <area
                target=""
                alt="11223"
                title="11223"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11223`}
                coords="99,310,138,303,142,342,131,345,131,359,118,361,103,361"
                shape="poly"
              />
              <area
                target=""
                alt="11210"
                title="11210"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11210`}
                coords="145,248,155,302,167,293,174,282,183,276,185,262,182,251,176,244,165,243,153,243,145,250"
                shape="poly"
              />
              <area
                target=""
                alt="11219"
                title="11219"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11219`}
                coords="53,264,79,283,107,250,92,235,96,230,88,224,74,242,63,253"
                shape="poly"
              />
              <area
                target=""
                alt="11214"
                title="11214"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11214`}
                coords="76,289,98,310,102,361,94,370,90,369,93,363,82,367,76,364,79,360,76,359,80,352,77,343,71,336,60,327,50,323,62,307,69,297"
                shape="poly"
              />
              <area
                target=""
                alt="11234"
                title="11234"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11234`}
                coords="184,255,209,234,210,259,216,264,228,269,243,281,246,280,251,281,250,290,250,296,259,301,261,308,255,314,245,319,250,320,261,319,271,316,272,324,277,344,280,354,283,361,281,370,272,375,262,377,254,379,249,368,243,357,224,362,211,358,191,335,194,331,187,323,191,315,182,308,180,310,166,294,176,282,184,275,185,265"
                shape="poly"
              />
              <area
                target=""
                alt="11235"
                title="11235"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11235`}
                coords="131,349,139,342,148,346,160,344,175,340,191,340,191,349,197,354,200,361,208,359,216,359,224,361,224,369,211,368,191,364,185,366,191,376,193,381,185,383,171,384,146,385,138,381,130,381"
                shape="poly"
              />
              <area
                target=""
                alt="11229"
                title="11229"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11229`}
                coords="138,304,156,302,167,293,179,308,182,308,190,314,186,322,191,330,193,337,212,358,200,359,194,348,190,338,174,340,156,344,142,344"
                shape="poly"
              />
              <area
                target=""
                alt="11236"
                title="11236"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11236`}
                coords="205,207,211,261,226,266,241,279,247,279,251,281,258,276,263,268,269,267,272,267,272,260,283,250,273,238,261,227,259,216,249,211,244,202,237,206,223,207"
                shape="poly"
              />
              <area
                target=""
                alt="11230"
                title="11230"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11230`}
                coords="144,248,135,256,133,246,115,259,108,264,114,268,115,275,118,285,116,293,118,301,122,307,138,303,153,301"
                shape="poly"
              />
              <area
                target=""
                alt="11203"
                title="11203"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11203`}
                coords="163,192,165,243,174,242,179,247,183,247,183,255,208,233,204,206,190,190,174,187,171,195"
                shape="poly"
              />
              <area
                target=""
                alt="11224"
                title="11224"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11224`}
                coords="130,359,116,362,102,362,93,370,83,369,73,365,66,367,57,370,54,376,56,381,61,385,70,389,77,392,94,393,110,392,125,390,147,387,145,381,129,382,130,369"
                shape="poly"
              />
              <area
                target=""
                alt="11226"
                title="11226"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11226`}
                coords="123,217,141,207,163,205,164,217,164,242,153,242,145,250,135,254,131,239,131,222"
                shape="poly"
              />
              <area
                target=""
                alt="11204"
                title="11204"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11204`}
                coords="75,288,107,249,112,253,114,259,107,263,112,267,115,279,116,286,116,294,118,301,120,305,97,311"
                shape="poly"
              />
              <area
                target=""
                alt="11218"
                title="11218"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11218`}
                coords="91,234,104,227,110,222,107,205,110,201,116,202,119,200,120,210,124,219,130,225,131,236,133,247,115,258,111,249,104,249,97,240"
                shape="poly"
              />
              <area
                target=""
                alt="11212"
                title="11212"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11212`}
                coords="189,189,237,163,237,175,239,185,244,200,238,206,226,206,214,207,204,206,197,197"
                shape="poly"
              />
              <area
                target=""
                alt="11228"
                title="11228"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11228`}
                coords="49,261,79,284,70,295,49,322,42,319,37,314,41,310,30,306,33,293,40,281,46,270"
                shape="poly"
              />
              <area
                target=""
                alt="11209"
                title="11209"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11209`}
                coords="15,241,49,260,45,269,40,280,34,288,31,298,30,305,40,309,37,314,44,320,31,320,21,312,12,301,7,288,7,275,10,258"
                shape="poly"
              />
              <area
                target=""
                alt="11207"
                title="11207"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11207`}
                coords="217,138,228,123,235,129,241,126,242,135,245,144,250,145,258,142,267,145,267,155,271,174,276,187,282,197,278,200,276,204,267,209,268,216,260,217,256,212,248,209,248,201,242,190,238,175,238,161,237,155,226,146"
                shape="poly"
              />
              <area
                target=""
                alt="11208"
                title="11208"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11208`}
                coords="260,144,282,125,289,120,297,124,298,137,298,144,304,151,308,159,309,169,315,177,315,189,315,199,305,201,306,207,297,199,287,206,278,207,282,199,271,174,268,148"
                shape="poly"
              />
              <area
                target=""
                alt="11220"
                title="11220"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11220`}
                coords="54,212,79,233,68,245,58,258,53,262,41,255,26,247,15,240,16,233,25,230,27,223,32,217,36,215,43,221,49,220"
                shape="poly"
              />
              <area
                target=""
                alt="11232"
                title="11232"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11232`}
                coords="36,214,41,203,48,198,53,194,60,188,66,184,71,181,79,171,83,179,83,186,89,190,85,196,92,201,98,198,105,206,108,216,108,224,94,229,88,222,79,231,66,220,53,210,49,217,45,221"
                shape="poly"
              />
              <area
                target=""
                alt="11205"
                title="11205"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11205`}
                coords="114,113,152,111,155,131,119,135,119,124,114,122"
                shape="poly"
              />
              <area
                target=""
                alt="11221"
                title="11221"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11221`}
                coords="165,127,186,124,189,116,197,106,205,113,211,117,217,115,228,124,216,141,179,146,170,146"
                shape="poly"
              />
              <area
                target=""
                alt="11215"
                title="11215"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11215`}
                coords="96,152,118,165,129,169,139,188,138,196,144,205,123,216,119,197,115,202,109,200,107,207,98,197,90,200,86,196,90,189,83,185,81,171,83,163,93,161"
                shape="poly"
              />
              <area
                target=""
                alt="11225"
                title="11225"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11225`}
                coords="139,170,163,176,161,183,171,184,170,194,161,192,163,203,149,205,141,202,139,192,142,181"
                shape="poly"
              />
              <area
                target=""
                alt="11233"
                title="11233"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11233`}
                coords="178,146,217,141,226,147,235,156,237,162,223,169,200,182,201,167,193,162,191,154,179,154"
                shape="poly"
              />
              <area
                target=""
                alt="11213"
                title="11213"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11213`}
                coords="163,162,172,160,171,154,178,151,181,154,190,155,191,163,200,166,198,181,189,189,172,185,161,182"
                shape="poly"
              />
              <area
                target=""
                alt="11216"
                title="11216"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11216`}
                coords="148,132,156,132,156,128,165,127,168,146,168,146,176,146,178,153,170,153,172,160,161,162,161,176,146,171,150,158,149,145"
                shape="poly"
              />
              <area
                target=""
                alt="11239"
                title="11239"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11239`}
                coords="282,251,290,251,295,244,287,228,287,226,295,233,302,232,313,232,315,218,306,207,297,199,283,207,274,206,268,211,260,219,263,226,274,239"
                shape="poly"
              />
              <area
                target=""
                alt="11206"
                title="11206"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11206`}
                coords="148,111,153,103,159,97,160,86,170,85,178,85,185,84,187,93,190,101,197,107,189,116,186,124,156,129,153,116"
                shape="poly"
              />
              <area
                target=""
                alt="11237"
                title="11237"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11237`}
                coords="185,81,203,79,208,88,211,93,219,99,225,107,228,113,236,120,237,126,235,129,228,124,216,114,210,116,198,107,189,101,187,91"
                shape="poly"
              />
              <area
                target=""
                alt="11211"
                title="11211"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11211`}
                coords="141,55,150,58,150,63,154,65,158,64,160,68,184,69,184,62,188,58,194,57,198,55,203,67,203,77,192,80,174,83,160,86,159,96,153,103,148,111,137,111,135,103,127,93,127,82,134,67"
                shape="poly"
              />
              <area
                target=""
                alt="11222"
                title="11222"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11222`}
                coords="138,34,146,25,155,24,164,28,174,34,176,42,183,47,191,49,197,54,187,58,183,64,182,69,170,68,160,68,157,65,152,65,149,57,141,54,141,43"
                shape="poly"
              />
              <area
                target=""
                alt="11201"
                title="11201"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11201`}
                coords="75,113,83,105,89,98,96,101,103,97,109,99,107,106,112,114,112,124,119,127,119,133,109,132,109,136,100,135,93,144,81,138,75,138,70,131"
                shape="poly"
              />
              <area
                target=""
                alt="11231"
                title="11231"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11231`}
                coords="47,174,47,187,52,189,58,188,63,181,72,181,80,172,84,163,94,162,97,151,93,147,84,140,76,139,69,133,64,141,54,147,46,154,47,165,56,172,60,178,54,184"
                shape="poly"
              />
              <area
                target=""
                alt="11238"
                title="11238"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11238`}
                coords="120,135,148,134,150,159,146,172,139,170,141,188,130,170,123,166,123,158,124,149"
                shape="poly"
              />
              <area
                target=""
                alt="11217"
                title="11217"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11217`}
                coords="92,146,100,132,108,136,112,131,120,132,120,140,123,149,123,158,123,168,103,155"
                shape="poly"
              />
              <area
                target=""
                alt="11251"
                title="11251"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-brooklyn-11251`}
                coords="111,97,107,105,112,114,138,112,134,103,127,93,120,90,122,105,111,99"
                shape="poly"
              />
            </map>
          </div>
        </>
      ) : null}
      {cityName == "queens" ? (
        <>
          <div className="maparea forDesktop mt-2">
            <img
              src={queenMap}
              usemap="#overlay"
              className="map img-responsive"
            />
            <map name="overlay">
              <area
                target=""
                alt="11697"
                title="11697"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11697`}
                coords="51,530,55,510,71,499,85,493,94,488,106,492,125,485,130,481,138,482,145,480,149,493"
                shape="poly"
              />
              <area
                target=""
                alt="11694"
                title="11694"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11694`}
                coords="148,481,152,494,154,480,162,476,169,472,181,463,194,456,202,451,209,454,218,451,233,446,242,441,248,444,250,451,177,485,241,457,159,490"
                shape="poly"
              />
              <area
                target=""
                alt="11692"
                title="11692"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11692`}
                coords="267,428,271,433,274,443,280,445,293,443,299,442,304,432,305,422,295,418,302,409,292,412,275,412"
                shape="poly"
              />
              <area
                target=""
                alt="11693"
                title="11693"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11693`}
                coords="226,420,236,417,243,406,250,392,239,388,224,386,223,375,228,361,229,351,227,337,234,341,241,335,244,348,250,359,250,367,253,373,254,384,254,403,250,409,246,422,225,426,235,426"
                shape="poly"
              />
              <area
                target=""
                alt="11691"
                title="11691"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11691`}
                coords="306,413,306,406,314,398,321,399,318,409,313,417,321,414,327,422,330,410,330,399,325,394,328,385,336,389,347,393,360,392,367,392,370,399,377,404,381,425,362,429,357,432,342,435,324,438,304,440,301,418"
                shape="poly"
              />
              <area
                target=""
                alt="11096"
                title="11096"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11096`}
                coords="344,390,342,383,331,383,333,376,341,369,351,366,365,356,370,362,369,369,380,368,385,383,368,390"
                shape="poly"
              />
              <area
                target=""
                alt="11414"
                title="11414"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11414`}
                coords="175,295,185,293,186,283,186,267,181,260,200,269,225,275,237,315,228,305,227,314,217,293,220,315,201,324,194,318,197,310,190,308,183,302"
                shape="poly"
              />
              <area
                target=""
                alt="11420"
                title="11420"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11420`}
                coords="250,286,228,281,221,262,227,260,228,250,252,241,266,234,273,245,278,263,276,276,265,283,258,286"
                shape="poly"
              />
              <area
                target=""
                alt="11436"
                title="11436"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11436`}
                coords="278,266,277,276,295,279,300,266,296,256,291,239,275,241"
                shape="poly"
              />
              <area
                target=""
                alt="11434"
                title="11434"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11434`}
                coords="292,237,312,227,321,223,329,216,344,239,351,255,340,261,340,275,333,277,339,283,334,292,335,299,299,280,298,272"
                shape="poly"
              />
              <area
                target=""
                alt="11413"
                title="11413"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11413`}
                coords="336,300,350,311,372,327,370,316,369,308,361,302,369,293,374,282,381,278,381,268,387,256,393,240,370,231,361,234,356,237,347,238,351,256,342,264,336,277,340,286,334,292"
                shape="poly"
              />
              <area
                target=""
                alt="11422"
                title="11422"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11422`}
                coords="395,242,402,245,403,255,399,265,399,277,399,285,405,308,389,312,378,318,376,331,378,343,375,338,368,339,372,322,369,307,364,301,374,283,382,279,382,270,387,257"
                shape="poly"
              />
              <area
                target=""
                alt="11411"
                title="11411"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11411`}
                coords="369,228,367,213,366,204,379,206,394,205,397,197,402,208,403,196,402,227,403,244,386,235,377,232"
                shape="poly"
              />
              <area
                target=""
                alt="11417"
                title="11417"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11417`}
                coords="181,259,179,252,189,252,201,246,218,244,225,239,229,247,228,258,221,260,226,274,206,269,191,264"
                shape="poly"
              />
              <area
                target=""
                alt="11416"
                title="11416"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11416`}
                coords="179,252,172,244,190,237,206,231,217,226,225,237,216,245,198,245,189,251"
                shape="poly"
              />
              <area
                target=""
                alt="11421"
                title="11421"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11421`}
                coords="171,243,170,232,168,221,191,213,190,204,196,205,198,211,208,228,193,236"
                shape="poly"
              />
              <area
                target=""
                alt="11418"
                title="11418"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11418`}
                coords="209,227,201,212,214,205,221,203,218,196,225,191,228,206,243,203,246,198,255,206,251,201,259,217,218,226"
                shape="poly"
              />
              <area
                target=""
                alt="11419"
                title="11419"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11419`}
                coords="219,228,239,222,258,219,267,234,248,241,231,248"
                shape="poly"
              />
              <area
                target=""
                alt="11415"
                title="11415"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11415`}
                coords="220,193,211,190,224,183,239,176,245,186,249,195,244,198,238,203,229,205"
                shape="poly"
              />
              <area
                target=""
                alt="11435"
                title="11435"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11435`}
                coords="241,177,250,175,262,169,265,177,262,182,275,203,277,219,292,237,273,242,267,233,260,221,255,205,249,198"
                shape="poly"
              />
              <area
                target=""
                alt="11385"
                title="11385"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11385`}
                coords="166,218,149,226,138,235,126,242,116,236,114,226,109,222,98,208,98,201,84,190,80,180,107,184,116,179,147,196,162,195,179,182,186,185,188,194,199,193,210,191,218,193,216,204,200,209,179,216,172,217"
                shape="poly"
              />
              <area
                target=""
                alt="11433"
                title="11433"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11433`}
                coords="293,236,278,221,276,203,289,198,303,193,312,190,320,196,314,198,321,204,327,213,324,220"
                shape="poly"
              />
              <area
                target=""
                alt="11412"
                title="11412"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11412`}
                coords="345,237,331,217,317,199,331,195,344,194,358,187,365,204,368,217,367,229,361,233,353,235"
                shape="poly"
              />
              <area
                target=""
                alt="11432"
                title="11432"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11432`}
                coords="273,199,262,181,268,175,263,168,273,164,294,155,311,148,322,176,312,178,313,186,295,193,284,198"
                shape="poly"
              />
              <area
                target=""
                alt="11423"
                title="11423"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11423`}
                coords="322,197,314,189,324,177,313,150,328,144,335,154,347,166,354,178,357,187,346,193"
                shape="poly"
              />
              <area
                target=""
                alt="11429"
                title="11429"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11429`}
                coords="366,202,359,187,355,181,370,173,386,171,395,166,398,178,402,193,401,204,397,196,393,203,377,205"
                shape="poly"
              />
              <area
                target=""
                alt="11428"
                title="11428"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11428`}
                coords="355,180,350,172,361,166,369,159,381,149,387,152,392,147,395,160,388,169,394,166,382,170"
                shape="poly"
              />
              <area
                target=""
                alt="11378"
                title="11378"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11378`}
                coords="77,177,74,165,67,149,73,150,76,136,88,143,103,147,116,139,134,138,141,148,138,164,118,176,106,183,86,179"
                shape="poly"
              />
              <area
                target=""
                alt="11379"
                title="11379"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11379`}
                coords="117,178,136,167,141,152,152,149,158,147,164,154,170,164,180,175,180,181,163,193,147,194,132,185"
                shape="poly"
              />
              <area
                target=""
                alt="11374"
                title="11374"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11374`}
                coords="161,149,168,142,176,138,187,134,193,148,187,164,186,173,186,183,181,180,173,164"
                shape="poly"
              />
              <area
                target=""
                alt="11375"
                title="11375"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11375`}
                coords="188,189,187,172,191,154,194,147,188,134,195,133,203,127,208,138,214,147,219,160,231,169,238,175,222,183,213,189,200,191"
                shape="poly"
              />
              <area
                target=""
                alt="11367"
                title="11367"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11367`}
                coords="204,127,217,119,232,120,251,125,261,127,262,146,265,167,244,175,229,167,220,159"
                shape="poly"
              />
              <area
                target=""
                alt="11366"
                title="11366"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11366`}
                coords="265,165,261,149,276,148,293,144,308,139,330,130,323,135,328,142,314,147"
                shape="poly"
              />
              <area
                target=""
                alt="11365"
                title="11365"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11365`}
                coords="263,147,262,128,262,121,256,119,258,112,267,103,275,106,285,107,293,109,314,102,324,130,311,136,298,142,279,145"
                shape="poly"
              />
              <area
                target=""
                alt="11427"
                title="11427"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11427`}
                coords="349,169,340,159,329,143,345,141,361,136,376,133,387,127,392,140,392,145,388,150,382,145,372,154,362,165"
                shape="poly"
              />
              <area
                target=""
                alt="11364"
                title="11364"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11364`}
                coords="324,128,313,103,322,99,342,94,341,86,349,81,359,84,370,98,377,106,383,113,388,121,376,132,348,138,329,142"
                shape="poly"
              />
              <area
                target=""
                alt="11426"
                title="11426"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11426`}
                coords="396,160,394,140,392,131,387,126,377,129,389,120,395,110,407,97,415,113,423,132,428,148,416,154"
                shape="poly"
              />
              <area
                target=""
                alt="11004"
                title="11004"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11004`}
                coords="429,150,425,133,414,113,407,94,418,96,424,103,431,107,440,105,441,117,442,127,438,139"
                shape="poly"
              />
              <area
                target=""
                alt="11362"
                title="11362"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11362`}
                coords="390,116,381,106,367,89,354,82,362,77,375,73,387,58,405,69,421,84,413,86,407,96"
                shape="poly"
              />
              <area
                target=""
                alt="11101"
                title="11101"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11101`}
                coords="63,149,52,144,47,135,35,128,23,126,13,131,15,122,21,118,21,108,27,110,28,102,33,89,40,82,47,80,51,89,72,97,79,89,100,96,99,103,75,107,68,130"
                shape="poly"
              />
              <area
                target=""
                alt="11104"
                title="11104"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11104`}
                coords="62,148,68,131,75,108,98,103,93,109,90,120,80,125,76,136,72,149"
                shape="poly"
              />
              <area
                target=""
                alt="11377"
                title="11377"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11377`}
                coords="76,138,79,128,91,124,92,112,101,109,99,97,101,84,113,71,130,74,127,82,121,84,122,100,124,111,125,116,132,114,129,124,133,135,125,140,117,141,105,148,90,145"
                shape="poly"
              />
              <area
                target=""
                alt="11373"
                title="11373"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11373`}
                coords="133,135,129,120,132,114,165,105,170,116,169,125,174,128,174,137,161,148,157,144,143,150,150,148,139,143"
                shape="poly"
              />
              <area
                target=""
                alt="11372"
                title="11372"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11372`}
                coords="134,109,124,111,122,98,147,92,159,91,165,103,152,108,141,109"
                shape="poly"
              />
              <area
                target=""
                alt="11368"
                title="11368"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11368`}
                coords="173,136,176,128,168,124,169,114,164,101,160,89,172,88,181,86,188,85,194,84,213,73,220,81,218,90,221,105,219,115,206,125,195,132"
                shape="poly"
              />
              <area
                target=""
                alt="11355"
                title="11355"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11355`}
                coords="220,118,223,108,220,95,229,90,244,84,258,78,267,81,267,102,261,108,255,116,260,120,250,124,256,125"
                shape="poly"
              />
              <area
                target=""
                alt="11358"
                title="11358"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11358`}
                coords="269,101,269,82,269,65,273,61,275,53,291,55,301,63,300,75,304,81,311,98,301,106,285,106,292,109,275,104,307,103"
                shape="poly"
              />
              <area
                target=""
                alt="11354"
                title="11354"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11354`}
                coords="218,94,219,88,221,80,213,73,202,71,199,59,198,48,213,52,217,46,224,59,230,50,243,49,255,51,274,54,272,60,267,64,269,80,259,78,237,86"
                shape="poly"
              />
              <area
                target=""
                alt="11354"
                title="11354"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11361`}
                coords="303,104,311,97,308,84,300,74,302,61,316,58,323,50,324,57,333,51,341,56,350,68,359,74,355,80,343,84,340,92"
                shape="poly"
              />
              <area
                target=""
                alt="11363"
                title="11363"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11363`}
                coords="359,75,351,61,359,57,355,51,359,38,367,41,377,49,387,57,380,66,372,72,366,73"
                shape="poly"
              />
              <area
                target=""
                alt="11106"
                title="11106"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11106`}
                coords="42,80,48,71,60,63,54,56,65,69,80,77,79,87,73,94,60,91,49,88"
                shape="poly"
              />
              <area
                target=""
                alt="11102"
                title="11102"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11102`}
                coords="79,76,66,69,58,62,54,56,57,47,63,48,73,53,80,47,88,57,92,60,84,66"
                shape="poly"
              />
              <area
                target=""
                alt="11103"
                title="11103"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11103`}
                coords="80,88,84,81,80,75,87,65,93,60,106,66,114,69,103,80,100,95"
                shape="poly"
              />
              <area
                target=""
                alt="11370"
                title="11370"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11370`}
                coords="122,96,120,83,129,79,131,71,114,70,122,56,137,33,131,55,135,66,141,70,144,81,146,92"
                shape="poly"
              />
              <area
                target=""
                alt="11369"
                title="11369"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11369`}
                coords="145,92,143,76,142,69,154,60,162,61,173,66,181,80,186,84,171,88"
                shape="poly"
              />
              <area
                target=""
                alt="11105"
                title="11105"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11105`}
                coords="113,67,94,58,81,46,73,52,68,48,83,38,98,19,116,25,121,31,111,41,126,38,128,46,120,58"
                shape="poly"
              />
              <area
                target=""
                alt="11371"
                title="11371"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11371`}
                coords="176,70,187,67,189,58,177,50,163,41,166,31,156,41,152,34,148,42,140,43,141,55,132,55,135,64,140,70"
                shape="poly"
              />
              <area
                target=""
                alt="11370"
                title="11370"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11370`}
                coords="140,30,131,29,133,22,128,16,129,6,134,1,139,8,147,12,155,14,162,21,160,29,149,31"
                shape="poly"
              />
              <area
                target=""
                alt="11356"
                title="11356"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11356`}
                coords="199,48,199,37,188,38,183,31,189,27,192,18,191,12,199,16,206,6,210,12,217,9,219,21,229,23,229,32,234,35,236,40,230,49,225,58,217,45,212,51"
                shape="poly"
              />
              <area
                target=""
                alt="11357"
                title="11357"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11357`}
                coords="232,48,236,42,234,33,229,27,227,21,234,15,233,7,240,6,248,3,255,7,268,8,278,8,287,12,289,23,288,31,294,43,291,49,290,54,274,55"
                shape="poly"
              />
              <area
                target=""
                alt="11360"
                title="11360"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11360`}
                coords="300,60,291,53,294,42,287,29,289,21,296,26,304,20,313,26,310,12,320,10,326,24,322,30,329,36,336,49,324,55,324,47,318,56"
                shape="poly"
              />
              <area
                target=""
                alt="11005"
                title="11005"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11005`}
                coords="425,86,412,87,408,93,414,94,422,94,423,102,427,105,435,103,438,96,430,91,417,84"
                shape="poly"
              />
            </map>
          </div>
          <div class="maparea forMobile">
            <img src={queenMapMobile} />
            <map name="image-map">
              <area
                target=""
                alt="11370"
                title="11370"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11370`}
                coords="98,4,103,8,109,8,116,13,115,20,106,23,98,20,95,32,94,40,98,49,101,53,102,60,105,65,88,69,84,58,90,56,92,53,81,49,92,38,97,20,95,18,91,9,93,4"
                shape="poly"
              />
              <area
                target=""
                alt="11385"
                title="11385"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11385`}
                coords="57,132,78,132,99,141,107,142,114,141,124,135,132,133,133,140,146,140,151,139,155,148,142,155,139,147,135,149,137,156,120,161,115,158,102,168,94,177,88,177,81,170,82,166,75,163,79,158,71,152,69,145,57,139"
                shape="poly"
              />
              <area
                target=""
                alt="11697"
                title="11697"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11697`}
                coords="37,371,41,365,50,362,53,359,60,359,68,354,75,357,83,356,90,349,97,350,102,349,106,358,83,367,79,367,74,371,71,368,37,387"
                shape="poly"
              />
              <area
                target=""
                alt="11694"
                title="11694"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11694`}
                coords="102,348,109,349,138,328,147,329,156,327,168,322,172,318,175,321,175,327,172,332,159,337,148,342,105,358"
                shape="poly"
              />
              <area
                target=""
                alt="11372"
                title="11372"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11372`}
                coords="88,69,115,64,117,76,88,80"
                shape="poly"
              />
              <area
                target=""
                alt="11416"
                title="11416"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11416`}
                coords="123,178,155,164,159,171,135,182,125,183"
                shape="poly"
              />
              <area
                target=""
                alt="11421"
                title="11421"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11421`}
                coords="120,161,136,157,135,149,139,148,142,156,145,155,147,165,122,176"
                shape="poly"
              />
              <area
                target=""
                alt="11373"
                title="11373"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11373`}
                coords="92,81,117,77,125,98,117,106,113,105,105,108,97,108,93,99"
                shape="poly"
              />
              <area
                target=""
                alt="11106"
                title="11106"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11106`}
                coords="31,56,34,58,33,60,50,70,58,57,42,46,35,49"
                shape="poly"
              />
              <area
                target=""
                alt="11101"
                title="11101"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11101`}
                coords="28,58,49,71,55,65,64,70,70,69,70,73,52,77,49,95,53,97,50,105,46,106,47,111,36,105,33,97,18,92,9,96,10,87,16,84,13,82,15,80,18,81,18,76"
                shape="poly"
              />
              <area
                target=""
                alt="11102"
                title="11102"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11102`}
                coords="41,33,38,39,42,43,41,47,56,55,62,45,66,43,56,34,53,39"
                shape="poly"
              />
              <area
                target=""
                alt="11105"
                title="11105"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11105`}
                coords="49,33,52,37,56,35,66,42,63,46,72,46,80,48,90,39,91,27,87,27,82,29,87,23,83,16,72,15,63,19,64,24"
                shape="poly"
              />
              <area
                target=""
                alt="11103"
                title="11103"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11103`}
                coords="57,55,61,47,72,47,80,48,73,62,69,65,70,69,64,70,54,64"
                shape="poly"
              />
              <area
                target=""
                alt="11104"
                title="11104"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11104`}
                coords="52,78,61,75,71,74,72,77,64,77,64,84,59,89,57,89,54,97,50,95"
                shape="poly"
              />
              <area
                target=""
                alt="11377"
                title="11377"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11377`}
                coords="54,97,67,105,74,105,76,108,79,101,90,99,91,80,87,80,86,69,84,58,90,56,91,54,80,48,73,63,68,65,71,69,68,72,72,77,64,77,64,83,59,88,57,88,59,88,55,92"
                shape="poly"
              />
              <area
                target=""
                alt="11378"
                title="11378"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11378`}
                coords="53,98,51,106,47,107,47,109,55,125,54,128,55,133,76,130,98,118,98,108,93,99,81,100,75,109,73,105,67,105"
                shape="poly"
              />
              <area
                target=""
                alt="11371"
                title="11371"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11371`}
                coords="107,25,112,29,116,22,119,25,116,29,134,41,131,45,139,52,132,49,126,50,115,42,106,43,100,49,94,39,101,39,99,31,105,30"
                shape="poly"
              />
              <area
                target=""
                alt="11379"
                title="11379"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11379`}
                coords="79,129,97,119,98,109,105,109,117,107,129,127,125,132,115,139,105,140,98,139,78,131"
                shape="poly"
              />
              <area
                target=""
                alt="11356"
                title="11356"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11356`}
                coords="141,10,142,5,145,6,147,9,152,6,157,11,154,16,162,18,166,23,168,28,167,34,162,41,156,32,153,33,152,36,142,34,140,28,135,28,131,20,136,19,135,15"
                shape="poly"
              />
              <area
                target=""
                alt="11369"
                title="11369"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11369`}
                coords="99,49,106,43,113,42,122,49,129,57,135,61,132,64,115,65,115,63,105,64,101,57"
                shape="poly"
              />
              <area
                target=""
                alt="11368"
                title="11368"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11368`}
                coords="116,66,132,64,135,61,140,60,151,51,156,56,153,69,159,76,155,85,146,90,139,94,138,92,132,95,125,97,117,76"
                shape="poly"
              />
              <area
                target=""
                alt="11414"
                title="11414"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11414`}
                coords="125,213,127,217,130,219,133,226,141,226,137,230,140,235,154,233,151,211,154,211,156,229,163,228,162,220,166,227,169,228,162,205,161,201,156,201,129,189,131,194,134,194,135,205,131,205,131,210"
                shape="poly"
              />
              <area
                target=""
                alt="11417"
                title="11417"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11417`}
                coords="128,184,130,191,155,199,161,201,157,191,156,188,157,186,164,187,164,182,159,172,133,183,128,184"
                shape="poly"
              />
              <area
                target=""
                alt="11374"
                title="11374"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11374`}
                coords="118,107,124,98,132,95,139,108,132,118,132,129,126,133,129,125"
                shape="poly"
              />
              <area
                target=""
                alt="11375"
                title="11375"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11375`}
                coords="133,95,137,93,139,95,148,90,147,99,154,108,161,123,168,127,162,131,151,136,146,140,133,139,131,130,132,119,139,109"
                shape="poly"
              />
              <area
                target=""
                alt="11357"
                title="11357"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11357`}
                coords="162,16,162,14,166,12,168,6,173,1,179,3,186,4,202,6,207,9,205,17,204,21,209,27,211,30,207,33,211,37,194,39,180,36,174,35,166,36,167,26"
                shape="poly"
              />
              <area
                target=""
                alt="11355"
                title="11355"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11355`}
                coords="153,68,162,64,165,62,185,54,189,56,189,74,190,78,183,80,183,85,187,88,185,93,177,90,172,87,154,86,159,75"
                shape="poly"
              />
              <area
                target=""
                alt="11354"
                title="11354"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11354`}
                coords="142,35,141,42,144,50,148,52,151,51,156,57,152,66,162,64,185,54,189,56,195,40,172,34,167,34,162,41,156,32,153,33,152,36"
                shape="poly"
              />
              <area
                target=""
                alt="11367"
                title="11367"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11367`}
                coords="148,89,148,97,154,86,171,87,182,93,187,94,188,118,168,127,162,123,154,107,148,99"
                shape="poly"
              />
              <area
                target=""
                alt="11358"
                title="11358"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11358`}
                coords="196,39,209,39,215,53,212,53,216,57,222,73,205,77,203,75,201,77,190,75,189,57"
                shape="poly"
              />
              <area
                target=""
                alt="11418"
                title="11418"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11418`}
                coords="145,154,147,164,155,164,173,158,183,154,177,141,174,143,174,147,166,152,161,145,158,139,156,138,157,147"
                shape="poly"
              />
              <area
                target=""
                alt="11419"
                title="11419"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11419`}
                coords="155,163,183,155,189,170,164,182"
                shape="poly"
              />
              <area
                target=""
                alt="11420"
                title="11420"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11420`}
                coords="164,181,189,171,195,184,198,195,197,201,189,203,191,206,181,207,172,207,163,204,156,189,164,186"
                shape="poly"
              />
              <area
                target=""
                alt="11365"
                title="11365"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11365`}
                coords="183,80,190,78,190,74,200,77,202,74,207,78,222,72,231,96,204,105,188,108,187,94,183,92,188,89,183,85"
                shape="poly"
              />
              <area
                target=""
                alt="11415"
                title="11415"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11415`}
                coords="150,136,153,141,156,137,159,139,162,146,165,148,172,145,174,142,177,141,175,135,170,128,168,127"
                shape="poly"
              />
              <area
                target=""
                alt="11436"
                title="11436"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11436`}
                coords="192,176,207,172,209,177,211,188,214,191,211,199,196,202,198,195"
                shape="poly"
              />
              <area
                target=""
                alt="11435"
                title="11435"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11435`}
                coords="170,127,184,122,189,126,190,135,194,148,198,158,208,171,191,174,175,134"
                shape="poly"
              />
              <area
                target=""
                alt="11360"
                title="11360"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11360`}
                coords="205,14,209,17,212,14,219,10,220,17,225,17,238,36,230,41,229,37,228,40,214,45,209,39,212,38,208,33,211,29,203,22"
                shape="poly"
              />
              <area
                target=""
                alt="11413"
                title="11413"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11413`}
                coords="238,216,240,202,242,193,242,189,248,186,244,173,250,171,254,169,257,172,260,166,277,173,268,197,270,201,269,202,265,206,268,197,256,220,263,222,263,237"
                shape="poly"
              />
              <area
                target=""
                alt="11366"
                title="11366"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11366`}
                coords="187,108,200,106,230,96,233,103,189,121,188,116"
                shape="poly"
              />
              <area
                target=""
                alt="11432"
                title="11432"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11432`}
                coords="186,122,222,108,230,126,220,131,223,138,213,138,194,149,190,127"
                shape="poly"
              />
              <area
                target=""
                alt="11361"
                title="11361"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11361`}
                coords="212,45,228,41,229,36,231,41,237,36,247,49,254,51,255,55,247,57,247,60,244,63,244,66,238,66,223,73,217,57,213,54,216,52"
                shape="poly"
              />
              <area
                target=""
                alt="11362"
                title="11362"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11362`}
                coords="250,57,263,52,267,54,275,41,299,60,288,69,282,80,279,86,276,87,271,79,279,86,276,87,264,70,258,62"
                shape="poly"
              />
              <area
                target=""
                alt="11363"
                title="11363"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11363`}
                coords="250,36,256,26,261,30,274,39,266,54,255,54,252,50,253,45,256,41"
                shape="poly"
              />
              <area
                target=""
                alt="11364"
                title="11364"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11364`}
                coords="222,73,237,66,244,67,244,61,247,60,247,57,252,58,259,61,274,86,268,94,232,103"
                shape="poly"
              />
              <area
                target=""
                alt="11433"
                title="11433"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11433`}
                coords="194,150,212,137,222,139,228,137,228,141,223,141,228,149,235,156,225,163,208,171"
                shape="poly"
              />
              <area
                target=""
                alt="11434"
                title="11434"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11434`}
                coords="208,171,233,158,243,172,247,184,241,188,242,194,237,216,211,203,213,191,211,187"
                shape="poly"
              />
              <area
                target=""
                alt="11427"
                title="11427"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11427`}
                coords="234,102,239,112,245,116,248,123,269,106,275,108,278,100,274,99,277,96,276,93,269,96,268,95"
                shape="poly"
              />
              <area
                target=""
                alt="11423"
                title="11423"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11423`}
                coords="222,108,233,104,239,112,243,116,249,124,253,132,255,135,237,141,236,143,224,144,228,141,229,136,222,139,220,130,230,126"
                shape="poly"
              />
              <area
                target=""
                alt="11412"
                title="11412"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11412`}
                coords="225,143,235,143,238,141,254,135,263,158,260,166,257,171,254,169,244,172,233,158,236,155,228,148"
                shape="poly"
              />
              <area
                target=""
                alt="11411"
                title="11411"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11411`}
                coords="260,149,264,146,266,149,281,148,282,142,287,143,286,175,277,174,259,165,263,161"
                shape="poly"
              />
              <area
                target=""
                alt="11428"
                title="11428"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11428`}
                coords="249,122,267,108,275,109,278,104,280,109,281,116,283,120,275,122,253,130"
                shape="poly"
              />
              <area
                target=""
                alt="11429"
                title="11429"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11429`}
                coords="253,131,282,119,284,133,286,142,282,143,281,146,267,149,265,146,259,148"
                shape="poly"
              />
              <area
                target=""
                alt="11422"
                title="11422"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11422`}
                coords="257,218,265,202,267,205,270,202,268,197,277,175,286,176,282,205,285,212,286,223,268,229,267,241,269,249,266,247,260,245,263,241,262,235,263,222,256,220"
                shape="poly"
              />
              <area
                target=""
                alt="11426"
                title="11426"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11426`}
                coords="288,70,301,100,303,104,303,108,282,115,278,104,278,99,274,100,276,95,276,92,268,95,268,95,275,87,271,79,277,85,279,85"
                shape="poly"
              />
              <area
                target=""
                alt="11004"
                title="11004"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11004`}
                coords="288,69,293,65,300,72,302,77,309,76,314,78,313,84,314,90,300,97"
                shape="poly"
              />
              <area
                target=""
                alt="11001"
                title="11001"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11001`}
                coords="300,97,314,92,307,108,303,108,304,107"
                shape="poly"
              />
              <area
                target=""
                alt="11005"
                title="11005"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11005`}
                coords="299,60,293,65,302,75,307,68"
                shape="poly"
              />
              <area
                target=""
                alt="11040"
                title="11040"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11040`}
                coords="307,66,305,72,303,76,309,75,313,73"
                shape="poly"
              />
              <area
                target=""
                alt="11692"
                title="11692"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11692`}
                coords="191,307,196,313,198,311,196,306,194,302,205,301,212,295,217,299,211,302,210,309,212,309,214,302,215,315,213,320,198,323,194,318,197,313"
                shape="poly"
              />
              <area
                target=""
                alt="11693"
                title="11693"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11693`}
                coords="158,240,165,248,171,248,171,238,175,252,177,260,176,266,181,270,179,276,180,286,179,295,177,300,176,307,178,313,186,312,190,307,196,315,193,318,199,323,175,329,176,321,177,316,174,310,170,308,162,310,162,306,171,304,172,297,174,288,174,283,171,282,164,278,161,280,156,278,159,272,157,267,163,264,161,260,163,255"
                shape="poly"
              />
              <area
                target=""
                alt="11691"
                title="11691"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11691`}
                coords="232,279,239,282,246,286,253,286,261,284,268,289,262,291,268,296,272,298,272,306,270,309,262,310,253,316,245,314,229,315,219,320,212,322,215,314,216,314,216,306,217,299,219,296,219,293,222,288,229,287,224,299,221,305,222,307,225,301,231,300,232,305,237,304,232,299,234,291"
                shape="poly"
              />
              <area
                target=""
                alt="11096"
                title="11096"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-queens-11096`}
                coords="241,269,234,273,234,278,243,277,244,283,251,285,263,282,273,280,272,267,264,266,261,257,256,262,250,265,245,268"
                shape="poly"
              />
            </map>
          </div>
        </>
      ) : null}
      {cityName == "the-bronx" ? (
        <>
          <div className="maparea forDesktop mt-2">
            <img
              src={bronxMap}
              usemap="#overlay"
              className="map img-responsive"
            />
            <map name="overlay">
              <area
                target=""
                alt="11370"
                title="11370"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-11370`}
                coords="159,627,155,612,158,596,169,592,173,595,178,602,189,608,195,608,198,612,203,617,208,622,212,628,216,633,218,638,216,643,211,646,202,649,194,648,184,646,175,644,166,641"
                shape="poly"
              />
              <area
                target=""
                alt="10454"
                title="10454"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10454`}
                coords="39,547,69,518,99,538,111,544,116,545,122,544,126,556,125,562,121,566,108,587,104,594,99,597,92,596,85,592,79,587,72,578,66,575,59,574,53,574,49,564,44,556"
                shape="poly"
              />
              <area
                target=""
                alt="10474"
                title="10474"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10474`}
                coords="122,545,147,512,164,485,171,481,179,483,181,489,181,494,184,500,187,505,191,509,197,512,202,516,207,519,212,521,216,524,218,530,222,534,224,539,225,544,226,551,223,557,221,563,218,569,216,574,213,579,206,573,199,570,189,571,177,574,170,560,163,555,155,552,140,553,126,558"
                shape="poly"
              />
              <area
                target=""
                alt="10455"
                title="10455"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10455`}
                coords="70,517,78,494,80,486,86,485,95,487,100,495,106,496,111,498,118,500,123,491,128,496,136,502,140,506,144,510,141,518,134,527,129,533,122,542,114,544,105,540,99,539,91,533,83,528,74,521"
                shape="poly"
              />
              <area
                target=""
                alt="10451"
                title="10451"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10451`}
                coords="40,546,38,526,37,509,37,493,36,476,36,463,34,450,44,449,50,451,55,455,60,459,73,442,75,448,71,453,75,457,80,460,84,463,90,463,97,462,104,464,105,472,102,481,99,487,89,484,81,484,79,489,77,495,74,503,71,510,69,517,62,525,56,529,47,537"
                shape="poly"
              />
              <area
                target=""
                alt="10456"
                title="10456"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10456`}
                coords="71,438,80,421,85,413,89,406,91,399,94,405,99,410,104,411,103,399,102,391,112,386,118,387,119,392,117,400,115,406,124,411,132,415,138,416,140,425,136,432,133,439,131,446,130,451,128,459,125,470,122,480,123,491,119,499,111,498,105,496,95,494,99,487,104,476,106,469,102,463,94,462,88,463,80,459,73,455,74,447"
                shape="poly"
              />
              <area
                target=""
                alt="10457"
                title="10457"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10457`}
                coords="91,402,93,394,95,386,97,379,96,373,99,366,104,368,110,363,114,358,117,351,120,342,123,334,128,325,131,318,138,319,143,322,149,324,155,328,160,332,165,336,170,339,175,342,181,345,178,352,178,358,176,364,173,372,169,378,163,384,159,388,154,394,149,388,138,416,115,406,121,388,105,387,104,412,96,407"
                shape="poly"
              />
              <area
                target=""
                alt="10453"
                title="10453"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10453`}
                coords="97,377,87,376,79,376,66,374,59,374,51,375,54,367,57,360,61,354,63,347,66,339,69,331,75,330,80,320,85,314,94,308,98,304,103,306,109,307,114,310,119,312,127,314,132,317,127,326,125,331,123,337,119,347,119,355,111,361,107,365,99,366"
                shape="poly"
              />
              <area
                target=""
                alt="10459"
                title="10459"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10459`}
                coords="123,493,122,483,123,475,125,467,129,458,131,450,133,443,135,435,141,431,153,428,151,422,157,433,165,437,169,441,174,443,176,437,180,431,184,424,188,432,180,440,179,449,178,457,178,463,178,470,178,480,171,482,164,487,160,492,157,498,152,505,147,510,139,506,133,500,128,495"
                shape="poly"
              />
              <area
                target=""
                alt="10460"
                title="10460"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10460`}
                coords="142,427,139,419,140,409,145,400,149,393,156,392,164,386,169,382,173,376,175,371,179,359,181,346,183,338,186,326,193,316,199,311,201,315,200,324,207,327,213,326,216,331,216,336,217,342,217,348,217,355,218,361,218,368,218,374,216,381,221,383,226,379,234,387,237,394,239,399,239,405,240,414,241,420,241,427,235,423,228,420,219,416,209,414,199,413,193,420,187,421,183,427,175,443,166,437,155,430,151,421"
                shape="poly"
              />
              <area
                target=""
                alt="10472"
                title="10472"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10472`}
                coords="179,477,179,465,179,449,180,437,186,431,188,423,196,416,206,413,216,418,227,423,233,425,241,430,250,433,256,437,267,440,279,446,288,449,295,453,282,456,273,460,261,460,245,466,228,469,216,471,207,473,195,474,188,474"
                shape="poly"
              />
              <area
                target=""
                alt="10473"
                title="10473"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10473`}
                coords="180,479,188,490,193,496,200,504,206,509,220,518,226,524,230,530,240,536,247,533,258,536,260,542,260,550,261,558,267,559,275,553,280,557,287,558,281,549,282,529,266,522,263,515,266,507,272,517,280,517,285,522,290,527,293,533,296,522,299,513,302,502,307,493,308,483,305,470,301,458,299,451,292,453,282,454,273,458,261,460,253,463,240,466,229,467,221,467,214,470,201,474,192,473"
                shape="poly"
              />
              <area
                target=""
                alt="10462"
                title="10462"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10462`}
                coords="300,454,290,449,282,446,274,442,263,437,256,433,249,430,241,427,243,419,242,411,240,400,236,391,234,384,227,376,222,383,215,383,217,371,219,361,220,354,219,344,217,333,215,324,221,321,230,320,243,318,247,324,255,334,256,340,259,346,262,352,264,358,268,365,271,371,276,377,268,381,276,399,268,390,282,404,286,411,290,416,303,419,311,419,304,432,298,437,300,444"
                shape="poly"
              />
              <area
                target=""
                alt="10461"
                title="10461"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10461`}
                coords="244,316,254,317,266,318,273,317,279,317,287,317,297,316,308,317,324,321,330,324,342,327,350,328,351,337,349,349,348,354,349,367,351,377,353,389,353,400,356,414,354,424,348,430,341,434,342,425,334,420,326,420,318,420,311,427,306,438,304,445,304,453,309,420,295,418,282,406,275,399,266,387,274,380,269,367,263,354,255,340,251,330"
                shape="poly"
              />
              <area
                target=""
                alt="10468"
                title="10468"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10468`}
                coords="76,332,72,325,77,316,83,307,90,296,97,294,104,283,112,265,117,256,123,248,126,240,128,232,134,236,134,243,137,255,144,246,149,236,154,227,156,221,160,214,166,203,170,197,174,203,174,209,175,215,177,222,175,233,171,237,169,243,166,250,160,257,159,265,153,272,149,280,143,293,139,305,135,314,129,316,120,313,112,309,101,306,93,309,83,318,80,326"
                shape="poly"
              />
              <area
                target=""
                alt="10458"
                title="10458"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10458`}
                coords="131,317,134,308,139,298,143,293,146,286,151,277,158,264,163,250,168,241,172,234,174,223,182,232,183,239,186,246,188,254,189,261,196,272,206,279,209,287,209,298,205,303,195,310,189,317,187,324,183,333,181,341,173,340,166,338,155,330,148,326,141,321"
                shape="poly"
              />
              <area
                target=""
                alt="10463"
                title="10463"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10463`}
                coords="92,295,95,288,100,281,101,273,104,267,107,260,108,252,103,241,92,235,80,227,70,223,63,220,62,210,65,201,69,193,70,181,76,182,82,180,84,166,93,167,103,173,133,189,132,188,147,183,159,180,166,175,170,168,170,187,170,199,164,207,159,213,156,220,153,230,148,237,146,245,139,255,139,245,129,230,128,239,125,245,121,255,116,260,110,269,108,276,108,283,101,290,93,295"
                shape="poly"
              />
              <area
                target=""
                alt="10471"
                title="10471"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10471`}
                coords="69,182,73,173,78,155,80,141,81,132,82,125,85,118,85,110,88,99,94,87,95,73,96,63,99,55,115,61,125,65,134,68,148,75,162,81,168,85,176,87,185,92,194,95,194,106,193,120,190,128,190,134,187,142,181,150,172,161,165,169,156,174,148,180,135,185,128,186,118,179,112,174,105,172,94,167,82,161,82,168,80,175,81,183"
                shape="poly"
              />
              <area
                target=""
                alt="10470"
                title="10470"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10470`}
                coords="183,147,195,144,201,142,212,140,223,141,241,146,245,135,247,128,271,77,251,120,228,111,216,105,210,101,203,98,195,95,194,104,193,117,193,127,190,134,187,140,186,136,189,140,271,89,278,89,290,94,291,99,286,116,281,122,273,127,267,128,262,124,240,116,234,112,184,144"
                shape="poly"
              />
              <area
                target=""
                alt="10478"
                title="10478"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10478`}
                coords="169,170,174,161,181,151,188,147,195,142,202,141,209,141,223,140,233,142,241,146,238,153,233,166,228,177,227,184,222,196,221,204,219,214,218,222,226,211,230,203,233,191,234,181,243,187,253,191,261,196,259,207,252,224,249,230,249,236,248,244,246,257,246,270,245,276,245,284,245,293,243,300,242,308,242,315,236,319,227,321,220,323,209,323,202,326,200,319,201,309,206,302,209,296,212,288,205,279,196,270,190,263,188,249,183,236,179,224,176,215,173,203,169,195,170,184"
                shape="poly"
              />
              <area
                target=""
                alt="10466"
                title="10466"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10466`}
                coords="218,224,223,217,228,208,232,198,232,188,242,188,249,191,255,195,261,197,268,202,275,206,282,209,290,214,297,219,307,213,316,206,323,201,329,196,335,192,342,187,352,180,356,175,363,169,352,167,343,163,334,160,327,157,317,154,312,147,310,135,309,127,306,113,297,103,290,100,290,107,288,115,285,124,275,129,265,130,254,124,247,126,240,143,239,153,236,160,234,168,230,175,225,193,220,210,223,199"
                shape="poly"
              />
              <area
                target=""
                alt="10469"
                title="10469"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10469`}
                coords="263,199,270,201,278,205,289,208,297,214,308,208,315,210,315,218,319,227,322,234,325,244,329,253,329,260,332,267,335,277,339,285,342,293,345,300,336,313,347,310,354,307,362,301,366,307,373,307,369,314,363,320,359,326,355,332,347,328,332,325,322,321,314,319,299,314,286,316,272,317,262,316,253,316,242,314,242,303,243,294,245,285,246,276,246,267,248,255,248,246,249,237,254,225,256,217,258,205"
                shape="poly"
              />
              <area
                target=""
                alt="10475"
                title="10475"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10475`}
                coords="313,207,319,204,325,199,332,196,341,191,348,184,353,179,362,184,365,190,365,198,362,210,361,218,357,232,356,240,356,249,357,255,360,263,368,273,368,280,369,288,369,299,359,304,350,309,343,313,334,316,340,305,346,297,339,289,336,280,334,270,329,259,327,252,325,245,322,237,316,220"
                shape="poly"
              />
              <area
                target=""
                alt="10475"
                title="10475"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10475`}
                coords="357,177,362,172,367,166,375,170,381,171,382,177,381,184,379,198,376,206,375,219,373,226,372,233,372,243,372,253,367,259,365,245,365,231,368,220,370,213,370,198,372,187,367,179"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="383,172,390,174,397,178,409,183,421,187,428,191,435,193,447,198,440,215,436,223,420,253,417,260,404,255,408,266,413,272,417,278,423,280,427,273,430,265,433,258,437,247,447,244,448,236,448,226,449,213,456,207,461,212,469,220,470,227,469,237,469,245,468,256,467,263,456,266,454,277,452,285,450,295,448,306,446,313,436,324,433,335,434,343,433,351,428,358,423,352,415,342,414,333,415,322,414,313,417,299,412,295,408,303,406,310,400,300,396,293,386,286,380,280,385,273,386,266,385,255,376,264,369,260,372,253,372,240,374,229,375,222,377,215,377,207,379,199,381,187,381,180"
                shape="poly"
              />
              <area
                target=""
                alt="10465"
                title="10465"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10465`}
                coords="372,309,382,300,383,308,376,315,374,326,376,332,379,341,380,352,381,366,381,374,387,381,383,393,382,402,379,415,379,424,383,444,388,450,393,462,396,470,400,478,408,490,414,494,423,505,432,508,433,515,423,521,417,513,413,506,409,512,414,523,419,531,424,535,440,543,447,546,456,551,461,557,449,563,442,553,435,548,426,544,419,541,409,533,400,525,388,519,380,519,372,521,356,527,346,531,337,534,337,544,336,552,337,555,330,557,320,555,317,546,313,532,310,523,310,510,310,503,314,493,315,480,313,472,310,462,305,451,305,439,310,427,322,419,345,427,352,419,354,405,353,391,349,377,348,361,348,351,348,339,349,329,357,326,362,318,366,313"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="452,321,456,313,461,321,463,332,469,341,475,348,479,357,479,368,477,379,477,388,481,400,479,408,470,402,467,392,463,384,459,374,456,361,462,348,461,339,455,328"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="508,308,508,317,508,324,508,330,508,337,508,348,511,359,514,365,517,371,523,371,529,361,529,353,529,341,529,329,529,319,526,313,519,306,513,302"
                shape="poly"
              />
              <area
                target=""
                alt="10452"
                title="10452"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10452`}
                coords="35,450,42,449,52,451,57,455,65,449,70,442,73,432,80,421,85,411,89,406,92,398,93,390,97,384,99,377,91,377,77,376,66,376,53,376,51,375,48,385,44,400,37,418,35,428,35,436,35,442"
                shape="poly"
              />
            </map>
          </div>

          <div class="maparea forMobile ">
            <img src={bronxMapMobile} />
            <map name="image-map">
              <area
                target=""
                alt="10471"
                title="10471"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10471`}
                coords="60,34,83,44,113,57,112,79,105,92,95,103,82,111,65,103,52,96,47,108,42,108"
                shape="poly"
              />
              <area
                target=""
                alt="10470"
                title="10470"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10470`}
                coords="113,56,144,71,141,85,126,82,113,84,106,86,111,76"
                shape="poly"
              />
              <area
                target=""
                alt="10478"
                title="10478"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10478`}
                coords="141,86,123,82,108,87,99,99,100,110,102,120,107,134,111,151,113,161,121,164,123,171,123,177,118,182,119,191,142,186,142,157,145,131,151,114,135,107,135,113,132,120,127,128,137,91"
                shape="poly"
              />
              <area
                target=""
                alt="10469"
                title="10469"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10469`}
                coords="149,114,145,131,143,147,141,163,140,186,173,186,204,196,214,184,208,179,196,185,199,175,181,122,173,127,160,120,149,118"
                shape="poly"
              />
              <area
                target=""
                alt="10463"
                title="10463"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10463`}
                coords="48,95,46,106,41,110,38,118,36,130,45,133,59,143,63,152,60,168,55,175,75,135,79,137,79,150,98,113,97,98,79,109,57,98,48,96"
                shape="poly"
              />
              <area
                target=""
                alt="10468"
                title="10468"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10468`}
                coords="103,129,98,117,79,150,78,135,74,135,71,144,67,151,63,163,57,175,52,181,41,193,44,196,57,180,77,188,96,148,100,140,82,177"
                shape="poly"
              />
              <area
                target=""
                alt="10458"
                title="10458"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10458`}
                coords="77,187,86,191,104,204,109,190,118,179,120,175,120,165,111,159,109,145,103,131,98,142,91,158,82,178,86,167,94,149,84,173,82,183"
                shape="poly"
              />
              <area
                target=""
                alt="10462"
                title="10462"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10462`}
                coords="125,192,142,188,159,225,153,227,173,249,180,246,178,252,173,260,177,267,170,267,140,251,139,233,133,223,127,226,127,210,127,201"
                shape="poly"
              />
              <area
                target=""
                alt="10461"
                title="10461"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10461`}
                coords="142,189,173,186,203,196,202,208,202,217,205,231,206,246,202,253,192,247,184,249,176,265,182,245,173,249,155,228,159,223,150,205"
                shape="poly"
              />
              <area
                target=""
                alt="10466"
                title="10466"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10466`}
                coords="146,71,141,85,136,95,127,131,135,116,135,107,151,115,174,128,214,98,207,99,182,88,179,77,178,66,173,60,168,61,165,72,156,77"
                shape="poly"
              />
              <area
                target=""
                alt="10460"
                title="10460"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10460`}
                coords="116,181,110,189,105,198,103,209,101,217,98,223,94,228,89,231,87,233,81,245,83,251,88,248,89,254,101,260,108,248,115,247,120,243,128,246,140,250,140,238,132,223,127,227,126,208,126,197,126,191,117,193"
                shape="poly"
              />
              <area
                target=""
                alt="10465"
                title="10465"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10465`}
                coords="201,253,193,248,182,249,176,262,179,271,182,283,182,293,181,300,181,311,186,327,195,329,196,315,206,312,216,307,224,307,232,309,241,319,248,319,263,332,267,327,260,324,239,311,236,298,242,299,246,308,252,301,236,291,226,268,220,244,222,228,223,222,220,212,220,201,216,190,222,184,219,178,214,183,204,195,201,212,204,225,205,247"
                shape="poly"
              />
              <area
                target=""
                alt="10472"
                title="10472"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10472`}
                coords="170,269,104,283,105,268,107,255,119,245,145,255"
                shape="poly"
              />
              <area
                target=""
                alt="10473"
                title="10473"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10473`}
                coords="174,269,159,272,139,276,104,282,109,289,115,298,124,304,130,311,138,317,146,317,150,318,150,326,153,332,161,328,167,332,163,322,164,313,153,308,156,300,170,315,175,303,181,288"
                shape="poly"
              />
              <area
                target=""
                alt="10455"
                title="10455"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10455`}
                coords="48,288,41,308,51,312,62,320,70,322,77,314,85,303,72,290,70,297,60,293,56,288"
                shape="poly"
              />
              <area
                target=""
                alt="10457"
                title="10457"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10457`}
                coords="76,188,90,195,103,204,102,216,97,223,90,234,89,229,80,246,67,240,71,232,65,228,60,229,61,244,54,239,58,223,59,216,63,219,69,210,74,194"
                shape="poly"
              />
              <area
                target=""
                alt="10459"
                title="10459"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10459`}
                coords="88,250,79,258,77,268,74,280,72,288,85,302,97,287,104,284,104,269,104,258,94,258"
                shape="poly"
              />
              <area
                target=""
                alt="10453"
                title="10453"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10453`}
                coords="57,180,75,187,70,205,64,214,60,218,55,221,31,221,38,202,46,195,51,188"
                shape="poly"
              />
              <area
                target=""
                alt="10454"
                title="10454"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10454`}
                coords="25,324,42,307,52,316,67,322,74,328,71,336,64,346,57,354,46,348,40,340,30,337"
                shape="poly"
              />
              <area
                target=""
                alt="10474"
                title="10474"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10474`}
                coords="69,322,75,329,83,326,93,328,99,331,102,339,110,337,118,337,122,342,127,338,131,328,129,319,125,308,115,303,107,295,104,284,95,289,88,299,81,309,75,318"
                shape="poly"
              />
              <area
                target=""
                alt="11370"
                title="11370"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-11370`}
                coords="93,350,101,351,107,358,115,362,120,368,126,377,124,383,112,383,98,379,96,371,90,368"
                shape="poly"
              />
              <area
                target=""
                alt="10456"
                title="10456"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10456`}
                coords="61,230,70,230,66,240,80,245,84,253,78,258,72,281,72,291,69,295,56,291,64,276,56,272,48,273,43,268,43,258,49,247,53,238,61,244"
                shape="poly"
              />
              <area
                target=""
                alt="10451"
                title="10451"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10451`}
                coords="21,266,24,324,41,306,47,286,56,288,63,275,51,272,41,268,44,261,36,273,31,268"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="221,101,258,116,254,132,247,144,243,154,232,147,233,154,239,158,242,163,246,167,251,154,253,146,258,142,259,134,263,125,267,126,272,129,272,139,272,153,265,159,262,176,258,183,257,189,250,193,250,199,253,203,249,211,239,198,243,177,236,174,236,183,229,172,221,168,224,156,221,149,218,156,212,151,216,145,216,137,219,120,220,109"
                shape="poly"
              />
              <area
                target=""
                alt="10475"
                title="10475"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10475`}
                coords="182,122,205,104,213,97,222,101,221,109,219,120,217,128,216,138,216,150,213,153,211,141,216,110,210,105,208,105,210,113,209,124,207,145,212,159,213,167,215,174,196,187,201,177,195,163,191,147,185,132"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="266,183,262,188,264,197,267,204,264,220,269,228,275,240,280,238,277,226,278,214,270,200"
                shape="poly"
              />
              <area
                target=""
                alt="10464"
                title="10464"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-the-bronx-10464`}
                coords="294,180,295,212,299,219,305,219,306,193,299,180"
                shape="poly"
              />
            </map>
          </div>
        </>
      ) : null}
      {cityName == "manhattan" ? (
        <>
          <div className="maparea forDesktop mt-2">
            <img src={manhattan} class="map img-responsive" usemap="#overlay" />
            <map name="overlay">
              <area
                target=""
                alt="10004"
                title="10004"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-110004`}
                coords="145,1297,149,1306,153,1313,156,1318,160,1325,167,1330,173,1333,183,1336,189,1338,195,1340,200,1337,206,1332,211,1328,218,1323,223,1317,232,1314,238,1309,246,1304,251,1299,239,1296,194,1276,197,1268,188,1264,179,1290,153,1293"
                shape="poly"
              />
              <area
                target=""
                alt="10005"
                title="10005"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10005`}
                coords="194,1251,196,1252,200,1254,196,1252,211,1257,219,1260,226,1262,232,1266,242,1269,250,1272,258,1274,268,1276,275,1278,282,1283,273,1288,266,1291,259,1293,253,1298,251,1300,238,1296,195,1275,198,1267,188,1264,190,1257"
                shape="poly"
              />
              <area
                target=""
                alt="10038"
                title="10038"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10038`}
                coords="197,1236,208,1233,226,1229,239,1227,254,1223,266,1220,278,1218,289,1213,300,1213,307,1213,309,1204,313,1195,318,1193,325,1193,332,1193,339,1193,335,1199,330,1203,325,1210,327,1220,332,1234,334,1248,324,1254,292,1274,282,1281,245,1268,213,1257,198,1252,191,1250,195,1242"
                shape="poly"
              />
              <area
                target=""
                alt="10002"
                title="10002"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10002`}
                coords="341,1110,389,1110,450,1111,495,1111,504,1161,461,1183,414,1204,389,1214,335,1249,325,1207,341,1194,339,1131"
                shape="poly"
              />
              <area
                target=""
                alt="10009"
                title="10009"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10009`}
                coords="387,1037,458,1037,498,1110,389,1110,388,1043"
                shape="poly"
              />
              <area
                target=""
                alt="10010"
                title="10010"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10010`}
                coords="251,1035,458,1035,439,996,439,983,306,983,248,984,247,1027"
                shape="poly"
              />
              <area
                target=""
                alt="10016"
                title="10016"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10016`}
                coords="303,916,433,915,429,949,436,982,301,982,300,921"
                shape="poly"
              />
              <area
                target=""
                alt="10017"
                title="10017"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10017`}
                coords="302,856,437,858,434,917,301,916,300,863"
                shape="poly"
              />
              <area
                target=""
                alt="10022"
                title="10022"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10022`}
                coords="303,794,445,797,440,830,437,856,302,857,300,801"
                shape="poly"
              />
              <area
                target=""
                alt="10021"
                title="10021"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10021`}
                coords="300,713,465,714,447,796,302,794,301,721"
                shape="poly"
              />
              <area
                target=""
                alt="10028"
                title="10028"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10028`}
                coords="302,678,456,677,466,713,302,712,302,686"
                shape="poly"
              />
              <area
                target=""
                alt="10128"
                title="10128"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10128`}
                coords="301,630,448,631,447,638,459,678,302,678,300,638"
                shape="poly"
              />
              <area
                target=""
                alt="10029"
                title="10029"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10029`}
                coords="302,537,447,537,458,596,447,632,302,631,301,545"
                shape="poly"
              />
              <area
                target=""
                alt="10035"
                title="10035"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10035`}
                coords="302,465,410,465,444,497,449,535,301,536,301,471"
                shape="poly"
              />
              <area
                target=""
                alt="10039"
                title="10039"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10039`}
                coords="207,389,346,390,287,325,266,274,216,317,205,344"
                shape="poly"
              />
              <area
                target=""
                alt="10006"
                title="10006"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10006`}
                coords="125,1258,143,1258,144,1242,197,1235,178,1290,145,1293"
                shape="poly"
              />
              <area
                target=""
                alt="10048"
                title="10048"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10048`}
                coords="114,1234,127,1234,128,1226,136,1227,135,1234,142,1234,144,1241,141,1256,124,1257,114,1241,115,1248"
                shape="poly"
              />
              <area
                target=""
                alt="10007"
                title="10007"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10007`}
                coords="109,1209,166,1211,167,1193,314,1195,305,1211,174,1243,147,1242,136,1236,136,1227,126,1226,126,1234,112,1233,108,1218"
                shape="poly"
              />
              <area
                target=""
                alt="10013"
                title="10013"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10013`}
                coords="95,1158,182,1128,197,1158,339,1161,339,1193,166,1191,165,1209,108,1209,94,1165"
                shape="poly"
              />
              <area
                target=""
                alt="10012"
                title="10012"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10012`}
                coords="164,1085,339,1087,339,1112,339,1163,198,1157"
                shape="poly"
              />
              <area
                target=""
                alt="10014"
                title="10014"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10014`}
                coords="62,1036,131,1039,163,1085,182,1126,94,1157"
                shape="poly"
              />
              <area
                target=""
                alt="10003"
                title="10003"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10003`}
                coords="292,1037,388,1037,389,1110,341,1109,340,1086,293,1085,290,1043"
                shape="poly"
              />
              <area
                target=""
                alt="10011"
                title="10011"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10011`}
                coords="53,1000,246,1003,248,1039,291,1038,290,1084,165,1083,128,1038,64,1036"
                shape="poly"
              />
              <area
                target=""
                alt="10001"
                title="10001"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10001`}
                coords="47,955,299,958,300,983,250,983,247,1003,54,1001,48,989"
                shape="poly"
              />
              <area
                target=""
                alt="10018"
                title="10018"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10018`}
                coords="48,897,244,897,245,886,301,887,300,959,48,956"
                shape="poly"
              />
              <area
                target=""
                alt="10036"
                title="10036"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10036`}
                coords="47,863,300,865,301,887,246,887,246,897,47,898"
                shape="poly"
              />
              <area
                target=""
                alt="10019"
                title="10019"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10019`}
                coords="48,799,300,801,300,865,49,864"
                shape="poly"
              />
              <area
                target=""
                alt="10023"
                title="10023"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10023`}
                coords="48,723,203,726,203,798,48,798"
                shape="poly"
              />
              <area
                target=""
                alt="10024"
                title="10024"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10024`}
                coords="49,647,204,648,204,727,51,722"
                shape="poly"
              />
              <area
                target=""
                alt="10025"
                title="10025"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10025`}
                coords="47,542,94,542,95,559,146,560,146,542,165,543,164,568,205,570,204,648,49,647"
                shape="poly"
              />
              <area
                target=""
                alt="10026"
                title="10026"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10026`}
                coords="165,505,300,506,301,572,165,569,165,536"
                shape="poly"
              />
              <area
                target=""
                alt="10027"
                title="10027"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10027`}
                coords="57,425,80,426,79,440,277,442,278,466,300,468,300,506,166,505,163,544,147,542,144,558,96,559,95,542,47,542,45,435"
                shape="poly"
              />
              <area
                target=""
                alt="10031"
                title="10031"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10031`}
                coords="82,406,74,346,209,348,210,442,85,441,83,425,60,422,74,409"
                shape="poly"
              />
              <area
                target=""
                alt="10032"
                title="10032"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10032`}
                coords="62,265,261,266,265,273,218,318,205,348,73,348"
                shape="poly"
              />
              <area
                target=""
                alt="10033"
                title="10033"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10033`}
                coords="62,198,259,201,259,257,264,266,64,265"
                shape="poly"
              />
              <area
                target=""
                alt="10040"
                title="10040"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10040`}
                coords="61,185,100,178,101,135,115,118,156,118,259,180,260,198,61,196"
                shape="poly"
              />
              <area
                target=""
                alt="10034"
                title="10034"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10034`}
                coords="61,89,80,46,130,24,147,52,207,15,244,13,261,49,258,178,158,116,115,117,100,134,101,177,62,187"
                shape="poly"
              />
              <area
                target=""
                alt="10044"
                title="10044"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10044`}
                coords="460,825,483,693,497,710,498,799,470,859,459,851,457,834,481,703"
                shape="poly"
              />
              <area
                target=""
                alt="10030"
                title="10030"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10030`}
                coords="211,391,345,391,280,442,208,441"
                shape="poly"
              />
              <area
                target=""
                alt="10037"
                title="10037"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10037`}
                coords="345,390,410,463,282,465,279,444"
                shape="poly"
              />
            </map>
          </div>
          <div class="maparea forMobile">
            <img src={manhattanMobile} />
            <map name="image-map">
              <area
                target=""
                alt="10034"
                title="10034"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10034`}
                coords="68,38,76,18,99,9,106,22,131,6,146,5,156,20,155,35,155,49,155,63,155,78,132,64,111,51,92,51,84,60,84,76,68,82"
                shape="poly"
              />
              <area
                target=""
                alt="10040"
                title="10040"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10040`}
                coords="65,82,84,77,84,59,92,52,110,51,128,63,155,78,154,86,137,86,110,86,68,86,67,86,65,82,67,86"
                shape="poly"
              />
              <area
                target=""
                alt="10033"
                title="10033"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10033`}
                coords="67,86,154,85,155,117,68,117"
                shape="poly"
              />
              <area
                target=""
                alt="10032"
                title="10032"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10032`}
                coords="68,115,154,116,157,120,134,140,130,151,72,151"
                shape="poly"
              />
              <area
                target=""
                alt="10031"
                title="10031"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10031`}
                coords="71,152,130,152,130,171,130,193,76,192,76,185,69,185,74,178"
                shape="poly"
              />
              <area
                target=""
                alt="10039"
                title="10039"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10039`}
                coords="157,119,165,143,192,171,131,170,133,140,131,170,130,153,134,140"
                shape="poly"
              />
              <area
                target=""
                alt="10037"
                title="10037"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10037`}
                coords="164,204,191,171,221,204"
                shape="poly"
              />
              <area
                target=""
                alt="10030"
                title="10030"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10030`}
                coords="166,203,192,171,131,170,130,194,158,192,158,192"
                shape="poly"
              />
              <area
                target=""
                alt="10035"
                title="10035"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10035`}
                coords="173,204,221,203,235,218,237,235,173,235"
                shape="poly"
              />
              <area
                target=""
                alt="10029"
                title="10029"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10029`}
                coords="173,235,238,236,241,260,236,276,173,276"
                shape="poly"
              />
              <area
                target=""
                alt="10024"
                title="10024"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10024`}
                coords="62,282,130,283,130,318,62,316"
                shape="poly"
              />
              <area
                target=""
                alt="10023"
                title="10023"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10023`}
                coords="62,316,130,317,130,350,62,348"
                shape="poly"
              />
              <area
                target=""
                alt="10019"
                title="10019"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10019`}
                coords="62,349,172,350,172,378,62,377"
                shape="poly"
              />
              <area
                target=""
                alt="10001"
                title="10001"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10001`}
                coords="62,419,173,419,173,430,150,432,150,438,64,438,62,431"
                shape="poly"
              />
              <area
                target=""
                alt="10128"
                title="10128"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10128`}
                coords="173,275,237,275,237,281,241,295,174,296"
                shape="poly"
              />
              <area
                target=""
                alt="10028"
                title="10028"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10028`}
                coords="173,297,241,296,244,312,173,312"
                shape="poly"
              />
              <area
                target=""
                alt="10021"
                title="10021"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10021`}
                coords="173,312,244,312,237,346,173,347"
                shape="poly"
              />
              <area
                target=""
                alt="10022"
                title="10022"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10022`}
                coords="173,348,236,347,234,363,233,375,173,375"
                shape="poly"
              />
              <area
                target=""
                alt="10017"
                title="10017"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10017`}
                coords="173,376,232,376,231,401,172,401"
                shape="poly"
              />
              <area
                target=""
                alt="10016"
                title="10016"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10016`}
                coords="172,400,231,400,230,416,233,432,173,430"
                shape="poly"
              />
              <area
                target=""
                alt="10036"
                title="10036"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10036`}
                coords="174,377,62,378,62,392,148,392,150,387,171,387"
                shape="poly"
              />
              <area
                target=""
                alt="10018"
                title="10018"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10018`}
                coords="62,392,144,394,149,389,173,389,173,419,62,419"
                shape="poly"
              />
              <area
                target=""
                alt="10010"
                title="10010"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10010`}
                coords="149,432,173,431,233,431,233,437,241,452,149,453,149,444,149,439,149,444"
                shape="poly"
              />
              <area
                target=""
                alt="10011"
                title="10011"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10011`}
                coords="63,438,149,438,149,452,168,452,168,475,112,475,97,454,67,454"
                shape="poly"
              />
              <area
                target=""
                alt="10003"
                title="10003"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10003`}
                coords="168,453,211,454,210,485,189,485,188,474,168,474"
                shape="poly"
              />
              <area
                target=""
                alt="10009"
                title="10009"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10009`}
                coords="211,453,242,452,258,484,258,486,210,485"
                shape="poly"
              />
              <area
                target=""
                alt="10014"
                title="10014"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10014`}
                coords="67,453,98,453,98,453,112,475,121,493,81,506"
                shape="poly"
              />
              <area
                target=""
                alt="10012"
                title="10012"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10012`}
                coords="112,475,187,474,190,507,127,506"
                shape="poly"
              />
              <area
                target=""
                alt="10048"
                title="10048"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10048`}
                coords="89,539,101,539,103,549,95,550"
                shape="poly"
              />
              <area
                target=""
                alt="10007"
                title="10007"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10007`}
                coords="89,528,90,541,98,536,103,543,120,543,174,530,179,523,114,522,114,530,89,530"
                shape="poly"
              />
              <area
                target=""
                alt="10013"
                title="10013"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10013`}
                coords="81,508,120,496,127,507,189,508,189,523,114,523,112,529,87,530"
                shape="poly"
              />
              <area
                target=""
                alt="10006"
                title="10006"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10006`}
                coords="93,551,101,550,103,542,112,543,127,542,119,566,105,566"
                shape="poly"
              />
              <area
                target=""
                alt="10002"
                title="10002"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10002`}
                coords="189,523,190,486,261,487,261,507,239,519,211,531,187,545,183,528"
                shape="poly"
              />
              <area
                target=""
                alt="10038"
                title="10038"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10038`}
                coords="127,542,174,532,177,523,189,521,189,524,184,531,187,548,163,561,124,548"
                shape="poly"
              />
              <area
                target=""
                alt="10005"
                title="10005"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10005`}
                coords="125,556,122,553,125,546,163,562,150,569,127,559"
                shape="poly"
              />
              <area
                target=""
                alt="10004"
                title="10004"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10004`}
                coords="111,581,105,567,117,565,120,554,127,554,128,559,149,570,125,586"
                shape="poly"
              />
              <area
                target=""
                alt="10044"
                title="10044"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10044`}
                coords="252,302,258,311,258,348,246,377,242,372,241,362"
                shape="poly"
              />
              <area
                target=""
                alt="10027"
                title="10027"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10027`}
                coords="62,190,68,185,76,187,76,195,162,193,162,204,171,204,171,220,111,220,112,236,105,237,103,244,82,244,82,236,63,236"
                shape="poly"
              />
              <area
                target=""
                alt="10025"
                title="10025"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,"fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10025`}
                coords="103,237,112,237,112,249,131,249,130,283,62,282,62,237,81,236,81,245,103,245"
                shape="poly"
              />
              <area
                target=""
                alt="10026"
                title="10026"
                data-maphilight='{"strokeColor":"110600","strokeWidth":2,&qquot;fillColor":"ee8b48","fillOpacity":0.6}'
                href={`${MapUrl}--zip-manhattan-10026`}
                coords="112,222,171,220,173,250,130,249,112,247"
                shape="poly"
              />
            </map>
          </div>
        </>
      ) : null}
    </>
  );
}
