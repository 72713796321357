import React, { useEffect, useState } from "react";
import { Vortex } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BASE_URL, token } from "../../component/token";

export default function LocationPage() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("button1");
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchCity, setSearchCity] = useState([]);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [cityZips, setCityZips] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [neighborhood, setNeighborhood] = useState([]);
  const [cityZip, setCityZip] = useState([]);
  const [selectedZip, setSelectedZip] = useState("");
  useEffect(() => {
    localStorage.setItem("searchTerm", JSON.stringify(searchTerm));
  }, [searchTerm]);
  const handleZipChange = (event) => {
    setSelectedZip(event.target.value);
  };
  const handleClick = (button) => {
    setActiveButton(button);
  };
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getCity`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCities(data?.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const fetchCityZips = async (city) => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getZip`, {
        method: "POST",
        body: JSON.stringify({ city: selectedCity }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setCityZips(jsonData?.result);
      setCityZip(jsonData?.result?.zip);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchneigh = async (city) => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getNeighborhood`, {
        method: "POST",
        body: JSON.stringify({ city: selectedCity }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setNeighborhood(jsonData?.result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleChange = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");
    setLoader(false);
    setSearchTerm(sanitizedInput);
    if (value.length < 3) {
      setResults([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/search`, {
        method: "POST",

        body: JSON.stringify({
          keyword: searchTerm,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", 
        },
      });
      const data = await response.json();
      setResults(data.result);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleData = async (event) => {
    const value = event.target.value.replace(/[^\w\s]/gi, "");
    setLoader(false);
    setSearchCity(value);
    if (value.length < 3) {
      setSearchData([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getRestaurantByName`, {
        method: "POST",

        body: JSON.stringify({
          keyword: searchCity,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      setSearchData(data.result.restaurant);
      setLoader(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    fetchCityZips(event.target.value);
    fetchneigh(event.target.value);
  };
  return (
    <div className="">
      <div className="text-center py-3">
        <div className="   font-extrabold text-white  text-[30px]  ">
          Discover Local Restaurants Menus, Photos &
        </div>
        <div className="   font-extrabold text-white opacity-100 text-[30px]  ">
          Specials
        </div>
      </div>
      <div className="flex items-center justify-center gap-3 px-2">
        <button
          to="#"
          className={
            activeButton === "button1"
              ? "active btn btn-solid lg:px-3  lg:w-60 w-full font-bold h-12 px-1 text-[14px] "
              : "btn btn-solid bg-white lg:p-1 px-1 lg:w-60  w-full h-12 text-[#88001b] text-[14px] lg:font-bold md:text:sm"
          }
          onClick={() => handleClick("button1")}
        >
          NEAR ME
        </button>
        <button
          to="#"
          className={
            activeButton === "button2"
              ? "active btn btn-solid lg:px-3 lg:w-60 w-full  font-bold h-12 px-1 text-[14px]"
              : " btn btn-solid bg-white lg:p-1  lg:w-60 w-full h-12 px-1 text-[#88001b] text-[14px] lg:font-bold md:text:sm"
          }
          onClick={() => handleClick("button2")}
        >
          RESTAURANT NAME
        </button>
        <button
          to="#"
          className={
            activeButton === "button3"
              ? "active btn btn-solid lg:px-3 lg:w-60 w-full rounded font-bold h-12 px-1 text-[14px]"
              : "btn btn-solid bg-white lg:p-1 lg:w-60 w-full text-[#88001b] px-1 rounded h-12 text-[14px] lg:font-bold md:text:sm"
          }
          onClick={() => handleClick("button3")}
        >
          ZIP CODE OR NEIGHBORHOOD
        </button>
      </div>
      {activeButton === "button1" ? (
        <div className="  bg-white     border lg:w-[740px]  w-full lg:mx-auto rounded-md mt-4  ">
          <div className="">
            <div className="flex mt-5 items-center p-2 px-4">
              <div
                style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                className="h-12 w-16 border-black border-t border-b border-l justify-center items-center flex p-2"
              >
                <svg
                  fill="#88001b"
                  className="w-7 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
              </div>
              <input
                style={{
                  outline: "none",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }}
                type="text"
                value={searchTerm}
                onChange={handleChange}
                placeholder="Type Your Address Here"
                className="border border-black w-full p-1   h-12   "
              />
            </div>
            <ul className="scrollable-list">
              {results.length > 3 ? (
                <>
                  {results.map((result, index) => {
                    const modifiedPanelName = result.address
                      .replace(/\W/g, " ")
                      .toLowerCase();
                    const isMenuPage =
                      result.id !== undefined &&
                      result.id !== null &&
                      result.id.length > 1;
                    const handleClick = () => {
                      const cityName = result.rname.replace(/\s/g, "");
                      if (isMenuPage) {
                        navigate(`/menu/-${result.id}-${cityName}`);
                      } else {
                        navigate(`/searchCity/${modifiedPanelName}`);
                      }
                    };
                    return (
                      <div
                        onClick={handleClick}
                        className={`border-b mt-2 mb-2 slate-600 cursor-pointer ${
                          isMenuPage ? "menu-item" : "search-city-item"
                        }`}
                        key={index}
                      >
                        <li className="px-6 font-bold text-red-900" key={index}>
                          {result.rname}
                        </li>
                        <li className="px-6" key={index}>
                          {result.address}
                        </li>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </ul>
            <button
              onClick={() => {
                const cityName = searchTerm.replace(/\s/g, "");
                navigate(`/searchCity/${cityName}`);
              }}
              className="bg-[#990000] lg:w-[700px] w-full h-10 flex items-center lg:mx-auto justify-center mb-5   mt-2 "
            >
              {loader ? (
                <Vortex
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="vortex-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "red", "white", "green", "white", "orange"]}
                />
              ) : (
                <div className="text-white font-bold  text-xl md:lg">GO</div>
              )}
            </button>
          </div>
        </div>
      ) : null}
      {activeButton === "button2" ? (
        <div className="  bg-white rounded-md lg:w-[740px] justify-center hover:scale-105 transition duration-300 ease-in-out px-2 items-center  border  w-full mx-auto mt-4  ">
          <div className="">
            <div className="  items-center px-4 mt-5">
              <input
                type="text"
                value={searchCity}
                onChange={handleData}
                style={{
                  outline: "none",
                }}
                className="border p-2 rounded-md border-black w-full h-12  "
                placeholder="Type restaurant name"
              />
              <ul className="scrollable-list">
                {searchData.length > 3 ? (
                  <>
                    {searchData.map((pk, index) => (
                      <>
                        <ul
                          onClick={() => {
                            const modified = pk.rname.replace(/\s/g, "");
                            navigate(`/menu/-${pk.id}-${modified}`);
                          }}
                        >
                          <li
                            className="px-6  font-bold text-red-900 "
                            key={index}
                          >
                            {pk.rname}
                          </li>
                          <li className="px-6   text-slate-600 " key={index}>
                            {pk.address}
                          </li>
                        </ul>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
            <button className="bg-[#990000] lg:w-[700px] px-2 w-full h-10  flex items-center mb-5 justify-center  lg:mx-auto  mt-5 rounded-md">
              {loader ? (
                <Vortex
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="vortex-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "red", "white", "green", "white", "orange"]}
                />
              ) : (
                <div className="text-white  text-lg font-bold md:sm">
                  SEARCH
                </div>
              )}
            </button>
          </div>
        </div>
      ) : null}
      {activeButton === "button3" ? (
        <div className="   bg-white rounded-md justify-center flex  border lg:w-[740px] md:w-auto mx-auto mt-2  ">
          <div className="w-full mx-auto  justify-center items-center lg:p-5 p-3">
            <select
              value={selectedCity}
              onChange={handleCityChange}
              className="w-full px-4  h-8 bg-white border border-black rounded mt-3 mb-2 "
            >
              <option className="">SELECT CITY</option>

              {cities.map((panel) => {
                return (
                  <>
                    <option value={panel.id} className="px-2">
                      {panel.cityName.toUpperCase()}
                    </option>
                  </>
                );
              })}
            </select>
            <div>
              <select
                value={selectedZip}
                onChange={handleZipChange}
                onClick={fetchCityZips}
                className="w-full h-8 px-4 bg-white border border-black rounded mt-2"
              >
                <option className="">Select Zip</option>
                {cityZips.length == 0 ? (
                  <></>
                ) : (
                  <>
                    {cityZips.map((user) => {
                      return (
                        <>
                          <option className="font-medium">{user.zip}</option>
                        </>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            <div className="text-center font-bold text-1xl mt-2">OR</div>
            <div className="mt-2">
              <select
                onClick={fetchneigh}
                className="w-full h-8 px-4 bg-white border border-black rounded mt-2"
              >
                <option className="">Select Neighborhood</option>
                {neighborhood.length == 0 ? (
                  <></>
                ) : (
                  <>
                    {neighborhood.map((nei) => {
                      return (
                        <>
                          <option className="font-medium cursor-pointer">
                            {nei.neighborhood_name}
                          </option>
                        </>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
            <>
              <button
                onClick={() => {
                  const url = `/searchCity/${selectedZip}`;
                  navigate(url);
                }}
                className="bg-[#88001b] lg:w-[700px] w-full h-10 flex items-center  justify-center mx-auto  mt-5 "
              >
                {loader ? (
                  <Vortex
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={[
                      "white",
                      "red",
                      "white",
                      "green",
                      "white",
                      "orange",
                    ]}
                  />
                ) : (
                  <div className="text-white  text-lg font-bold ">SEARCH</div>
                )}
              </button>
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
}
