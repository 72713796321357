import React from "react";

export default function RealMenu() {
  return (
    <div>
      <div className="my-elementThree">
        <div className="grid lg:grid-cols-3  justify-center     ">
          <div className="text-center py-5">
            <div>
              <div className="font-bold text-3xl text-white">
                REAL MENU SCANS
              </div>
              <div className="mt-5">
                <div className="text-white ">LOOK UP REAL MENU SCANS</div>
                <div className="text-white">FROM NEARBY RESTAURANTS</div>
              </div>
            </div>
          </div>
          <div className="flex justify-center ">
            <div className="border-l lg:block hidden  border-white "></div>
          </div>
          <div className="text-center py-5  ">
            <div className="text-center">
              <div className="font-bold text-3xl  text-white">
                NYC's Local Restaurant
              </div>
              <div className="text-white mt-5 ">
                SEARCH ENGINE & DINING GUIDE
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
