import React, { useEffect, useState } from "react";
import Header from "../common/components/header";
import Modal from "react-modal";
import MainBottom from "../common/components/mainBottom";
import About from "../common/components/about";
import HeaderichmondAvenue from "../common/components/richmondAvenuePage/headerichmondAvenue";
import { useNavigate, useParams } from "react-router-dom";
import NoImage from "../img/noimage.jpg";
import { Helmet } from "react-helmet";
import { Vortex } from "react-loader-spinner";
import Star from "../img/Star.png";
import { BASE_URL, token } from "./token";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Restaurant() {
  const [click, setClick] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [resturantP, setResturantP] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isRestaurantPage, setIsRestaurantPage] = useState(false);
  const [cuisine, setCuisines] = useState([]);
  const [serviceShow, setServiceShow] = useState(false);
  const [service, setServices] = useState([]);
  const [feature, setFeature] = useState([]);
  const [featureShow, setFeatureShow] = useState(false);
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [cityZips, setCityZips] = useState([]);
  const [zip, setZip] = useState(false);
  const [loader, setLoader] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhoodShow, setNeighborhoodShow] = useState(false);
  const [resturantH, setResturantH] = useState([]);
  const [restaurant, setResturnat] = useState([]);
  const navigate = useNavigate();

  const zipToShow = zip ? cityZips : cityZips.slice(0, 10);
  const serviceToShow = serviceShow ? service : service.slice(0, 4);
  const featureToShow = featureShow ? feature : feature.slice(0, 4);
  const neighToShow = neighborhoodShow
    ? neighborhoods
    : neighborhoods.slice(0, 5);
  //slider
  const [currentSlide, setCurrentSlide] = useState(0);

  const imagesToShow = 3; // Number of images to show at a time

  // Configuration for the responsive carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3, // Number of items to show at this breakpoint
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show one item on mobile
    },
  };
  const handlePrevClick = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - imagesToShow);
    }
  };
  // Function to handle the next button click event
  const handleNextClick = () => {
    if (currentSlide + imagesToShow < searchData.length) {
      setCurrentSlide(currentSlide + imagesToShow);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let params = useParams();
  const modified = params?.modified;
  let split_data = modified.split("-");
  let cityName, neighborhood, city, id;
  if (split_data.length === 4) {
    neighborhood = split_data[0];
    city = split_data.slice(3).join("-").replace(/-/g, " ");
    id = split_data[split_data.length - 1];
  } else if (split_data.length > 4) {
    cityName = split_data[1];
    neighborhood = split_data[2];
    if (split_data.length >= 5) {
      city = split_data
        .slice(3, split_data.length - 1)
        .join("-")
        .replace(/-/g, " ");
    } else {
      city = split_data[3].replace(/-/g, " ");
    }
    id = split_data[split_data.length - 1];
  } else {
    console.log("this is error");
  }
  if (modified && neighborhood && city && id) {
    console.log("Modified:", modified);
    console.log("Neighborhood:", neighborhood);
    console.log("City:", city);
    console.log("ID:", id);
  } else {
    console.error("Missing or incomplete parameters.");
  }
  let cityNameId = 1;
  if (city === "staten island") {
    cityNameId = 1;
  } else if (city === "brooklyn") {
    cityNameId = 2;
  } else if (city === "queens") {
    cityNameId = 3;
  } else if (city === "the bronx") {
    cityNameId = 4;
  } else if (city === "manhattan") {
    cityNameId = 5;
  }
  const neighShowAll = () => {
    setNeighborhoodShow(!neighborhoodShow);
    setZip(false);
    setServiceShow(false);
    setFeatureShow(false);
  };
  const toggleShowAll = () => {
    setZip(!zip);
    setNeighborhoodShow(false);
    setServiceShow(false);
    setFeatureShow(false);
  };
  const toggleServiceSHow = () => {
    setServiceShow(!serviceShow);
    setZip(false);
    setNeighborhoodShow(false);
    setFeatureShow(false);
  };
  const toggleFeatureSHow = () => {
    setFeatureShow(!featureShow);
    setServiceShow(false);
    setZip(false);
    setNeighborhoodShow(false);
  };
  const [store, setStore] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleText = (e) => {
    setStore({ ...store, [e.target.name]: e.target.value });
  };
  const handleNum = async (event) => {
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/getRestaurantByAttribute`,
        {
          method: "POST",

          body: JSON.stringify({
            city: city,
            type: neighborhood,
            id: id,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Add any other headers you need
          },
        }
      );
      const data = await response.json();
      setIsRestaurantPage(true);
      setResturnat(data.result.restaurant);
      setResturantP(data.result.restaurantsP);
      setResturantH(data.result.restaurantsH);
      setSearchData(data.result.recentOpening);
      setLoader(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  //zip
  const fetchCityZips = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getZip`, {
        method: "POST",
        body: JSON.stringify({ city: cityNameId }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setCityZips(jsonData?.result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  //feature services
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: "all" }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCuisines(data?.result?.cusine);
        setServices(data?.result?.service);
        setFeature(data?.result?.feature);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //neighbarhood
  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getNeighborhood`, {
        method: "POST",
        body: JSON.stringify({ city: cityNameId }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setNeighborhoods(jsonData?.result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleNum();
    fetchData();
    fetchCityZips();
  });
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <div className="">
        <Helmet>
          <title>
            {isRestaurantPage
              ? `${id} ${city} | Take Out & Dine In Restaurant Guide`
              : `${cityName} Restaurants in ${city} | Openings & Menus`}
          </title>
        </Helmet>
        <Header />
        <HeaderichmondAvenue />
        <Modal
          isOpen={isopen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className=" lg:w-[600px] w-full px-2">
            <div className="flex  justify-center items-center ">
              <div className="text-2xl w-full  py-1 border-b rounded-md text-center px-2 text-black">
                Report Missing Restaurant
              </div>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
              >
                <svg
                  className="h-5 w-5 "
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="mt-2">Email Address *</div>
            <input
              name="email"
              value={store.email}
              onChange={handleText}
              className="w-full mt-2 border border-black p-2 rounded-md"
              placeholder="email"
            />
            {click && store.message == "" ? (
              <div className="text-red-600 ">Required to fill email id</div>
            ) : null}
            <div className="mt-2">Restaurant Name *</div>
            <input
              name=""
              value={store.resturant}
              onChange={handleText}
              type="phone"
              className=" no-spinner w-full mt-2 border border-black p-2 rounded-md"
              placeholder="Phone Number"
            />
            {click && store.phone == "" ? (
              <div className="text-red-600 ">
                Required to fill resturant name
              </div>
            ) : null}
            <div className="mt-2">Message: *</div>
            <textarea
              name="message"
              value={store.message}
              onChange={handleText}
              className="w-full h-24 border border-black p-2 mt-2 rounded-md"
              placeholder="write your message"
            />
            {click && store.message == "" ? (
              <div className="text-red-600 ">Required to fill message</div>
            ) : null}
            <button className="w-full mt-2 p-2 bg-red-900 rounded-md text-white">
              Send
            </button>
          </div>
        </Modal>
        {/* <div className="px-2 mt-2 ">
          <div className="container  px:4 lg:w-[1170px] flex items-center  gap-2 mx-auto  ">
            <div>
              <svg
                fill="#990000"
                className="w-7 h-7 mb-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
            </div>
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className="text-base cursor-pointer capitalize text-[#88001b] font-semibold mb-2"
            >
              {cityName}
            </div>
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className=" mb-2"
            >
              (Edit)
            </button>
          </div>
        </div> */}
        <div className="border border-red-900"></div>
        <div className="my-elements">
          <div className="lg:flex bg-[#f0f0f0]  container mx-auto  lg:w-[1170px] px-2 py-2 gap-5">
            <div className="lg:w-[350px]  hidden lg:block   border mb-2    mx-auto container    w-ful  px-2 py-4 rounded-sm">
              <div className=" ">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <div
                      // onClick={() => {
                      //   const modifi = city.replace(/\s/g, "");
                      //   const modified = "cuisine"
                      //     .replace(/\s/g, "")
                      //     .toLowerCase();
                      //   const city = cityName.replace(/\s/g, "").toLowerCase();
                      //   const url = `/cuisine/-${modifi}-${modified}-${city}-${id}`;
                      //   navigate(url);
                      // }}
                      className="underline capitalize text-red-900"
                    >
                      {city}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div>
                      <svg
                        fill="#88001b"
                        className="w-2 h-2"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 320 512"
                      >
                        <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                      </svg>
                    </div>
                    <div className="text-red-900 cursor-pointer hover:underline">
                      {cityName}
                    </div>
                  </div>
                </div>
                <div className="bg-white py-2 px-2 mt-5">
                  <div className="flex justify-between  items-center shadow-2xl mt-4 ">
                    <div className="font-bold text-xl text-red-900 ">
                      Features
                    </div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className=" gap-2 mt-4 items-center">
                    {featureToShow.length > 0 ? (
                      <>
                        {featureToShow.map((feat) => (
                          <div
                            className="flex items-center mb-2"
                            key={feature.id}
                          >
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {feat.feature_name}
                            </div>
                          </div>
                        ))}
                        <div className="">
                          {featureShow ? (
                            <>
                              <button
                                onClick={toggleFeatureSHow}
                                className="mt-2 underline "
                              >
                                Hide more...
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={toggleFeatureSHow}
                              className="mt-2 "
                            >
                              load more...
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="bg-white mt-1 mb-2 py-2 px-2">
                  <div className="flex justify-between  items-center">
                    <div className="font-bold text-xl text-red-900 ">
                      Services
                    </div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className="gap-2 mt-4 items-center">
                    {serviceToShow.length > 0 ? (
                      <>
                        {serviceToShow.map((service) => (
                          <div
                            className="flex items-center mb-2"
                            key={service.id}
                          >
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {service.service_name}
                            </div>
                          </div>
                        ))}
                        {serviceShow ? (
                          <>
                            <button
                              onClick={toggleServiceSHow}
                              className="mt-2 underline "
                            >
                              Hide more...
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={toggleServiceSHow}
                              className="mt-2"
                            >
                              load more...
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="bg-white px-2 mb-2">
                  <div className="flex  justify-between items-center">
                    <div className="font-bold text-xl text-red-900 ">
                      Neighborhood
                    </div>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className="gap-2 mt-4 items-center">
                    {neighToShow.length > 0 ? (
                      <>
                        {neighToShow.map((neig) => (
                          <div className="flex items-center mb-2" key={neig.id}>
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {neig.neighborhood_name}
                            </div>
                          </div>
                        ))}
                        {zip ? (
                          <>
                            <button
                              onClick={neighShowAll}
                              className="mt-2 underline "
                            >
                              Hide more...
                            </button>
                          </>
                        ) : (
                          <>
                            <button onClick={toggleShowAll} className="mt-2 ">
                              load more...
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="bg-white px-2">
                  <div className="flex  justify-between items-center">
                    <div className="font-bold text-xl text-red-900 ">Zip</div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className="mt-2 mb-4 items-center">
                    {zipToShow.length > 0 ? (
                      <>
                        {zipToShow.map((zips) => (
                          <div className="flex mb-2 items-center" key={zips.id}>
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {zips.zip}
                            </div>
                          </div>
                        ))}
                        {zip ? (
                          <>
                            <button
                              onClick={toggleShowAll}
                              className="mt-2 underline "
                            >
                              Hide more...
                            </button>
                          </>
                        ) : (
                          <>
                            <button onClick={toggleShowAll} className="mt-2 ">
                              load more...
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="  lg:px-4   lg:w-[850px]">
                <div className="flex justify-between items-center">
                  <div className="font-bold text-xl ">Recent Openings</div>
                  <div className="  ">
                    <button onClick={handlePrevClick} className=" ">
                      <svg
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                        height="3em"
                        viewBox="0 0 256 512"
                      >
                        <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                      </svg>
                    </button>
                    <button onClick={handleNextClick} className="">
                      <svg
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                        height="3em"
                        viewBox="0 0 256 512"
                      >
                        <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                      </svg>
                    </button>
                  </div>
                </div>

                <Carousel
                  responsive={responsive}
                  swipeable={true} 
                  draggable={true}
                  customTransition="transform 300ms ease-in-out" // Adjust transition speed and easing
                 
                >
                  {searchData.map((near, index) => (
                    <div
                      key={index}
                      style={{
                        display:
                          index >= currentSlide &&
                          index < currentSlide + imagesToShow
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="gap-6 px-2 flex relative cursor-pointer border  py-2 shadow-lg">
                        <div
                          onClick={() => {
                            const modifiedPanelName = near.rname
                              .replace(/\W/g, "")
                              .toLowerCase();
                            navigate(`/menu/-${near.id}-${modifiedPanelName}`);
                          }}
                        >
                          <div className="absolute bottom-36 right-2 ">
                            {(() => {
                              const dateObj = new Date(near.rdate);
                              return isNaN(dateObj) ? (
                                <p>OPENED: Invalid Date</p>
                              ) : (
                                <div className="bg-[#88001b] text-white  font-semibold px-2">
                                  OPENED:
                                  {dateObj.toLocaleString("default", {
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </div>
                              );
                            })()}
                          </div>
                          {near.image ? (
                            <div className="flex">
                              <img
                                className="h-[170px] lg:w-[306px] object-cover"
                                src={near.image}
                                alt={near.rname}
                              />
                            </div>
                          ) : (
                            <div className="flex relative">
                              <img
                                className="h-[170px] lg:w-[306px]"
                                src={NoImage}
                                alt="No Image Available"
                              />
                            </div>
                          )}
                          
                            <div className="font-medium text-[19px] text-xl mt-4">
                              {near.rname}
                            </div>
                            <div className="text-[#5a5a5a] text-[14px] font-light">
                              {near.address}
                            </div>
                          
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="py-10">
                {loader ? (
                  <>
                    <div className="grid lg:grid-cols-2 gap-5 mt-2   grid-cols-1   w-full  ">
                      {resturantP.length > 0 ? (
                        <>
                          {resturantP.map((pan) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modifiedPanelName = pan.rname
                                      .replace(/\W/g, "")
                                      .toLowerCase();
                                    navigate(
                                      `/menu/-${pan.id}-${modifiedPanelName}`
                                    );
                                  }}
                                  className="flex cursor-pointer border border-red-900 hover:bg-white   rounded-md  "
                                >
                                  {pan.image.length > 0 ? (
                                    <>
                                      <img
                                        className="lg:h-[170px] lg:w-[200px] rounded  h-full  max-h-48 "
                                        src={pan.image}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="lg:h-[170px] rounded  h-full max-h-48"
                                        src={NoImage}
                                      />
                                    </>
                                  )}
                                  <div className="ml-4">
                                    <div className="font-normal text text-red-900">
                                      {pan.rname}
                                    </div>
                                    <div className="text-[#989898] text-[13px]">
                                      {pan.address}
                                    </div>
                                    <div className="font-bold text-[15px]">
                                      {pan.cuisine}
                                    </div>
                                    <div className="flex justify-end">
                                      <div></div>
                                      <img src={pan.openClose} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : null}
                      {resturantH.length > 0 ? (
                        <>
                          {resturantH.map((resh) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modifiedPanelName = resh.rname
                                      .replace(/\W/g, "")
                                      .toLowerCase();
                                    navigate(
                                      `/menu/-${resh.id}-${modifiedPanelName}`
                                    );
                                  }}
                                  className="flex cursor-pointer  hover:bg-white   rounded-md  "
                                >
                                  <img
                                    className="absolute h-10 w-10 "
                                    src={Star}
                                  />
                                  {resh.image.length > 0 ? (
                                    <>
                                      <img
                                        className="lg:h-[170px] lg:w-[200px]  h-full  max-h-48 "
                                        src={resh.image}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="lg:h-[170px]   h-full max-h-48"
                                        src={NoImage}
                                      />
                                    </>
                                  )}
                                  <div className="ml-4">
                                    <div className="font-normal text text-red-900">
                                      {resh.rname}
                                    </div>
                                    <div className="text-[#989898] text-[13px]">
                                      {resh.address}
                                    </div>
                                    <div className="font-bold text-[15px]">
                                      {resh.cuisine}
                                    </div>
                                    <div className="flex justify-end">
                                      <div></div>
                                      <img src={resh.openClose} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : null}
                      {restaurant.length > 0 ? (
                        <>
                          {restaurant.map((res) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modifiedPanelName = res.rname
                                      .replace(/\W/g, "")
                                      .toLowerCase();
                                    navigate(
                                      `/menu/-${res.id}-${modifiedPanelName}`
                                    );
                                  }}
                                  className="flex cursor-pointer  hover:bg-white   rounded-md  "
                                >
                                  {res.image.length > 0 ? (
                                    <>
                                      <img
                                        className="lg:h-[170px] lg:w-[200px]  h-full  max-h-48 "
                                        src={res.image}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="lg:h-[170px]   h-full max-h-48"
                                        src={NoImage}
                                      />
                                    </>
                                  )}
                                  <div className="ml-4">
                                    <div className="font-normal text text-red-900">
                                      {res.rname}
                                    </div>
                                    <div className="text-[#989898] text-[13px]">
                                      {res.address}
                                    </div>
                                    <div className="font-bold text-[15px]">
                                      {res.cuisine}
                                    </div>
                                    <div className="flex justify-end">
                                      <div></div>
                                      <img src={res.openClose} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-2xl text-red-900 text-center  mt-3">
                            No result found
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center">
                      <Vortex
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="vortex-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={[
                          "white",
                          "red",
                          "white",
                          "green",
                          "white",
                          "orange",
                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <MainBottom headingText="Search for Restaurants Near You" />
        <About />
      </div>
    </>
  );
}
