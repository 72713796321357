import React from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function InfoRestaurant() {
  return (
    <div className="">
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto lg:px-24 p-2 ">
        <div className="text-xl mt-10 leading-1 ">Info for Restaurants</div>

        <div className="font-bold text-3xl">
          Make Changes or Update Your listing on WhereYouEat!
        </div>
        <div className="">
          <div className=" text-xl ">
            WhereYouEat is the only independent local dining guide serving NYC,
            If your restaurant has any information that needs to be corrected
            Give us a call at 718-878-6758
          </div>
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
