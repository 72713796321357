import React, { useEffect, useState } from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";
import { Vortex } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BASE_URL, token } from "./token";

export default function ContactPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [click, setClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState([]);
  const [store, setStore] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
    number: "",
  });
  const handleText = (e) => {
    setStore({ ...store, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    setClick(true);
    e.preventDefault();
    if (parseInt(store) === num1 + num2) {
      alert("Captcha verification successful!");
    } else {
      alert("Incorrect answer. Please try again.");
      setNum1(Math.floor(Math.random() * 10));
      setNum2(Math.floor(Math.random() * 10));
      setStore("");
    }
  };
  const ValidateEmail = () => {
    setClick(true);
    var validRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (validRegex.test(store.email)) {
      return true;
    } else setClick(true);
    {
      return false;
    }
  };
  //api fetch
  const fetchFormData = () => {
    ValidateEmail();
    fetch(`${BASE_URL}/wye-api/contactForm`, {
      method: "POST",
      body: JSON.stringify({
        ...store,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Add any other headers you need
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(data.message);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoader(false);
      });
  };
  return (
    <div>
      <Header />
      <div className="border-t-2 border-[#990000] "></div>
      <div className="w-full lg:w-[800px] py-5 mb-5 container shadow-2xl rounded-md mt-4 mx-auto border px-4">
       <div className="text-center bg-green-300 w-full">
       {message}
       </div>
        <div className="text-3xl mt-2 font-bold text-center">Contact Form</div>
        <div className="flex  mb-3"></div>
        <div className="flex  mb-3 ">
          <div className="w-full px-3">
            <label className="block tracking-wide   text-lg font-bold mb-2">
              First Name
            </label>
            <input
              className="appearance-none block w-full  border border-black rounded p-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="fname"
              name="fname"
              value={store.fname}
              onChange={handleText}
              placeholder="Please enter your firstname"
            />
            {click && store.fname === "" ? (
              <div className="text-red-600 ">Required to fill firstName</div>
            ) : null}
          </div>
        </div>
        <div className="flex  mb-3">
          <div className="w-full px-3">
            <label className="block  tracking-wide  text-lg font-bold mb-2">
              LastName
            </label>
            <input
              name="lname"
              value={store.lname}
              onChange={handleText}
              className="appearance-none block w-full  border border-black rounded p-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="lname"
              placeholder="Please enter your last name"
            />
            {click && store.lname === "" ? (
              <div className="text-red-600 ">Required to fill lastName</div>
            ) : null}
          </div>
        </div>
        <div className="flex  mb-3">
          <div className="w-full px-3">
            <label className="block  tracking-wide  text-lg font-bold mb-2">
              Email
            </label>
            <input
              name="email"
              value={store.email}
              onChange={handleText}
              className="appearance-none block w-full  border border-black rounded p-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="email"
              placeholder="Please enter your email"
            />
            {click && store.email === "" ? (
              <div className="text-red-600 ">Required to fill email</div>
            ) : null}
          </div>
        </div>
        <div className="flex  mb-3">
          <div className="w-full px-3">
            <label className="block  tracking-wide  text-lg font-bold mb-2">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              value={store.phone}
              onChange={handleText}
              className="appearance-none no-spinner block w-full  border border-black rounded p-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="phone"
              placeholder="Please enter your phone number"
            />
            {click && store.phone === "" ? (
              <div className="text-red-600 ">Required to fill phone Number</div>
            ) : null}
          </div>
        </div>
        <div className="flex  mb-3">
          <div className="w-full px-3">
            <label className="block  tracking-wide  text-lg font-bold mb-2">
              Message
            </label>
            <textarea
              name="message"
              value={store.message}
              onChange={handleText}
              className="appearance-none block w-full h-40  border border-black rounded p-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="message"
              placeholder="Message for me"
            />
            {click && store.message === "" ? (
              <div className="text-red-600 ">Required to fill message</div>
            ) : null}
          </div>
        </div>
        <div className="mb-2 flex items-center">
          <h2 className="font-bold mr-2">Captcha </h2>
          <form>
            <div className="flex">
              <p>
                {num1} + {num2} =
              </p>
              <input
                className="border text-center ml-2 w-14 border-black"
                type=""
                name="number"
                value={store.number}
                onChange={handleText}
              />
              {click && store.number === "" ? (
                <div className="text-red-600 ml-2 ">
                  Required to fill Captcha
                </div>
              ) : null}
            </div>
          </form>
        </div>
        <button className="bg-[#990000] flex justify-center items-center w-full hover:bg-slate-600 ">
          {loader ? (
            <>
              <Vortex
                visible={true}
                height="40"
                width="40"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["white", "red", "white", "green", "white", "orange"]}
              />
            </>
          ) : (
            <>
              <div
                onClick={fetchFormData}
                className="text-center cursor-pointer font-bold text-1xl p-2 text-white"
              >
                Send Message
              </div>
            </>
          )}
        </button>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
