import React, { useEffect, useState } from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import HeaderichmondAvenue from "../common/components/richmondAvenuePage/headerichmondAvenue";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainBottom from "../common/components/mainBottom";
import Cuisine from "../common/components/richmondAvenuePage/cuisine";
import { Vortex } from "react-loader-spinner";
import NoImage from "../img/noimage.jpg";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { BASE_URL, MapUrl, token } from "./token";
import MapData from "../common/components/mapData";

export default function CityPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  //usestate
  const [activeButton, setActiveButton] = useState("button1");
  const [loader, setLoader] = useState(false);
  const [dataShow, setDataShow] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);
  const [cityZips, setCityZips] = useState([]);
  const [active, setActive] = useState(false);
  const [zipActive, setZipActive] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showData, setShowData] = useState(false);
  const [neighActive, setNeighActive] = useState(false);
  const [news, setNews] = useState([]);
  const [popular, setPopular] = useState([]);
  const [review, setReview] = useState([]);
  const [isopen, setIsOpen] = useState(false);
  const handleClick = (button) => {
    setActiveButton(button);
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    window.location.href = selectedOption;
  };
  let params = useParams();
  const cityName = params?.cityName;
  localStorage.setItem("cityName", cityName);
  localStorage.getItem("cityName", cityName);
  let cityNameId = 0;
  if (params.cityName === "staten-island") {
    cityNameId = 1;
  } else if (params.cityName === "brooklyn") {
    cityNameId = 2;
  } else if (params.cityName === "queens") {
    cityNameId = 3;
  } else if (params.cityName === "the-bronx") {
    cityNameId = 4;
  } else if (params.cityName === "manhattan") {
    cityNameId = 5;
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const service = () => {
    fetchUser();
    setShowText(false);
    setShowData(!showData);
    setZipActive(!false);
    setNeighActive(false);
  };
  const feature = () => {
    fetchUser();
    setShowText(!showText);
    setShowData(false);
    setZipActive(!false);
    setNeighActive(false);
    // setActive(!active);
  };
  const capitalizedCityName =
    cityName.charAt(0).toUpperCase() + cityName.slice(1);
  const fetchUser = () => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: cityName }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDataShow(data?.result?.feature);
        setServiceData(data?.result?.service);
        setZipActive(false);
        setNeighActive(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getNeighborhood`, {
        method: "POST",
        body: JSON.stringify({ city: cityNameId }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setNeighborhood(jsonData?.result);
      // setActiveTwo(true);
      setZipActive(false);
      setShowText(false);
      setShowData(false);
      setActive(false);
      setNeighActive(!neighActive);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchCityZips = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getZip`, {
        method: "POST",
        body: JSON.stringify({ city: cityNameId }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setCityZips(jsonData?.result);
      // setActiveOne(true);
      setZipActive(!zipActive);
      setNeighActive(false);
      setActive(false);
      setShowText(false);
      setShowData(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const CityRestaurants = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getCityRestaurants`, {
        method: "POST",
        body: JSON.stringify({ city: cityName }),

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setLoader(true);
      setNews(jsonData?.result.whatsNew);
      setReview(jsonData?.result.reviews);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const CityRest = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/getCityRestaurantsPopular`,
        {
          method: "POST",
          body: JSON.stringify({ city: cityName }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Add any other headers you need
          },
        }
      );
      const jsonData = await response.json();
      setLoader(true);
      setPopular(jsonData?.result.popular);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    CityRestaurants();
  }, []);
  return (
    <>
      <Helmet>
        <title className="capitalize">
          {capitalizedCityName} Restaurant Menus / Openings - WhereYouEat.com
        </title>
        <meta name="returant" content="resturant" />
      </Helmet>
      <Modal isOpen={isopen} style={customStyles} contentLabel="Example Modal">
        <div className="bg-red-900 w-full flex justify-between items-center px-2 lg:w-[200px]">
          <div className="font-bold p-2 text-white">Select City</div>
          <svg
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div className="flex justify-center items-center">
          <select
            value={cityName}
            className="mt-4 border-black border p-2"
            onChange={handleSelectChange}
          >
            <option value="https://reactjs.orderingspace.net/city/staten-island">
              Staten Island
            </option>
            <option value="https://reactjs.orderingspace.net/city/brooklyn">
              Brooklyn
            </option>
            <option value="https://reactjs.orderingspace.net/city/queens">
              Queens
            </option>
            <option value="https://reactjs.orderingspace.net/city/the-bronx">
              The Bronx
            </option>
            <option value="https://reactjs.orderingspace.net/city/manhattan">
              Manhattan
            </option>
          </select>
        </div>
      </Modal>
      <Header />
      <HeaderichmondAvenue />
      <div className="px-2    mt-2 ">
        <div className="container  px:4 lg:w-[1170px] flex items-center  gap-2 mx-auto  ">
          <div>
            <svg
              fill="#990000"
              className="w-7 h-7 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
            </svg>
          </div>
          <div
            onClick={() => {
              setIsOpen(true);
            }}
            className="text-base cursor-pointer capitalize text-[#88001b] font-semibold mb-2"
          >
            {cityName}
          </div>
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className=" mb-2"
          >
            (Edit)
          </button>
        </div>
      </div>
      <div className="border-t-2 border-[#990000]"></div>
      <div className="">
        <div className="  ">
          <div className=" lg:w-[1170px]  container mx-auto grid md:grid-cols-2 grid-cols-1 gap-10   w-full justify-center  mt-2">
            <div className=" justify-center items-center  ">
              <div className="font-bold text-2xl text-center ">
                Popular Search
              </div>
              {cityName == "staten-island" ? (
                <>
                  <div className="bg-[#88001b] p-1 flex justify-center items-center mt-5">
                    <Link
                      to="http://reactjs.orderingspace.net/cuisine/-BYOB-feature-staten-island-7"
                      className="text-white text-center font-semibold text-sm"
                    >
                      DINE IN SPECIALS
                    </Link>
                  </div>
                  <button
                    onClick={() => {
                      navigate("/newOpening");
                    }}
                    className="bg-[#88001b] w-full mt-3 p-1"
                  >
                    <div className="text-white text-center font-semibold text-sm ">
                      NEW OPENINGS
                    </div>
                  </button>
                  <div className="bg-[#88001b] w-full mt-3 p-1">
                    <Link to="https://reactjs.orderingspace.net/cuisine/-DineInDinnerSpecials-feature-staten-island-7">
                      <div className="text-white text-center font-semibold text-sm">
                        SPECIALTY CATERING
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <> </>
              )}
              {cityName == "brooklyn" ? (
                <>
                  <div className="bg-[#990000] p-1 flex justify-center items-center mt-5">
                    <Link
                      to="http://reactjs.orderingspace.net/cuisine/-BYOB-feature-staten-island-7"
                      className="text-white text-center font-semibold"
                    >
                      DINE IN SPECIALS
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="grid grid-cols-2  justify-center gap-2 items-center mt-4 px-2">
                <Link
                  to="https://reactjs.orderingspace.net/cuisine/-Italian-cuisine-staten-island-37"
                  className="border p-2 border-black text-center text-sm"
                >
                  ITALIAN
                </Link>
                <Link
                  to="https://reactjs.orderingspace.net/cuisine/-OutdoorDining-feature-staten-island-15"
                  className="border border-black text-center p-2 text-sm"
                >
                  OUTDOOR DINNING
                </Link>
              </div>
              <div className="grid grid-cols-2 gap-2 justify-center items-center mt-4 px-2">
                <Link
                  to="https://reactjs.orderingspace.net/cuisine/-Sushi-cuisine-staten-island-84"
                  className="border border-black p-2 text-center text-sm"
                >
                  SUSHI CUISIN
                </Link>
                <Link
                  to="https://reactjs.orderingspace.net/cuisine/-Pizzeria-cuisine-queens-50"
                  className="border p-2 border-black text-center text-sm"
                >
                  PIZZA CUISINE SEARCH
                </Link>
              </div>
              <MapData />
              <Cuisine />
              {zipActive ? (
                <>
                  <div
                    onClick={fetchCityZips}
                    className="flex cursor-pointer bg-black hover:bg-[#990000]   justify-between p-2 mt-10"
                  >
                    <div className="text-white ">Search by ZIP</div>
                    <svg
                      fill="white"
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </div>
                  <div className="grid grid-cols-2">
                    {cityZips.length > 0 ? (
                      <>
                        {cityZips.map((zips) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  const modified = "zip".replace(/\s/g, "");
                                  const city = "".replace(/\s/g, "");

                                  const url = `/cuisine/-${city}-${modified}-${cityName}-${zips.zip}`;
                                  navigate(url);
                                }}
                                className="px-4 cursor-pointer"
                              >
                                {zips.zip}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={fetchCityZips}
                    className="flex cursor-pointer text-sm bg-black hover:bg-[#990000]   justify-between p-2 mt-10"
                  >
                    <div className="text-white ">Search by ZIP</div>
                    <svg
                      className="w-5 h-5 "
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </div>
                </>
              )}
              {neighActive ? (
                <>
                  <div
                    onClick={fetchData}
                    className="flex cursor-pointer hover:bg-[#990000] bg-black p-2 justify-between mt-2"
                  >
                    <div className="text-white text-sm ">
                      Search by NEIGHBORHOOD
                    </div>
                    <svg
                      fill="white"
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </div>
                  <div className="grid grid-cols-2">
                    {neighborhood.length > 0 ? (
                      <>
                        {neighborhood.map((neigh) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  const modified =
                                    neigh.neighborhood_name.replace(/\s/g, "");
                                  const url = `/cuisine/-${modified}-neighborhood-${cityName}-${neigh.id}`;

                                  navigate(url);
                                }}
                                className="px-4 cursor-pointer"
                              >
                                {neigh.neighborhood_name}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={fetchData}
                    className="flex cursor-pointer hover:bg-[#990000] bg-black p-2 justify-between mt-2"
                  >
                    <div className="text-white text-sm ">
                      Search by NEIGHBORHOOD
                    </div>
                    <svg
                      className="w-5 h-5 "
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </div>
                </>
              )}
              <div>
                {showText ? (
                  <div>
                    <div
                      onClick={feature}
                      className="flex bg-black p-2 hover:bg-[#990000] justify-between mt-2"
                    >
                      <div className="text-white text-sm ">
                        Search by FEATURES
                      </div>
                      <svg
                        fill="white"
                        className="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                      </svg>
                    </div>
                    <div className="grid grid-cols-2">
                      {dataShow.map((use) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                const modified = use.feature_name.replace(
                                  /\s/g,
                                  ""
                                );

                                const url = `/cuisine/-${modified}-feature-${cityName}-${use.id}`;

                                navigate(url);
                              }}
                              className="px-4 cursor-pointer py-1"
                            >
                              {use.feature_name}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={feature}
                    className="flex bg-black p-2 cursor-pointer hover:bg-[#990000] justify-between mt-2"
                  >
                    <div className="text-white  text-sm">
                      Search by FEATURES
                    </div>
                    <svg
                      className="w-5 h-5 "
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </div>
                )}
              </div>
              {showData ? (
                <>
                  <div
                    onClick={service}
                    className="flex hover:bg-[#990000] cursor-pointer bg-black p-2 justify-between mt-2"
                  >
                    <div className="text-white text-sm ">
                      Search by SERVICES
                    </div>

                    <svg
                      fill="white"
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                  </div>

                  <div className="grid grid-cols-2">
                    {serviceData.map((user) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              const modified = user.service_name.replace(
                                /\s/g,
                                ""
                              );

                              const url = `/cuisine/-${modified}-service-${cityName}-${user.id}`;

                              navigate(url);
                            }}
                            className="px-4 cursor-pointer py-1"
                          >
                            {user.service_name}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={service}
                    className="flex hover:bg-[#990000] cursor-pointer bg-black p-2 justify-between mt-2"
                  >
                    <div className="text-white text-sm ">
                      Search by SERVICES
                    </div>
                    <svg
                      className="w-5 h-5 "
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </div>
                </>
              )}
            </div>
            <div className=" w-full">
              <div className="grid grid-cols-2  ">
                <div>
                  <button
                    to="#"
                    className={
                      activeButton === "button1"
                        ? "activeOne  btn btn-solid  font-bold  w-full p-2 "
                        : "btn btn-solid font-bold w-full bg-[#e0e0e0]  p-2 text-orange-900"
                    }
                    onClick={() => handleClick("button1")}
                  >
                    What's New
                  </button>
                </div>
                <div>
                  <button
                    to="#"
                    className={
                      activeButton === "button2"
                        ? "activeOne btn btn-solid font-bold  w-full p-2  "
                        : " btn btn-solid font-bold w-full bg-[#e0e0e0]  p-2 text-orange-900"
                    }
                    onClick={() => handleClick("button2") || CityRest()}
                  >
                    Popular Places
                  </button>
                </div>
              </div>
              <div>
                {activeButton === "button1" ? (
                  <>
                    {loader ? (
                      <>
                        <div className="grid lg:grid-cols-2 gap-5   ">
                          {news.length == 0 ? (
                            <>
                              <div className="text-2xl font-normal  ml-3 text-red-600">
                                No Restaurant Found
                              </div>
                            </>
                          ) : (
                            <>
                              {news.map((what) => {
                                return (
                                  <>
                                    <button
                                      onClick={() => {
                                        const modifiedPanelName =
                                          what.rname.replace(/\s/g, "");
                                        navigate(
                                          `/menu/-${what.id}-${modifiedPanelName}`
                                        );
                                      }}
                                      className=" "
                                    >
                                      <div>
                                        {what.image ? (
                                          <>
                                            <img
                                              className="w-full lg:h-[180px] h-[160px] "
                                              src={what.image}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="w-full lg:h-[180px] h-[160px] "
                                              src={NoImage}
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="p-2 text-left">
                                        <div className="font-semibold">
                                          {what.rname}
                                        </div>
                                        <div className="">{what.address}</div>
                                        <div className="font-bold text-[#ee9e4d]">
                                          OPENED:{" "}
                                          {new Date(
                                            what.rdate
                                          ).toLocaleDateString("en-US", {
                                            month: "long",
                                            year: "numeric",
                                          })}
                                        </div>
                                        <div>{what.neighborhood_name}</div>
                                        <div>{what.cuisine}</div>
                                      </div>
                                    </button>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center items-center">
                          <Vortex
                            visible={true}
                            height="60"
                            width="60"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={[
                              "white",
                              "red",
                              "orange",
                              "green",
                              "yellow",
                              "orange",
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                {activeButton === "button2" ? (
                  <>
                    {popular.length > 0 ? (
                      <>
                        <div className="grid lg:grid-cols-2 gap-5 py-2 ">
                          {popular.map((pop) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    const modifiedPanelName = pop.rname.replace(
                                      /\s/g,
                                      ""
                                    );
                                    navigate(
                                      `/menu/-${pop.id}-${modifiedPanelName}`
                                    );
                                  }}
                                  className=" cursor-pointer "
                                >
                                  {pop.image ? (
                                    <>
                                      <img
                                        className="w-full lg:h-[180px] h-[160px] "
                                        src={pop.image}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="w-full lg:h-[180px] h-[160px] "
                                        src={NoImage}
                                      />
                                    </>
                                  )}
                                  <div className="p-2 text-left">
                                    <div className="font-semibold">
                                      {pop.rname}
                                    </div>
                                    <div>{pop.address}</div>
                                    <div className="font-bold text-[#ee9e4d]">
                                      OPENED:{" "}
                                      {new Date(pop.rdate).toLocaleDateString(
                                        "en-US",
                                        {
                                          month: "long",
                                          year: "numeric",
                                        }
                                      )}
                                    </div>
                                    <div>{pop.neighborhood_name}</div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-2xl ml-3 font-normal text-red-600">
                          No Restaurant Found
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </div>
              <div className="bg-black mt-3 ">
                <div className="text-white font-bold text-center p-2  uppercase">
                  {cityName} BEST
                </div>
              </div>
              <div className="grid lg:grid-cols-2 mt-2 ml-3">
                <div className="text-red-600 text-2xl font-normal">
                  No Restaurant found
                </div>
              </div>
              <div className="bg-black mt-3">
                <div className="text-white font-bold text-center p-2">
                  Latest Reviews
                </div>
              </div>
              {loader ? (
                <>
                  {review.length === 0 ? (
                    <>
                      <div className=" text-2xl  text-red-600">
                        No Restaurant Found
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="grid lg:grid-cols-2 gap-5 py-2 ">
                        {review.map((review) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  const modifiedPanelName =
                                    review.rname.replace(/\s/g, "-");
                                  navigate(
                                    `/menu/-${review.id}-${modifiedPanelName}`
                                  );
                                }}
                                className=" cursor-pointer  "
                              >
                                {review.image ? (
                                  <>
                                    <img
                                      className="w-full lg:h-[180px] h-[160px]"
                                      src={review.image}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      className="w-full lg:h-[180px] h-[160px]"
                                      src={NoImage}
                                    />
                                  </>
                                )}
                                <div className="p-2">
                                  <div className="font-semibold">
                                    {review.rname}
                                  </div>
                                  <div className=" flex gap-2">
                                    {review.rating.length > 0 ? (
                                      <>
                                        {review.rating ? (
                                          Array.from(
                                            { length: parseInt(review.rating) },
                                            (_, index) => (
                                              <div
                                                className="bg-[#ee9e4d] h-5 w-5  flex items-center p-1  rounded-full"
                                                key={index}
                                              >
                                                <svg
                                                  fill="white"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  height="1em"
                                                  viewBox="0 0 576 512"
                                                >
                                                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                                </svg>
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="font semibold">
                                          <svg
                                            fill="white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 576 512"
                                          >
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                          </svg>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {(() => {
                                      const words = review.review.split(" ");
                                      const wordLimit = 9;
                                      const truncatedReview = words
                                        .slice(0, wordLimit)
                                        .join(" ");
                                      return <div>{truncatedReview}...</div>;
                                    })()}
                                  </div>
                                  <div>
                                    <div className="font-semibold text-red-900">
                                      Read Full Review
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="flex justify-center items-center">
                    <Vortex
                      visible={true}
                      height="60"
                      width="60"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={[
                        "white",
                        "red",
                        "orange",
                        "green",
                        "yellow",
                        "orange",
                      ]}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </>
  );
}
