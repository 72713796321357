import React, { useEffect, useState } from "react";
import { navigate, useNavigate, useParams } from "react-router-dom";
import { BASE_URL, token } from "../../../component/token";


export default function RichmondAvenueHeader() {
  const navigate=useNavigate()
  const [neighborhood, setNeighborhood] = useState([]);
  const [cityZips, setCityZips] = useState([]);
  const [news, setNews] = useState([]);
  const [review, setReview] = useState([]);
  const CityRestaurants = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}wye-api/getCityRestaurants`,
        {
          method: "POST",
          body: JSON.stringify({ city: cityName }),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Add any other headers you need
          },
        }
      );
      const jsonData = await response.json();
      setNews(jsonData?.result.whatsNew);
      setReview(jsonData?.result.reviews);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/getNeighborhood`,
        {
          method: "POST",
          body: JSON.stringify({ city: cityName }),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Add any other headers you need
          },
        }
      );
      const jsonData = await response.json();
      setNeighborhood(jsonData?.result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchCityZips = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/getZip`,
        {
          method: "POST",
          body: JSON.stringify({ city: cityName }),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Add any other headers you need
          },
        }
      );
      const jsonData = await response.json();
      setCityZips(jsonData?.result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  let params = useParams();
  const cityName = params?.cityName;
  let cityNameId = 0;
  if (params.cityName === "staten-island") {
    cityNameId = 1;
  } else if (params.cityName === "brooklyn") {
    cityNameId = 2;
  } else if (params.cityName === "queens") {
    cityNameId = 3;
  } else if (params.cityName === "brooklyn") {
    cityNameId = 4;
  } else if (params.cityName === "manhattan") {
    cityNameId = 5;
  }
  useEffect(() => {
    fetchCityZips();
  }, []);
  useEffect(() => {
    fetchData();
  });
  useEffect(() => {
    CityRestaurants();
  }, []);
  return (
    <>
    <div className=" container mx-auto     ">
    <div className=" absolute   hidden lg:block   border mb-2   shadow-2xl mx-auto container   lg:w-[250px] w-ful bg-white px-2 py-4 rounded-sm">
      <div className="flex justify-between   ">
        <div className="font-bold text-xl ">Cuisine</div>
        {neighborhood.length > 0 ? (
          <>
            {neighborhood.map((check) => {
              return (
                <>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                  </svg>

                  <div className="flex py-1 mt-4 items-center  ">
                    <input className="w-5 h-5" type={"checkbox"} />
                    <div className="font-leading  ml-3 text-xl">
                      {check.neighborhood_name}
                    </div>
                  </div>

                  <div className="font-bold text-xl mb-2">Other...</div>
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
      <div className="font-bold text-xl lg:ml-20 lg:px-52 px-2">Openings Near You</div>
     <div className="  ">
     <div className="lg:ml-28  lg:px-40 ">
     <div className=" overflow-x-auto py-2  p-2 ">
        <div className="flex gap-2  w-max">
         {news.map((panel)=>{
          return(
            <>
           <div>
            <img className="h-44"  />
            <div>dummy</div>
            <div>dummy</div>
            <div>dummy</div>
          </div>
         
            </>
          )
         })}
        </div>
      </div>
     </div>
     </div>
     <div className="mt-2">

   <div className=" lg:ml-28 lg:px-40 px-2  py-2 ">
    <div>
    <div className="grid lg:grid-cols-2  grid-cols-1   gap-2">
      <div className="flex items-center border border-red-900 rounded-md  px-2">
        <img className="w-1/2 "  />
        <div>
          <div>Real Madrid</div>
          <div>2075 Forest Ave , 10303</div>
          <div>Spanish</div>
          <div className="mt-3">
            Dine In Prix Fixe Menu $27.95 / Lobster Festival &
          </div>
          <div className="flex justify-end">
            <div>
              <img />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center border border-red-900 rounded-md  px-2">
      
        <div>
          <div>Real Madrid</div>
          <div>2075 Forest Ave , 10303</div>
          <div>Spanish</div>
          <div className="mt-3">
            Dine In Prix Fixe Menu $27.95 / Lobster Festival &
          </div>
          <div className="flex justify-end">
            <div>
            
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
   </div>
   </div>
    </div>
    
    </>
  )
}
