import React, { useState } from "react";
import Header from "../common/components/header";

export default function Dashboard() {
  //state
  const [activeButton, setActiveButton] = useState("features");
  const [data, setData] = useState("trylist");
  const [settingData, setSettingData] = useState("profileedit");
  //function
  const showFeatures = () => {
    setActiveButton("features");
  };
  const showSettings = () => {
    setActiveButton("settings");
  };
  const showFavorites = () => {
    setData("favorites");
  };
  const showTryList = () => {
    setData("trylist");
  };
  const showReviews = () => {
    setData("reviews");
  };
  const showPhoto = () => {
    setData("photo");
  };
  const showProfileEdit = () => {
    setSettingData("profileedit");
  };
  const showChangePassword = () => {
    setSettingData("changepassword");
  };
  const showAccoutDelete = () => {
    setSettingData("accountdelete");
  };
  return (
    <>
      <Header />
      <div className="conatainer mx-auto lg:w-[1150px]  mt-5">
        <div className="ml-2">
          <button
            style={{
              backgroundColor:
                activeButton === "features" ? "#281470" : "#88001b",
              color: "#ffff",
              padding: "10px 20px",
              cursor: "pointer",
            }}
            onClick={showFeatures}
          >
            FEATURES
          </button>
          <button
            style={{
              backgroundColor:
                activeButton === "settings" ? "#281470" : "#88001b",
              color: "#ffff",
              padding: "10px 20px",
              cursor: "pointer",
            }}
            onClick={showSettings}
          >
            SETTINGS
          </button>
          <div
            style={{
              display: activeButton === "features" ? "block" : "none",
              color: "#0000",
              padding: "20px",
            }}
          >
            <div className="border border-slate-200 shadow-lg mt-4 ">
              <div className="flex  place-content-around   ">
                <button
                  style={{
                    backgroundColor: data === "favorites" ? "#88001b" : "#ffff",

                    color: data === "favorites" ? "#ffff" : null,
                  }}
                  onClick={showFavorites}
                  className="border border-red-900  w-1/5  text-[#88001b]"
                >
                  FAVORITES(2)
                </button>
                <button
                  style={{
                    backgroundColor: data === "trylist" ? "#88001b" : "#ffff",
                    color: data === "trylist" ? "#ffff" : null,
                  }}
                  onClick={showTryList}
                  className="border border-red-900 p-2 w-1/5  text-[#88001b]"
                >
                  TRY LIST(7)
                </button>
                <button
                  style={{
                    backgroundColor: data === "reviews" ? "#88001b" : "#ffff",
                    color: data === "reviews" ? "#ffff" : null,
                  }}
                  onClick={showReviews}
                  className="border border-red-900 p-2 w-1/5  text-[#88001b]"
                >
                  REVIEWS (1)
                </button>
                <button
                  style={{
                    backgroundColor: data === "photo" ? "#88001b" : "#ffff",
                    color: data === "photo" ? "#ffff" : null,
                  }}
                  onClick={showPhoto}
                  className="border border-red-900 p-2 w-1/5  text-[#88001b]"
                >
                  PHOTOS(1)
                </button>
              </div>
              <div
                style={{
                  display: data === "trylist" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">sunny</div>
              </div>
              <div
                style={{
                  display: data === "favorites" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">sk</div>
              </div>
              <div
                style={{
                  display: data === "reviews" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">Reviews</div>
              </div>
              <div
                style={{
                  display: data === "photo" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">photo</div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: activeButton === "settings" ? "block" : "none",
              padding: "20px",
            }}
          >
            <div className="p-2 border-slate-200 shadow-lg">
              <div className="flex  place-content-around  ">
                <button
                  style={{
                    backgroundColor:
                      settingData === "profileedit" ? "#88001b" : "#ffff",
                    color: settingData === "profileedit" ? "#ffff" : null,
                  }}
                  className="border border-red-900 p-2 w-1/4  text-[#88001b]"
                  onClick={showProfileEdit}
                >
                  PROFILE EDIT
                </button>
                <button
                  style={{
                    backgroundColor:
                      settingData === "changepassword" ? "#88001b" : "#ffff",
                    color: settingData === "changepassword" ? "#ffff" : null,
                  }}
                  onClick={showChangePassword}
                  className="border border-red-900 p-2 w-1/4  text-[#88001b]"
                >
                  CHANGE PASSWORD
                </button>
                <button
                  style={{
                    backgroundColor:
                      settingData === "accountdelete" ? "#88001b" : "#ffff",
                    color: settingData === "accountdelete" ? "#ffff" : null,
                  }}
                  onClick={showAccoutDelete}
                  className="border border-red-900 p-2 w-1/4  text-[#88001b]"
                >
                  ACCOUNT DELETE
                </button>
              </div>
              <div
                style={{
                  display: settingData === "profileedit" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">sunny</div>
              </div>
              <div
                style={{
                  display: settingData === "changepassword" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">password</div>
              </div>
              <div
                style={{
                  display: settingData === "accountdelete" ? "block" : "none",
                  color: "#0000",
                  padding: "20px",
                }}
              >
                <div className="text-black">delete</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
