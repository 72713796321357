import React from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function InfoUser() {
  return (
    <div className="">
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto lg:px-24 p-2 ">
        <div className="text-2xl mt-10 leading-1 font-bold">Info for users</div>

        <div className=" text-xl leading-1 mt-5">
          WhereYouEat is the best way to discover local restaurants, look up
          information, find new restaurants to try and enjoy Local Eateries!
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
