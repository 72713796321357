import React, { useState } from "react";
import { Vortex } from "react-loader-spinner";
import ScroolButton from "./scroolButton";
import { useNavigate } from "react-router-dom";
import { BASE_URL, token } from "../../component/token";
export default function MainBottom(props) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loader, setLoader] = useState(false);
  const { headingText } = props;
  const handleChange = async (event) => {
    const value = event.target.value;
    setLoader(false);
    setSearchTerm(value);
    if (value.length === 0) {
      setResults([]);
      return;
    }
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/search`,
        {
          method: "POST",
          body: JSON.stringify({
            keyword: searchTerm,
          }),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          },
        }
      );
      const data = await response.json();
      setResults(data.result);
      setLoader(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  return (
    <div className=" ">
      <ScroolButton />
      <div className="my-elementTwo w-full h-10">
        <div className="p-4 ">
          <div className="font-bold lg:mt-14   p-4 text-center lg:text-3xl text-xl text-white ">
            {headingText}
          </div>
          <div className=" pb-4">
            <div className="flex  justify-center items-center ">
              <input
                value={searchTerm}
                style={{ outline: "none" }}
                onChange={handleChange}
                className="lg:w-[700px] w-full md:w-auto lg:h-12 h-8 p-2 "
                placeholder="Enter address to display restaurant near you"
              />
              <button className="bg-[#400000] w-24 lg:h-12 h-8 justify-center items-center flex">
                {loader ? (
                  <Vortex
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={[
                      "pink",
                      "red",
                      "orange",
                      "white",
                      "green",
                      "white",
                    ]}
                  />
                ) : (
                  <div className=" font-bold text-3xl text-white">GO</div>
                )}
              </button>
            </div>
            <div className="flex justify-center  ">
              <ul className="scrollable-list bg-white  lg:w-[795px] w-full md:w-auto  ">
                {results.length > 0 ? (
                  <>
                    {results.map((result, index) => (
                      <li
                        onClick={() => {
                          const modifiedPanelName = result.address
                            .replace(/\s/g, "-")
                            .toLowerCase();
                            navigate(`/searchCity/-${modifiedPanelName}`);
                        }}
                        className=" leading-3 px-2 py-2 font-bold text-red-900 "
                        key={index}
                      >
                        {result.address}
                      </li>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
