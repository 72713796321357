import React, { useEffect, useState } from "react";
import Header from "../common/components/header";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MainBottom from "../common/components/mainBottom";
import About from "../common/components/about";
import Noimage from "../img/noimage.jpg";
import { useNavigate } from "react-router-dom";
import Star from "../img/Star.png";
import { Helmet } from "react-helmet";
import { BASE_URL, token } from "./token";
import { Vortex } from "react-loader-spinner";

export default function DockAndDine() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [dockDine, setDockDine] = useState("");
  const [restaurantsN, setrestaurantsN] = useState([]);
  const [restaurantsP, setrestaurantsP] = useState([]);
  const [draggable, setDraggable] = useState(true);
  const [restaurantsH, setrestaurantsH] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [visibleItemCount, setVisibleItemCount] = useState(15);

  const handleShowMoreClick = () => {
    setVisibleItemCount((prevVisibleItemCount) =>
      Math.min(prevVisibleItemCount + 15, filteredRestaurants.length)
    );
  };
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getDockDine`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { restaurantsH, restaurantsN, restaurantsP } = data?.result;
        const uniqueRestaurants = new Map();
        const getRestaurantKey = (restaurant) =>
          `${restaurant.rid}_${restaurant.rname}_${restaurant.address}`;
        const isRestaurantAdded = (restaurant) => {
          const key = getRestaurantKey(restaurant);
          return uniqueRestaurants.has(key);
        };
        const addRestaurant = (restaurant) => {
          const key = getRestaurantKey(restaurant);
          const lat = parseFloat(restaurant.lat);
          const lng = parseFloat(restaurant.log);
          if (!isNaN(lat) && !isNaN(lng)) {
            uniqueRestaurants.set(key, {
              uniq: restaurant.id,
              id: restaurant.address,
              name: restaurant.rname,
              lat,
              lng,
            });
            setDraggable(true);
          } else {
            console.log(
              `Invalid latitude or longitude for restaurant: ${restaurant.rname}`
            );
          }
        };
        if (restaurantsH && Array.isArray(restaurantsH)) {
          restaurantsH.forEach((restaurant) => {
            if (!isRestaurantAdded(restaurant)) {
              addRestaurant(restaurant);
            }
          });
        }
        if (restaurantsN && Array.isArray(restaurantsN)) {
          restaurantsN.forEach((restaurant) => {
            if (!isRestaurantAdded(restaurant)) {
              addRestaurant(restaurant);
            }
          });
        }
        if (restaurantsP && Array.isArray(restaurantsP)) {
          restaurantsP.forEach((restaurant) => {
            if (!isRestaurantAdded(restaurant)) {
              addRestaurant(restaurant);
            }
          });
        }
        const allMarkers = Array.from(uniqueRestaurants.values());
        setDockDine(data?.result);
        setrestaurantsP(data?.result.restaurantsP);
        setrestaurantsH(data?.result.restaurantsH);
        setrestaurantsN(data?.result.restaurantsN);
        setMarkers(allMarkers);
        setLoader(true);
      })
      .catch((error) => {
        setLoader(false);
        console.log("Error:", error);
      });
  }, []);
  const uniqueRestaurantIdsOrNames = new Set();
  const filteredRestaurants = [];
  restaurantsP.forEach((pres) => {
    const idOrName = pres.id || pres.rname;
    if (!uniqueRestaurantIdsOrNames.has(idOrName)) {
      uniqueRestaurantIdsOrNames.add(idOrName);
      filteredRestaurants.push(pres);
    }
  });
  restaurantsH.forEach((hres) => {
    const idOrName = hres.id || hres.rname;
    if (!uniqueRestaurantIdsOrNames.has(idOrName)) {
      uniqueRestaurantIdsOrNames.add(idOrName);
      filteredRestaurants.push(hres);
    }
  });
  restaurantsN.forEach((nres) => {
    const idOrName = nres.id || nres.rname;
    if (!uniqueRestaurantIdsOrNames.has(idOrName)) {
      uniqueRestaurantIdsOrNames.add(idOrName);
      filteredRestaurants.push(nres);
    }
  });
  return (
    <>
      <Helmet>
        <title>
          Dock & Dine / Restaurants w/Boat Docks NYC, Long Island & NJ
        </title>
      </Helmet>
      <Header />
      <div className="my-elementfour">
        <div className="p-6 text-white">
          <div className="flex justify-center">
            <div className="text-3xl md:w-[450px] lg:w-full font-bold text-center mt-24">
              {dockDine.title}
            </div>
          </div>
          <div className="flex justify-center mt-6 mb-24">
            <div className="text-center text-xl md:w-[550px] lg:w-[600px]">
              {dockDine.description}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#395e78] flex justify-center px-2   ">
        <div className="lg:w-[750px]   relative border-2 border-red-900  w-full  mt-10 mb-10">
          <MapContainer
            className="md-w-full z-0 "
            center={[40.6138, -74.1683]}
            zoom={10}
            scrollWheelZoom={true}
            style={{ height: "400px" }}
          >
            <TileLayer
              maxZoom={14}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
            />
            {markers.map((marker) => (
              <Marker
                draggable={draggable}
                open={true}
                position={[marker.lat, marker.lng]}
              >
                <Popup
                  autoClose={false}
                  draggable={draggable}
                  autoPan={true}
                  open={true}
                  closeOnClick={false}
                >
                  <div className="text-[#0078A8] text-xl font-medium">
                    {marker.name}
                  </div>
                  <div className="text-xl">{marker.id}</div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
      <div className="flex justify-center items-center mt-8">
        <div className="font-bold text-2xl">List Of </div>
        <div className="font-bold text-2xl text-red-900 ml-2"> Restaurants</div>
      </div>
      {loader ? (
        <>
          <div className="container mx-auto lg:w-[1150px] w-full mt-10  px-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 mb-5 mt-4  gap-5">
              {filteredRestaurants.length > 0 ? (
                <>
                  {filteredRestaurants
                    .slice(0, visibleItemCount)
                    .map((pres) => {
                      const isFromP = restaurantsP.some(
                        (p) => p.id === pres.id
                      );
                      const isFromH = restaurantsH.some(
                        (h) => h.id === pres.id
                      );
                      const isFromN = restaurantsN.some(
                        (n) => n.id === pres.id
                      );
                      let classes = "";
                      let content = null;
                      if (isFromP) {
                        classes = "border-class border border-red-900   ";
                        content = <></>;
                      } else if (isFromH) {
                        classes = "your-star-image-class ";
                        content = (
                          <>
                            <img className="absolute h-10 w-10" src={Star} />
                          </>
                        );
                      } else if (isFromN) {
                        content = <>{/* Your content specific to N */}</>;
                      }
                      return (
                        <div className={classes} key={pres.id}>
                          <div
                            className="cursor-pointer  h-[200px] "
                            onClick={() => {
                              const modifiedPanelName = pres.rname
                                .replace(/\W/g, "")
                                .toLowerCase();
                              navigate(
                                `/menu/-${pres.id}-${modifiedPanelName}`
                              );
                            }}
                          >
                            <div className="flex overflow-hidden  h-full   bg-[#f0f0f0] border  hover:bg-white">
                              <div className="lg:w-[200px] w-full">
                                {pres.image ? (
                                  <>
                                    <img
                                      className="lg:h-full   "
                                      src={`https://live.orderingspace.net/r_gallery_images/rgallery-${pres.rid}/${pres.image}`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      className=" w-full h-full "
                                      src={Noimage}
                                    />
                                  </>
                                )}
                              </div>
                              <div className="lg:w-[200px] w-full">
                                <div className="px-2 py-2">
                                  <div className="font-bold text-red-900 text-left">
                                    {pres.rname}
                                  </div>
                                  <div className="text-slate-600 text-left">
                                    {pres.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <img />
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : null}
            </div>
            {visibleItemCount < filteredRestaurants.length && (
              <div className="col-span-2 flex justify-center mb-2">
                <button
                  className="font-medium text-center text-red-900 "
                  onClick={handleShowMoreClick}
                >
                  Show More
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <Vortex
              visible={true}
              height="60"
              width="60"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["yellow", "red", "orange", "green", "yellow", "orange"]}
            />
          </div>
        </>
      )}
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </>
  );
}
