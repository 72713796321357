import React, { useEffect, useState } from "react";
import Header from "../common/components/header";
import Modal from "react-modal";
import MainBottom from "../common/components/mainBottom";
import About from "../common/components/about";
import HeaderichmondAvenue from "../common/components/richmondAvenuePage/headerichmondAvenue";
import { useNavigate, useParams } from "react-router-dom";
import NoImage from "../img/noimage.jpg";
import Star from "../img/Star.png";
import { BASE_URL, token } from "./token";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function SearchCity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cityName = localStorage.getItem("cityName");
  let params = useParams();
  const resturant = params?.resturant;
  const navigate = useNavigate();
  //state
  const [cuisines, setCuisines] = useState([]);
  const [feature, setFeature] = useState([]);
  const [service, setServices] = useState([]);
  const [click, setClick] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [resturantH, setResturantH] = useState([]);
  const [resturantP, setResturantP] = useState([]);
  const [resturants, setResturants] = useState([]);
  const [serviceShow, setServiceShow] = useState(false);
  const [featureShow, setFeatureShow] = useState(false);
  const [cuisineShow, setCuisineShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [results, setResults] = useState([]);
  const [activeButton, setActiveButton] = useState("button2");
  const [showData, setShowData] = useState(true);
  const [openData, setOpenData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const cuisineToShow = cuisineShow ? cuisines : cuisines.slice(0, 4);
  const serviceToShow = serviceShow ? service : service.slice(0, 4);
  const featureToShow = featureShow ? feature : feature.slice(0, 4);
  const [showInputBox, setShowInputBox] = useState(false);
  const toggleInputBox = () => {
    setShowInputBox((prevState) => !prevState);
  };
  const [store, setStore] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  //slider
  const imagesToShow = 3;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3, // Number of items to show at this breakpoint
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show one item on mobile
    },
  };
  const handleText = (e) => {
    setStore({ ...store, [e.target.name]: e.target.value });
  };
  const toggleCuisineSHow = () => {
    setCuisineShow(!cuisineShow);
  };
  const toggleServiceSHow = () => {
    setServiceShow(!serviceShow);
  };
  const toggleFeatureSHow = () => {
    setFeatureShow(!featureShow);
  };
  const handleNum = async () => {
    setLoader(false);
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getSearchRestaurant`, {
        method: "POST",
        body: JSON.stringify({
          keyword: resturant,
          latitude: "",
          longitude: "",
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      if (data.result) {
        setResultData(data.result);
        if (!data.result.latitude || !data.result.longitude) {
          console.warn("Latitude or longitude not found in response.");
        }
        if (!data.result.recentOpening) {
          setSearchData([]);
        } else {
          setSearchData(data.result.recentOpening);
        }

        if (!data.result.restaurantP) {
          setResturantP([]);
        } else {
          setResturantP(data.result.restaurantP);
        }

        if (!data.result.restaurantH) {
          setResturantH([]);
        } else {
          setResturantH(data.result.restaurantH);
        }

        if (!data.result.restaurant) {
          setResturants([]);
        } else {
          setResturants(data.result.restaurant);
        }
      } else {
        console.warn("No result data found in response.");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  //search
  const handleChange = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");
    setSearchTerm(sanitizedInput);
    if (value.length < 3) {
      setResults([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/search`, {
        method: "POST",
        body: JSON.stringify({
          keyword: searchTerm,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setResults(data.result);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: "all" }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCuisines(data?.result?.cusine);
        setServices(data?.result?.service);
        setFeature(data?.result?.feature);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    handleNum();
  }, []);
  const handleShow = (button) => {
    setActiveButton(button);
    setShowData(!showData);
    setOpenData(false);
  };
  const handleClickData = (button) => {
    setActiveButton(button);
    setOpenData(!openData);
    setShowData(false);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
   <Helmet>
  <title className="capitalize">
    {`${resturant} Restaurants in/${cityName} /Openings & Menus`}
  </title>
  <meta name="restaurant" content={resturant} />
</Helmet>


      <div>
        <Header />
        <HeaderichmondAvenue />

        <Modal
          isOpen={isopen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className=" bg-white lg:w-[600px] w-full px-2">
            <div className="flex  justify-center items-center ">
              <div className="text-2xl w-full font-medium  py-1 border-b rounded-md text-center px-2 text-black">
                Report Missing Restaurant
              </div>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
              >
                <svg
                  className="h-5 w-5 "
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="mt-2">Email Address *</div>
            <input
              name="email"
              value={store.email}
              onChange={handleText}
              className="w-full mt-2 border border-black p-2 rounded-md"
              placeholder="Enter email"
            />
            {click && store.message == "" ? (
              <div className="text-red-600 ">Required to fill email id</div>
            ) : null}
            <div className="mt-2">Restaurant Name *</div>
            <input
              name=""
              value={store.resturant}
              onChange={handleText}
              type="phone"
              className=" no-spinner w-full mt-2 border border-black p-2 rounded-md"
              placeholder="Restaurant Name"
            />
            {click && store.phone == "" ? (
              <div className="text-red-600 ">
                Required to fill resturant name
              </div>
            ) : null}
            <div className="mt-2">Message: *</div>
            <textarea
              name="message"
              value={store.message}
              onChange={handleText}
              className="w-full h-24 border border-black p-2 mt-2 rounded-md"
              placeholder="write your message"
            />
            {click && store.message == "" ? (
              <div className="text-red-600 ">Required to fill message</div>
            ) : null}
            <button
              onClick={setClick}
              className="w-full mt-2 p-2 bg-red-900 rounded-md text-white"
            >
              Send
            </button>
          </div>
        </Modal>
        <div className="container  px:4 lg:w-[1170px] w-full flex items-center  gap-2 mx-auto  ">
          <div>
            <svg
              fill="#990000"
              className="w-7 h-7 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
            </svg>
          </div>
          <div className="text-base cursor-pointer capitalize text-[#88001b] font-semibold mb-2">
            {resturant}
          </div>
          <button className=" ml-2 mb-2" onClick={toggleInputBox}>
            (Edit)
          </button>
        </div>
        {showInputBox && (
          <div className="container  px-2 lg:w-[1170px] flex  w-full  items-center mb-2   mx-auto">
            <input
              value={searchTerm}
              onChange={handleChange}
              type="text"
              className="border-l border-t border-b  p-1  border-gray-400 rounded  focus:outline-none focus:border-blue-500 "
            />
            <button className="p-1 border-r border-t border-b text-white hover:bg-green-600 px-2 bg-red-900">
              Submit
            </button>
            <ul className="scrollable-list mt-60 w-[350px] absolute z-20 bg-white ">
              {results.length > 3 && results != "false" ? (
                <>
                  {results.map((result, index) => (
                    <li
                      onClick={() => {
                        const modifiedPanelName = result.address
                          .replace(/\W/g, "")
                          .toLowerCase();
                        navigate(`/searchCity/${modifiedPanelName}`);
                        window.location.reload();
                      }}
                      className="px-6 py-2 leading-5 font-bold  text-red-900 "
                      key={index}
                    >
                      {result.address}
                    </li>
                  ))}
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
        )}
        <div className="border border-red-900"></div>
        <div className="my-elements  ">
          <div className="lg:w-[1200px]   bg-[#f0f0f0] container mx-auto ">
            <div className="lg:flex py-2  lg:px-6     ">
              <div className="p-3  hidden lg:block    mb-2    py-4 rounded-sm">
                <div className="bg-white py-2 px-2 mt-5">
                  <div className="flex justify-between  items-center shadow-2xl mt-4 ">
                    <div className="font-bold text-xl text-red-900 ">
                      Cuisine
                    </div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className=" gap-2 mt-4 items-center">
                    {cuisineToShow.length > 0 ? (
                      <>
                        {cuisineToShow.map((cui) => (
                          <div
                            className="flex items-center mb-2"
                            key={cuisines.id}
                          >
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {cui.name}
                            </div>
                          </div>
                        ))}
                        <div className="">
                          {cuisineShow ? (
                            <>
                              <button
                                onClick={toggleCuisineSHow}
                                className="mt-2 underline "
                              >
                                Hide more...
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={toggleCuisineSHow}
                              className="mt-2 "
                            >
                              load more...
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="bg-white py-2 px-2 mt-5">
                  <div className="flex justify-between  items-center shadow-2xl mt-4 ">
                    <div className="font-bold text-xl text-red-900 ">
                      Features
                    </div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className=" gap-2 mt-4 items-center">
                    {featureToShow.length > 0 ? (
                      <>
                        {featureToShow.map((feat) => (
                          <div
                            className="flex items-center mb-2"
                            key={feature.id}
                          >
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {feat.feature_name}
                            </div>
                          </div>
                        ))}
                        <div className="">
                          {featureShow ? (
                            <>
                              <button
                                onClick={toggleFeatureSHow}
                                className="mt-2 underline "
                              >
                                Hide more...
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={toggleFeatureSHow}
                              className="mt-2 "
                            >
                              load more...
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="bg-white mt-1 mb-2 py-2 px-2">
                  <div className="flex justify-between  items-center">
                    <div className="font-bold text-xl text-red-900 ">
                      Services
                    </div>
                    <svg
                      fill="#88001b"
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                  <div className="gap-2 mt-4 items-center">
                    {serviceToShow.length > 0 ? (
                      <>
                        {serviceToShow.map((service) => (
                          <div
                            className="flex items-center mb-2"
                            key={service.id}
                          >
                            <input className="w-5 h-5" type="checkbox" />
                            <div className="font-leading ml-3 text-sm">
                              {service.service_name}
                            </div>
                          </div>
                        ))}
                        {serviceShow ? (
                          <>
                            <button
                              onClick={toggleServiceSHow}
                              className="mt-2 underline "
                            >
                              Hide more...
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={toggleServiceSHow}
                              className="mt-2"
                            >
                              load more...
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4   lg:px-4   lg:w-[900px]">
                <div className="py-2 ">
                  <div className="font-bold text-xl p-1">Openings Near You</div>
                </div>
                {searchData.length > 1 ? (
                  <>
                    <Carousel
                      className=""
                      responsive={responsive}
                      swipeable={true}
                      draggable={true}
                      customTransition="transform 300ms ease-in-out"
                    >
                      {searchData.map((near, index) => (
                        <div
                          key={index}
                          style={{
                            display:
                              index >= currentSlide &&
                              index < currentSlide + imagesToShow
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="gap-6 px-2 flex relative cursor-pointer border  py-2 shadow-lg">
                            <div
                              onClick={() => {
                                const modifiedPanelName = near.rname
                                  .replace(/\W/g, "")
                                  .toLowerCase();
                                navigate(
                                  `/menu/-${near.id}-${modifiedPanelName}`
                                );
                              }}
                            >
                              <div className="absolute bottom-40 right-0 mr-5">
                                {(() => {
                                  const dateObj = new Date(near.rdate);
                                  return isNaN(dateObj) ? (
                                    <p>OPENED: Invalid Date</p>
                                  ) : (
                                    <div className="bg-[#88001b] text-white  font-semibold px-2">
                                      OPENED:
                                      {dateObj.toLocaleString("default", {
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </div>
                                  );
                                })()}
                              </div>
                              {near.image ? (
                                <div className="flex">
                                  <img
                                    className="h-[170px] lg:w-[306px] object-cover"
                                    src={near.image}
                                    alt={near.rname}
                                  />
                                </div>
                              ) : (
                                <div className="flex relative">
                                  <img
                                    className="h-[170px] lg:w-[306px]"
                                    src={NoImage}
                                    alt="No Image Available"
                                  />
                                </div>
                              )}
                              <div className="font-medium text-[19px] text-xl mt-4">
                                {near.rname}
                              </div>
                              <div className="text-[#5a5a5a] text-[14px] font-light">
                                {near.address}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </>
                ) : (
                  <div className="text-center tect-red-900">No Data Found</div>
                )}

                <div className="flex justify-between mb-2 gap-2 mt-2 p-2 ">
                  <div className="flex gap-2 mr-2">
                    <div className="flex gap-2 mr-2">
                      <div
                        onClick={() => handleClickData("button1")}
                        className={
                          activeButton === "button1"
                            ? "active btn btn-solid lg:px-3 cursor-pointer text-black  font-bold p-2  px-1  "
                            : "btn btn-solid bg-white  px-1 cursor-pointer  p-2 text-black  lg:font-bold md:text:sm"
                        }
                      >
                        <div className=" lg:font-bold text-sm text-center">
                          Open Now
                        </div>
                      </div>
                      <div
                        onClick={() => handleShow("button2")}
                        className={
                          activeButton === "button2"
                            ? "active btn btn-solid lg:px-3 cursor-pointer text-black  font-bold p-2  px-1  "
                            : "btn btn-solid bg-white  px-1 cursor-pointer  p-2 text-black  lg:font-bold md:text:sm"
                        }
                      >
                        <div className="lg:font-bold text-sm text-center">
                          Show All
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      className="bg-yellow-300 cursor-pointer rounded lg:p-2 px-2 py-2"
                    >
                      <div className="text-red-900 lg:font-semibold text-center text-sm">
                        Report Missing Restaurant
                      </div>
                    </div>
                  </div>
                </div>
                {openData ? (
                  <>
                    <>
                      {resultData ? (
                        <>
                          <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-6 gap-4">
                            {resturantP.length > 0 && resturantP != "false" ? (
                              <>
                                {resturantP.map((pan) => {
                                  const getCuisineTitles = (resturantP) => {
                                    const cuisineArray = resturantP.cuisine;
                                    if (
                                      cuisineArray &&
                                      cuisineArray.length > 0
                                    ) {
                                      return cuisineArray[0].title;
                                    }
                                    return "Unknown Cuisine";
                                  };
                                  return (
                                    <>
                                      {pan.openCloseStatus === "open" ? (
                                        <>
                                          <div
                                            onClick={() => {
                                              const modifiedPanelName =
                                                pan.rname
                                                  .replace(/\W/g, "")
                                                  .toLowerCase();
                                              navigate(
                                                `/menu/-${pan.id}-${modifiedPanelName}`
                                              );
                                            }}
                                            className="flex cursor-pointer   hover:bg-white border border-red-900 rounded-md  "
                                          >
                                            {pan.image.length > 0 ? (
                                              <>
                                                <img
                                                  className=" h-[120px] lg:h-[150px]  max-w-1/2 min-w-1/2 "
                                                  src={pan.image}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  className=" h-[120px] lg:h-[150px]  max-w-1/2 min-w-1/2"
                                                  src={NoImage}
                                                />
                                              </>
                                            )}
                                            <div className="ml-2 ">
                                              <div className="font-semibold text-red-900 text-xl">
                                                {pan.rname}
                                              </div>
                                              <div className="text-sm text-[#505050]">
                                                {pan.address}
                                              </div>
                                              <div className="font-bold">
                                                {" "}
                                                {getCuisineTitles(pan)}
                                              </div>
                                              <div className=" font-bold">
                                                {Math.round(
                                                  pan.distance * 100
                                                ) / 100}{" "}
                                                Miles away
                                              </div>
                                              <div className="flex justify-end">
                                                <div>
                                                  <img src={pan.openClose} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {resturantH.length > 0 && resturantH !== "false" ? (
                              <>
                                {resturantH.map((panel) => {
                                  // Function to extract cuisine titles from each restaurant
                                  const getCuisineTitles = (restaurantH) => {
                                    const cuisineArray = restaurantH.cuisine;
                                    if (
                                      cuisineArray &&
                                      cuisineArray.length > 0
                                    ) {
                                      return cuisineArray[0].title;
                                    }
                                    return "Unknown Cuisine"; // Handle cases where cuisine information is missing
                                  };

                                  return (
                                    <>
                                      {panel.openCloseStatus === "open" ? (
                                        <>
                                          <div
                                            onClick={() => {
                                              const modifiedPanelName =
                                                panel.rname
                                                  .replace(/\W/g, "")
                                                  .toLowerCase();
                                              navigate(
                                                `/menu/-${panel.id}-${modifiedPanelName}.html`
                                              );
                                            }}
                                            className="flex cursor-pointer hover:bg-white shadow-lg rounded-md  "
                                          >
                                            <img
                                              className="absolute h-10 w-10 "
                                              src={Star}
                                            />
                                            <img
                                              className="absolute h-10 w-10 "
                                              src={Star}
                                            />
                                            {panel.image.length > 0 ? (
                                              <>
                                                <img
                                                  className="lg:h-[150px] h-[120px]  max-w-1/2  "
                                                  src={panel.image}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  className="lg:h-[150px] h-[120px]  max-w-1/2 "
                                                  src={NoImage}
                                                />
                                              </>
                                            )}
                                            <div className="ml-2">
                                              <div className="font-semibold text-xl text-red-900">
                                                {panel.rname}
                                              </div>
                                              <div className="text-sm text-[#505050]">
                                                {panel.address}
                                              </div>
                                              <div className="font-bold">
                                                {" "}
                                                {getCuisineTitles(panel)}
                                              </div>
                                              <div className="font-semibold">
                                                {Math.round(
                                                  panel.distance * 100
                                                ) / 100}{" "}
                                                Miles away
                                              </div>
                                              <div className="flex justify-end">
                                                <div>
                                                  <img src={panel.openClose} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {resturants.map((pk) => {
                              const modifiedPanelName = pk.rname
                                .replace(/\W/g, "")
                                .toLowerCase();
                              const getCuisineTitles = (restaurant) => {
                                const cuisineArray = restaurant.cuisine;
                                if (cuisineArray && cuisineArray.length > 0) {
                                  return cuisineArray[0].title;
                                }
                                return "Unknown Cuisine"; // Handle cases where cuisine information is missing
                              };
                              return (
                                <>
                                  {pk.openCloseStatus == "open" ? (
                                    <>
                                      <div
                                        key={pk.id}
                                        onClick={() => {
                                          navigate(
                                            `/menu/-${pk.id}-${modifiedPanelName}.html`
                                          );
                                        }}
                                        className="flex cursor-pointer hover:bg-white shadow-lg rounded-md"
                                      >
                                        {pk.image.length > 0 ? (
                                          <img
                                            className="lg:h-[150px] h-[120px] max-w-1/2"
                                            src={pk.image}
                                            alt={pk.rname}
                                          />
                                        ) : (
                                          <img
                                            className="lg:h-[150px] h-[120px] max-w-1/2"
                                            src={NoImage}
                                            alt="No Image"
                                          />
                                        )}
                                        <div className="ml-2">
                                          <div className="font-semibold text-xl text-red-900">
                                            {pk.rname}
                                          </div>
                                          <div className="text-sm text-[#505050]">
                                            {pk.address}
                                          </div>
                                          <div className="font-bold">
                                            {" "}
                                            {getCuisineTitles(pk)}
                                          </div>

                                          <div className="font-semibold">
                                            {Math.round(pk.distance * 100) /
                                              100}{" "}
                                            Miles away
                                          </div>
                                          <div className="flex justify-end">
                                            <div>
                                              <img src={pk.openClose} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <div>No Data Found on this Search</div>
                      )}
                    </>
                  </>
                ) : (
                  <></>
                )}
                {showData ? (
                  <>
                    <div>
                      {resultData ? (
                        <>
                          <div className="grid lg:grid-cols-2 lg:gap-10 gap-4">
                            {resturantP.length > 0 && resturantP != "false" ? (
                              <>
                                {resturantP.map((pan) => {
                                  const getCuisineTitles = (resturantP) => {
                                    const cuisineArray = resturantP.cuisine;
                                    if (
                                      cuisineArray &&
                                      cuisineArray.length > 0
                                    ) {
                                      return cuisineArray[0].title;
                                    }
                                    return "Unknown Cuisine";
                                  };
                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          const modifiedPanelName = pan.rname
                                            .replace(/\W/g, "")
                                            .toLowerCase();
                                          navigate(
                                            `/menu/-${pan.id}-${modifiedPanelName}`
                                          );
                                        }}
                                        className="cursor-pointer flex cursor-pointe hover:bg-white border border-red-900 rounded-md  "
                                      >
                                        {pan.image.length > 0 ? (
                                          <>
                                            <img
                                              className=" h-[120px] lg:h-[150px]  max-w-1/2 min-w-1/2 "
                                              src={pan.image}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className=" h-[120px] lg:h-[150px]  max-w-1/2 min-w-1/2"
                                              src={NoImage}
                                            />
                                          </>
                                        )}
                                        <div className="ml-2 ">
                                          <div className="font-semibold text-red-900 text-xl">
                                            {pan.rname}
                                          </div>
                                          <div className="text-sm text-[#505050]">
                                            {pan.address}
                                          </div>
                                          <div className="font-bold">
                                            {" "}
                                            {getCuisineTitles(pan)}
                                          </div>
                                          <div className=" font-bold">
                                            {Math.round(pan.distance * 100) /
                                              100}{" "}
                                            Miles away
                                          </div>
                                          <div className="flex justify-end">
                                            <div>
                                              <img src={pan.openClose} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {resturantH.length > 0 && resturantH != "false" ? (
                              <>
                                {resturantH.map((panel) => {
                                  const getCuisineTitles = (restaurantH) => {
                                    const cuisineArray = restaurantH.cuisine;
                                    if (
                                      cuisineArray &&
                                      cuisineArray.length > 0
                                    ) {
                                      return cuisineArray[0].title;
                                    }
                                    return "Unknown Cuisine";
                                  };

                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          const modifiedPanelName = panel.rname
                                            .replace(/\W/g, "")
                                            .toLowerCase();
                                          navigate(
                                            `/menu/-${panel.id}-${modifiedPanelName}.html`
                                          );
                                        }}
                                        className="cursor-pointer flex  hover:bg-white shadow-lg rounded-md  "
                                      >
                                        <img
                                          className="absolute h-10 w-10 "
                                          src={Star}
                                        />
                                        <img
                                          className="absolute h-10 w-10 "
                                          src={Star}
                                        />
                                        {panel.image.length > 0 ? (
                                          <>
                                            <img
                                              className="lg:h-[150px] h-[120px]  max-w-1/2 "
                                              src={panel.image}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="lg:h-[150px] h-[120px]  max-w-1/2"
                                              src={NoImage}
                                            />
                                          </>
                                        )}
                                        <div className="ml-2">
                                          <div className="font-semibold text-xl text-red-900">
                                            {panel.rname}
                                          </div>
                                          <div className="text-sm text-[#505050]">
                                            {panel.address}
                                          </div>
                                          <div className="font-bold">
                                            {" "}
                                            {getCuisineTitles(panel)}
                                          </div>
                                          <div className="font-semibold">
                                            {Math.round(panel.distance * 100) /
                                              100}{" "}
                                            Miles away
                                          </div>
                                          <div className="flex justify-end">
                                            <div>
                                              <img src={panel.openClose} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}

                            {resturants.length > 0 && resturants != "false" ? (
                              <>
                                {resturants.map((pen) => {
                                  const modifiedPanelName = pen.rname
                                    .replace(/\W/g, "")
                                    .toLowerCase();
                                  const getCuisineTitles = (restaurant) => {
                                    const cuisineArray = restaurant.cuisine;
                                    if (
                                      cuisineArray &&
                                      cuisineArray.length > 0
                                    ) {
                                      return cuisineArray[0].title;
                                    }
                                    return "Unknown Cuisine"; // Handle cases where cuisine information is missing
                                  };

                                  return (
                                    <div
                                      key={pen.id}
                                      onClick={() => {
                                        navigate(
                                          `/menu/-${pen.id}-${modifiedPanelName}.html`
                                        );
                                      }}
                                      className="flex cursor-pointer hover:bg-white shadow-lg rounded-md"
                                    >
                                      {pen.image.length > 0 ? (
                                        <img
                                          className="lg:h-[150px] h-[120px] max-w-1/2"
                                          src={pen.image}
                                          alt={pen.rname}
                                        />
                                      ) : (
                                        <img
                                          className="lg:h-[150px] h-[120px] max-w-1/2"
                                          alt="No Image"
                                        />
                                      )}
                                      <div className="ml-2">
                                        <div className="font-semibold text-xl text-red-900">
                                          {pen.rname}
                                        </div>
                                        <div className="text-sm text-[#505050]">
                                          {pen.address}
                                        </div>
                                        <div className="font-bold">
                                          {" "}
                                          {getCuisineTitles(pen)}
                                        </div>
                                        <div className="font-semibold">
                                          {Math.round(pen.distance * 100) / 100}{" "}
                                          Miles away
                                        </div>
                                        <div className="flex justify-end">
                                          <div>
                                            <img src={pen.openClose} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="text-red-900 text-center">
                          No Data Found on ThatSearch
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <MainBottom headingText="Search for Restaurants Near You" />
        <About />
      </div>
    </>
  );
}
