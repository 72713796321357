import { Result } from "postcss";
import React, { useEffect, useState } from "react";
import { Vortex } from "react-loader-spinner";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

export default function Gallery() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [urlimage, setUrlImage] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  let params = useParams();
  const id = params?.id.split("-")[1];
  useEffect(() => {
    fetch("https://api.orderingspace.net/wye-api/getRestaurant", {
      method: "POST",
      body: JSON.stringify({ rid: "6" }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoader(true);
        setUrlImage(data?.result.gallery_url);
        setDataImage(data?.result.gallery);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="px-2 mt-5">
      <div>
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className=" w-[400px]">
            <div className="flex  justify-center items-center ">
              <div className="text-2xl w-full bg-red-900 py-5 px-2 text-white">
                Add User Photos
              </div>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="h-8 w-8 absolute right-2 top-2   flex justify-center items-center bg-red-900 border border-white rounded-full "
              >
                <svg
                  className="h-5 w-5 "
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>

            <div className="bg-green-600 rounded-md px-4 py-4 mt-2 ">
              <div className="text-lg text-white ">Add Files...</div>
              <input className="text-white" type="file" />
            </div>
            <div className="mt-2">
              <div>Title</div>
              <input
                className="w-full border h-8 p-2 rounded-md border-black"
                placeholder="title"
              />
            </div>
            <div className="mt-2">
              <div>Description</div>
              <input
                className="w-full border rounded-md h-8 p-2 border-black"
                placeholder="description"
              />
            </div>
            <div className="flex justify-between mt-5">
              <div>
                <button className="bg-blue-500   lg:w-40 w-24 h-8 text-white rounded-md">
                  Submit
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="bg-red-900  lg:w-40 w-24 h-8 text-white rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="flex justify-between">
        <button className="text-2xl font-semibold">Gallery</button>
        <div className="flex gap-4">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className="bg-green-600 p-2 rounded-md text-white"
          >
            Add Photos{" "}
          </button>
          <div className="bg-black p-2 text-white rounded-md">Close</div>
        </div>
      </div>
      {loader ? (
        <>
          <div className="grid lg:grid-cols-3 mt-3 gap-3    ">
            {dataImage?.map((panel) => {
              return (
                <>
                  <img className="w-full" src={`${urlimage}${panel?.image}`} />
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <Vortex
              visible={true}
              height="60"
              width="60"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["white", "red", "orange", "green", "yellow", "orange"]}
            />
          </div>
        </>
      )}
    </div>
  );
}
