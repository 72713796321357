import React from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";
import RichmondAvenueHeader from "../common/components/richmondAvenuePage/richmondAvenueHeader";

export default function RichmondAvenue() {
  return (
    <div>
      <Header />
      <div className="border-b border-[#990000]"></div>
      <RichmondAvenueHeader />
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
