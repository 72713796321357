import React, { useLayoutEffect } from "react";
import MenuItems from "../common/components/menuBar/menuItems";

export default function Menu() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <MenuItems />
    </div>
  );
}
