import React, { useState } from "react";
import Header from "../common/components/header";
import { useNavigate } from "react-router-dom";
import { BASE_URL, token } from "./token";

export default function NotFound() {
  //naviagtion
  const navigate = useNavigate();
  //usestate
  const [results, setResults] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchCity, setSearchCity] = useState([]);
  const [searchData, setSearchData] = useState([]);
  //handle permission
  const handleChange = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");
    setLoader(false);
    setSearchTerm(sanitizedInput);
    if (value.length < 3) {
      setResults([]);
      return;
    }
    //fetch api
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/search`,
        {
          method: "POST",
          body: JSON.stringify({
            keyword: searchTerm,
          }),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Add any other headers you need
          },
        }
      );
      const data = await response.json();
      setResults(data.result);
      setLoader(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleData = async (event) => {
    const value = event.target.value.replace(/[^\w\s]/gi, "");
    setLoader(false);
    setSearchCity(value);
    if (value.length < 3) {
      setSearchData([]);
      return;
    }
    try {
      const response = await fetch(
        `${BASE_URL}/wye-api/getRestaurantByName`,
        {
          method: "POST",
          body: JSON.stringify({
            keyword: searchCity,
          }),
        }
      );
      const data = await response.json();
      setSearchData(data.result.restaurant);
      setLoader(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  return (
    <>
      <Header />
      <div className="my-element py-10 ">
        <div className="container mx-auto border lg:w-[900px] mt-24 rounded   py-10 mb-24 bg-white opacity-80">
          <div className="text-2xl font-bold text-center mt-4">
            Welcome to The New WhereYouEat
          </div>
          <div className="font-normal text-center mb-4 text-2xl">
            Our menu links may have moved
          </div>
          <div className="border-b border-red-900 mb-10"></div>
          <div className="font-normal text-red-900 text-xl text-center mb-3 ">
            Search for a specific restaurant
          </div>
          <div className="flex  items-center justify-center">
            <input
              value={searchCity}
              onChange={handleData}
              placeholder="Type restaurant name"
              className="border-t border-b border-l p-2 rounded border-black h-12"
            />
            <button className="bg-red-900 text-white font-bold h-12 w-24 border-r border-b border-t ">
              Search
            </button>
          </div>
          <ul className="scrollable-list  lg:w-[400px] text-center absolute z-10 lg:left-[740px] left-64  bg-white">
            {searchData.length > 3 ? (
              <>
                {searchData.map((pk, index) => (
                  <>
                    <ul
                      className="border-b "
                      onClick={() => {
                        const modified = pk.rname.replace(/\s/g, "");
                        navigate(`/menu/-${pk.id}-${modified}`);
                      }}
                    >
                      <li
                        className="px-6    font-bold text-red-900 "
                        key={index}
                      >
                        {pk.rname}
                      </li>
                      <li className="px-6   text-slate-600 " key={index}>
                        {pk.address}
                      </li>
                    </ul>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </ul>
          <div className="mt-5 text-red-900 font-bold text-2xl text-center mb-5">
            Discover Restaurants Near You
          </div>
          <div className="flex justify-center ">
            <div className="flex   items-center mb-5 justify-center w-[500px]  ">
              <div className="border-l border-t border-black   h-12 p-4 rounded-l-lg border-r round border-b  items-center flex">
                <svg
                  fill="#88001b"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
              </div>
              <input
                onChange={handleChange}
                value={searchTerm}
                placeholder="Type address to discover"
                className="border-t p-2 border-b outline-none    border-black h-12 w-full"
              />
              <button className="bg-red-900 text-white font-bold h-12 w-24 border-r border-b border-t ">
                Go
              </button>
            </div>
            <ul className="scrollable-list mt-14 w-[500px] text-center absolute z-10 left-54 bg-white ">
              {results.length > 3 ? (
                <>
                  {results.map((result, index) => (
                    <li
                      onClick={() => {
                        const modifiedPanelName = result.address
                          .replace(/\W/g, "")
                          .toLowerCase();
                        navigate(`/searchCity/${modifiedPanelName}`);
                      }}
                      className="px-6 py-2 border-b leading-3 font-bold text-red-900 "
                      key={index}
                    >
                      {result.address}
                    </li>
                  ))}
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
