import React, { useEffect, useState } from "react";
import { Vortex } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BASE_URL, token } from "../../component/token";

export default function Dining() {
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getCity`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCities(data?.result);
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className=" md:w-auto  mt-20 mx-auto  flex justify-center ">
      <div className="lg:w-[500px] bg-white p-4  opacity-80">
        <div className="text-center bg-white p-2 opacity-100 px-2 py-2  text-red-900 text-lg font-bold">
          NEW EXCLUSIVE SECTION FOR BOATERS
        </div>
        <div
          onClick={() => {
            navigate("/dock-and-dine");
          }}
          className="border-4 cursor-pointer  border-[#2a1071] flex justify-center  mt-4 "
        >
          <div className="bg-white ">
            <div className="px-4 py-2">
              <div className="font-extrabold lg:text-3xl text-xl text-center text-[#2a1071]">
                DOCK AND DINE
              </div>
              <div className="lg:text-lg text-center">By Sea! Restaurants w/BOAT DOCKS</div>
              <div className="flex lg:items-center">
                <div className="text-lg">around</div>
                <div className="font-bold lg:text-3xl text-xl text-[#2a1071] ml-1">
                  NY / NJ & LONG ISLAND
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="font-bold mt-4  text-center text-[#2a1071] text-2xl">
          Explore Our Dining Guides
        </div>
        {loader ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 items-center  gap-4 px-4">
              {cities.map((item, index) => (
                <div
                  onClick={() => {
                    const PanelName = item.cityName
                      .replace(/\s/g, "-")
                      .toLowerCase();
                    navigate(`/city/${PanelName}`);
                  }}
                  key={index}
                  className={`${
                    index === cities.length - 1
                      ? " justify-center cursor-pointer lg:ml-28 font-bold h-12 text-white text-center bg-[#2a1071] flex lg:w-[200px] w-full    items-center"
                      : "bg-[#2a1071] h-12 cursor-pointer flex justify-center font-bold items-center w-full    text-white"
                  }`}
                >
                  {item.cityName}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center items-center">
              <Vortex
                visible={true}
                height="60"
                width="60"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={[
                  "yellow",
                  "red",
                  "orange",
                  "green",
                  "yellow",
                  "orange",
                ]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
