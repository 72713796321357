import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto p-2">
        <div className="lg:px-24 leading-normal">
          <div className="font-bold text-xl  mt-2 leading-normal">
            Private policy
          </div>
          <div className="font-bold text-2xl mt-2 leading-normal">
            Privacy policy
          </div>
          <div className="mt-4">
            This Privacy Policy is incorporated by reference into WYE LLC. Terms
            of Service. The term “Company,” “we,” and “us” includes WYE LLC. and
            its affiliates and subsidiaries. The Privacy Policy explains how
            Company may:
          </div>
          <ul>
            <li itemType="" className="mt-3">
              collect
            </li>
            <li className="mt-3">use, and</li>
            <li className="mt-3">disclose</li>
          </ul>
          <div>information we obtain through the “Site.”</div>
          <div>
            The “Site” means any website, mobile application, or Internet
            service under the control of WYE LLC., whether partial or otherwise,
            in connection with providing an online platform for Company’s
            services.
          </div>
          <div className="flex">
            <div className="font-bold">“Personal Information”</div>
            <div>
              means information that alone or when in combination with other
              information may be used to readily identify, contact, or locate
              you, such as: name, address, email address, or phone number. We do
              not consider Personal Information to include information that has
              been anonymized so that it does not allow a third party to easily
              identify a specific individual.
            </div>
          </div>
          <div className="font-bold">The Site Collects Your Information</div>
          <div>
            <div>We collect Personal Information when you:</div>
            <ul>register to use the Site;</ul>
            <ul>use the Site; and</ul>
            <ul>communicate with us.</ul>
            <div>
              We also collect information, such as anonymous usage statistics,
              by using cookies, server logs, and other similar technology as you
              use the Site.
            </div>
            <div>
              We use third-party advertising companies to serve ads and collect
              information when users visit our site.
            </div>
            <div>
              These companies may use information (not including your name,
              address, email address or telephone number) about your visits to
              this and other websites in order to provide advertisements on our
              site, other websites and other forms of media about goods and
              services of interest to you.
            </div>

            <div>
              These companies may use information (not including your name,
              address, email address or telephone number) about your visits to
              this and other websites in order to provide advertisements on our
              site, other websites and other forms of media about goods and
              services of interest to you.out
            </div>
          </div>

          <div className="flex text-left mx-auto">
            <Link to="http://www.networkadvertising.org/managing/opt_out.asp" className="text-blue-600 text-sm ">
              http://www.networkadvertising.org/managing/opt_out.asp
            </Link>
          </div>
          <div className="flex">
            <div className="font-bold">Personal Information Collection.</div>
            <div>
              You must register to use the Site to place orders. To register,
              you will need to provide Personal Information, including your
              name, address, email address, telephone number, nearest cross
              streets, and credit card information. You may also provide other
              optional information.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Using the Site. </div>
            <div>
              We collect information you post through the Site. For example,
              when you interact with other users by posting messages on the
              Site, the Site will collect the information you provide in such
              submissions, including any Personal Information.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Making Payments. </div>
            <div>
              When you make payments through the Site, you may need to provide
              Personal Information to our third-party service providers, such as
              your credit card number.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Customer Support. </div>
            <div>
              We may collect Personal Information through your communications
              with our customer-support team.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              Cookies, Automatic Data Collection, and Related Technologies.{" "}
            </div>
            <div>
              The Site collects and stores information that is generated
              automatically as you use it, including your preferences and
              anonymous usage statistics.
            </div>
          </div>
          <div>
            When you visit the Site, we and our third-party service providers
            receive and record information on our server logs from your browser,
            including your IP address, and from cookies and similar technology.
            Cookies are small text files placed in visitors’ computer browsers
            to store their preferences. Most browsers allow you to block and
            delete cookies. However, if you do that, the Site may not work
            properly.
          </div>
          <div>
            By using the Site, you are authorizing us to gather, parse, and
            retain data related to the provision of the Site.
          </div>
          <div className="font-bold">How Company Uses Your Information</div>
          <div>We use Personal Information to</div>
          <ul>facilitate and improve our services; and</ul>
          <ul>communicate with you.</ul>
          <div>
            We may use aggregate information for any purpose, including for
            marketing purposes.
          </div>
          <div className="flex">
            <div className="font-bold">Internal and Service-Related Usage.</div>
            <div>
              We use information, including Personal Information, for internal
              and service-related purposes only and may provide it to third
              parties to allow us to facilitate the Site. We may use and retain
              any data we collect to provide and improve our services.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Communications. </div>
            <div>
              We may send email to the email address you provide to us to verify
              your account and for informational and operational purposes, such
              as account management, customer service, or system maintenance.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Marketing. </div>
            <div>
              We may use information, including Personal Information, to provide
              online advertising on the Site and to facilitate transmittal of
              information that may be useful, relevant, valuable or otherwise of
              interest to you.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Aggregate Data. </div>
            <div>
              We may anonymize and aggregate data collected through the Site and
              use it for any purpose.
            </div>
          </div>
          <div className="font-bold">COMPANY May Disclose Your Information</div>
          <div>We may share your information:</div>
          <ul>with our third-party service providers;</ul>
          <ul>to comply with legal obligations;</ul>
          <ul>· to protect and defend our rights and property; and</ul>
          <ul> with your permission.</ul>
          <div>
            We do not rent, sell, or share Personal Information about you with
            other people or nonaffiliated companies for their direct marketing
            purposes, unless we have your permission.
          </div>
          <div className="flex">
            <div className="font-bold">
              We Use Vendors and Service Providers.
            </div>
            <div>
              We may share any information we receive with vendors and service
              providers retained in connection with the provision of the Site.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Displaying to Other Users. </div>
            <div>
              The content you post to the Site may be displayed on the Site. We
              are not responsible for privacy practices of the other users who
              will view and use the posted information.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              Social Networking and Other Websites.{" "}
            </div>
            <div>
              The Site may allow you to share information, including Personal
              Information, with social networking websites, such as Facebook. We
              do not share your Personal Information with them unless you direct
              the Site to share it. Their use of the information will be
              governed by their privacy policies, and you may be able to modify
              your privacy settings on their websites.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">SMarketing. </div>
            <div>
              We do not rent, sell, or share Personal Information about you with
              other people or nonaffiliated companies for their direct marketing
              purposes, unless we have your permission. We may allow access to
              other data collected by the Site to enable the delivery of online
              advertising on this website, or otherwise facilitate transmittal
              of information that may be useful, relevant, valuable or otherwise
              of interest to you.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              As Required By Law and Similar Disclosures{" "}
            </div>
            <div>
              We may access, preserve, and disclose your Personal Information,
              other account information, and content if we believe doing so is
              required or appropriate to: comply with law enforcement requests
              and legal process, such as a court order or subpoena; respond to
              your requests; or protect yours’, ours’ or others’ rights,
              property, or safety. interest to you.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              Merger, Sale, or Other Asset Transfers.{" "}
            </div>
            <div>
              If we are involved in a merger, acquisition, financing due
              diligence, reorganization, bankruptcy, receivership, sale of
              company assets, or transition of service to another provider, your
              information may be sold or transferred as part of such a
              transaction as permitted by law and/or contract. We cannot control
              how such entities may use or disclose such information.
            </div>
          </div>
          <div>
            We may also disclose your Personal Information with your permission.
          </div>
          <div className="font-bold">Security of Your Information</div>
          <div className="mt-2">
            We take steps to ensure that your information is treated securely
            and in accordance with this Privacy Policy. Unfortunately, the
            Internet cannot be guaranteed to be 100% secure, and we cannot
            ensure or warrant the security of any information you provide to us.
            We do not accept liability for unintentional disclosure.
          </div>
          <div className="mt-2">
            By using the Site or providing Personal Information to us, you agree
            that we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of the Site.
            If we learn of a security system’s breach, we may attempt to notify
            you electronically by posting a notice on the Site or sending an
            email to you. You may have a legal right to receive this notice in
            writing. To receive free written notice of a security breach (or to
            withdraw your consent from receiving electronic notice), please
            notify us at support@whereyoueat.com.
          </div>
          <div className="mt-2 font-bold">Children’s Privacy</div>
          <div className="mt-2">
            We do not knowingly collect information from children under 13 and
            we do not want it. We will take steps to delete it if we learn we
            have collected it.
          </div>
          <div className="mt-2">
            We do not knowingly collect, maintain, or use Personal Information
            from children under 13 years of age, and no part of the Site is
            directed to children under the age of 13. If you learn that your
            child has provided us with Personal Information without your
            consent, you may alert us at support@whereyoueat.com. If we learn
            that we have collected any Personal Information from children under
            13, we will promptly take steps to delete such information and
            terminate the child’s account.
          </div>
          <div className="font-bold mt-2">International Users</div>
          <div>
            By using the Site, you will transfer data to the United States.
          </div>
          <div>
            By choosing to visit the Site or otherwise provide information to
            us, you agree that any dispute over privacy or the terms contained
            in this Privacy Policy will be governed by the laws of the state of
            New York and the adjudication of any disputes arising in connection
            with Company or the Site will be in accordance with the Terms of
            Use.
          </div>
          <div className="font-bold mt-2">
            If you are visiting from the European Union or other regions with
            laws governing data collection and use, please note that you are
            agreeing to the transfer of your information to the United States
            and processing globally. By providing your information you consent
            to any transfer and processing in accordance with this Policy.
          </div>
          <div className="font-bold">
            UPDATE YOUR INFORMATION OR POSE A QUESTION OR SUGGESTION
          </div>
          <div>
            If you would like to update or correct any information that you have
            provided to us through your use of the Site or otherwise, or if you
            have suggestions for improving this Privacy Policy, please send an
            email to support@whereyoueat.com.
          </div>
          <div className="font-bold mt-3">
            CHANGES TO OUR PRIVACY POLICY AND PRACTICES
          </div>
          <div className="mt-2">
            We may revise this Privacy Policy, so review it periodically.
          </div>
          <div className="flex">
            <div className="font-bold">Posting of Revised Privacy Policy.</div>
            <div>
              We will post any adjustments to the Privacy Policy on this web
              page, and the revised version will be effective when it is posted.
              If you are concerned about how your information is used, bookmark
              this page and read this Privacy Policy periodically.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">New Uses of Personal Information.</div>
            <div>
              From time to time, we may desire to use Personal Information for
              uses not previously disclosed in our Privacy Policy. If our
              practices change regarding previously collected Personal
              Information in a way that would be materially less restrictive
              than stated in the version of this Privacy Policy in effect at the
              time we collected the information, we will make reasonable efforts
              to provide notice and obtain consent to any such uses as may be
              required by law
            </div>
          </div>
          <div className="mt-4 font-bold">Contact Information</div>
          <div>support@whereyoueat.com.</div>
          <div>718-878-6758</div>
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </>
  );
}
