import React from "react";
import Header from "./header";
import MainBottom from "./mainBottom";
import About from "./about";

export default function Signuptowhereyoueat() {
  return (
    <>
      <Header />
      <div className="border-t-2 border-red-900 "></div>
      <div className="text-center mt-2 p-6 font-semibold">Comeing Soon.........</div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </>
  );
}
