import React, { useEffect, useState } from "react";
import { Vortex } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, token } from "../../../component/token";

export default function Cuisine() {
  const [cuisines, setCuisines] = useState([]);
  const [displayCount, setDisplayCount] = useState(28);
  const [loader, setLoader] = useState(false);
  const [showMore, setShowMore] = useState(true); 
  const navigate = useNavigate();
  let params = useParams();
  const cityName = params?.cityName;
  let cityNameId = 0;
  if (params.cityName === "staten-island") {
    cityNameId = 1;
  } else if (params.cityName === "brooklyn") {
    cityNameId = 2;
  } else if (params.cityName === "queens") {
    cityNameId = 3;
  } else if (params.cityName === "the-bronx") {
    cityNameId = 4;
  } else if (params.cityName === "manhattan") {
    cityNameId = 5;
  } else {
    cityNameId = "no data found"; 
  }
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: "all" }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
    
      .then((response) => response.json())
      .then((data) => {
        setCuisines(data?.result?.cusine);
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleShowMore = () => {
    const newDisplayCount = displayCount + 28;
    setDisplayCount(newDisplayCount);
    if (newDisplayCount >= cuisines.length) {
      setShowMore(false); 
    }
  };
  const handleHideMore = () => {
    const newDisplayCount = displayCount - 28;
    if (newDisplayCount < cuisines.length - 28) {
      setDisplayCount(newDisplayCount);
    } else {
      setDisplayCount(cuisines.length - 28);
    }
    setShowMore(true);
  };
  return (
    <div>
      <div className="mt-10 font-extrabold text-2xl text-center text-[#88001b]">
        CUISINE SEARCH
      </div>
      {loader ? (
        <>
          <div className="grid lg:grid-cols-4 grid-cols-3  justify-center mt-3 md:px-6 px-1">
            {cuisines &&
              cuisines.slice(0, displayCount).map((dummy) => (
                <div
                  className=" cursor-pointer leading-8 uppercase  md:text-center text-sm "
                  onClick={() => {
                    const modifi = dummy.name.replace(/\s/g, "");
                    const modified = "cuisine".replace(/\s/g, "").toLowerCase();
                    const city = cityName.replace(/\s/g, "").toLowerCase();
                    const url = `/cuisine/-${modifi}-${modified}-${cityName}-${dummy.id}`;
                    navigate(url);
                  }}
                  key={dummy.id}
                >
                  {dummy.name}
                </div>
              ))}
          </div>
          {showMore ? (
            <div
              className=" mt-2 text-xl  font-normal text-center cursor-pointer "
              onClick={handleShowMore}
            >
              Show More
            </div>
          ) :(
            <div
              className=" mt-2 text-xl  font-normal text-center cursor-pointer "
              onClick={handleHideMore}
            >
              Hide More
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <Vortex
              visible={true}
              height="60"
              width="60"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["yellow", "red", "orange", "green", "yellow", "orange"]}
            />
          </div>
        </>
      )}
    </div>
  );
}
