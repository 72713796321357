import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="bg-white">
      <div className=" hidden lg:block  bg-black gap-4 text-white items-center justify-center lg:py-4 text-lg mx-auto ">
        <div className="flex gap-2 lg:gap-10 justify-center items-center ">
          <Link
            className="hover:text-yellow-300"
            style={{ cursor: "pointer" }}
            to="/aboutpage"
          >
            About
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/privacypolicy"
          >
            Privacy policy
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/faqpage"
          >
            FAQ
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/term"
          >
            Terms
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/InfoRestaurant"
          >
            Info for Restaurants
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/infouser"
          >
            Info for users
          </Link>
          <Link
            className="hover:text-[#ffb643]"
            style={{ cursor: "pointer" }}
            to="/contact"
          >
            Contact
          </Link>
        </div>
      </div>
      <div className="sm:bg-black bg-white   p-6 flex justify-center md:bg-white sm:text-white  md:text-black">
        <div>Copyright © 2023 WhereYouEat.com - All Rights Reserved</div>
      </div>
    </div>
  );
}
