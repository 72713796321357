import React, { useEffect } from "react";
import About from "../common/components/about";
import Header from "../common/components/header";
import MainBottom from "../common/components/mainBottom";

export default function Term() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="border-t-2 border-[#990000]"></div>
      <div className="container mx-auto p-2 ">
        <div className="lg:px-24 leading-normal   ">
          <h1 className="font-bold text-lg mt-5">Terms</h1>
          <div className="font-bold">Terms of Services</div>
          <div className="font-bold">Introduction and Eligibility</div>
          <div>
            Please read these Terms of Service (“Terms”) carefully before using
            the WhereYouEat Service.
          </div>
          <div>
            These Terms include WhereYouEat Privacy Policy, which is
            incorporated by reference into these Terms. We have included several
            annotations in boxes to help explain the legal jargon and emphasize
            key sections. These annotations are are for convenience only and
            have no legal or contractual effect.
          </div>
          <div>
            WhereYouEat Terms of Service is a legally binding agreement. As
            WhereYouEat evolves and improves, we may make changes to the Terms
            of Service. When we make changes, we’ll let you know on our
            homepage.
          </div>
          <div>
            <div className="font-bold">Binding Agreement.</div>
            <div>
              {" "}
              These Terms constitute a binding agreement between you and
              WhereYouEat Inc. and its affiliates, subsidiaries, and
              WhereYouEat.com (“WhereYouEat,” “we,” “us”). “You” and “users”
              shall mean all visitors to the WhereYouEat Service. You accept
              these Terms each time you access the WhereYouEat Service or
              WhereYouEat.com. If you do not accept these Terms, you must not
              use the WhereYouEat Service or WhereYouEat.com.
            </div>
          </div>
          <div>
            <div className="font-bold">Revisions to Terms.</div>
            <div>
              {" "}
              We may revise these Terms at any time by posting an updated
              version to this web page. You should visit this page periodically
              to review the most current Terms, because you are bound by them.
              Your continued use of the WhereYouEat Service after a change to
              these Terms constitutes your binding acceptance of these Terms.
            </div>
          </div>
          <div>
            <div className="font-bold">Children.</div>
            <div>
              {" "}
              No part of the WhereYouEat Service is directed to persons under
              the age of 13. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE
              OR ACCESS The WhereYouEat Service AT ANY TIME OR IN ANY MANNER. If
              you are under 18 years of age, you represent and agree that you
              possess the legal consent of your parent or guardian to access and
              use the WhereYouEat Service.
            </div>
          </div>
          <div>
            The terms “post” or “posting” as used in these Terms shall mean the
            act of submitting, uploading, publishing, displaying, or similar
            action on the WhereYouEat Service.
          </div>
          <div className="font-bold">The WhereYouEat Service</div>
          <div>
            The WhereYouEat Service is defined as any website, mobile
            application, or Internet service under WhereYouEat control, whether
            partial or otherwise, in connection with providing the services
            provided by WhereYouEat. The WhereYouEat Service allows you to
            browse and discover local restaurant pictures, menus and openings.
          </div>
          <div className="font-bold">Communications</div>
          <div>
            WhereYouEat may communicate with you by email or posting notice on
            the WhereYouEat homepage. You may request that we provide notice of
            any security breaches in writing.
          </div>
          <div>
            You agree to receive email from us at the email address you provided
            to us for customer service related purposes.
          </div>
          <div className="flex">
            <div className="font-bold">Electronic Notices.</div>
            <div>
              By using the WhereYouEat Service or providing Personal Information
              to us, you agree that we may communicate with you electronically
              regarding security, privacy, and administrative issues relating to
              your use of the WhereYouEat Service. If we learn of a security
              system’s breach, we may attempt to notify you electronically by
              posting a notice on the WhereYouEat Service or sending an email to
              you. You may have a legal right to receive this notice in writing.
              To receive free written notice of a security breach (or to
              withdraw your consent from receiving electronic notice), please
              write to us at Alex@whereyoueat.com
            </div>
          </div>
          <div className="font-bold">WhereYouEat Content Ownership and Use</div>
          <div>
            WhereYouEat owns all of the content we create, but you may use it
            while you use the WhereYouEat Service. Don’t use our logo, unless,
            of course, you have our written permission.
          </div>
          <div>
            The contents of the WhereYouEat Service include: designs, text,
            graphics, images, video, information, logos, button icons, software,
            audio files, computer code, and other WhereYouEat content
            (collectively, “WhereYouEat Content”). All WhereYouEat Content and
            the compilation (meaning the collection, arrangement, and assembly)
            of all WhereYouEat Content are the property of WhereYouEat or its
            licensors and are protected under copyright, trademark, and other
            laws.
          </div>
          <div className="flex">
            <div className="font-bold">License to You.</div>
            <div>
              We authorize you, subject to these Terms, to access and use the
              WhereYouEat Service and the WhereYouEat Content solely for the use
              of WhereYouEat services, at our discretion. Any other use is
              expressly prohibited. This license is revocable at any time
              without notice and with or without cause. Unauthorized use of the
              WhereYouEat Content may violate copyright, trademark, and
              applicable communications regulations and statutes and is strictly
              prohibited. You must preserve all copyright, trademarks, service
              marks, and other proprietary notices contained in the original
              WhereYouEat Content on any copy you make of the WhereYouEat
              Content.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Where You Eat Marks.</div>
            <div>
              All WhereYouEat logos and product and service names are or may be
              trademarks of WhereYouEat (the “WhereYouEat Marks”). Without our
              prior written permission, and except as solely enabled by any link
              as provided by us, you agree not to display or use in any manner
              the WhereYouEat Marks.
            </div>
          </div>
          <div className="font-bold">
            Intellectual Property Rights and Your License to Use
          </div>
          <div>
            You, or the people who allow you to use their content, own all of
            the content you post to the WhereYouEat Service. However, we may use
            it for any purpose, including in our marketing materials. We may
            also modify your content to make it work better. It is very
            important that you have permission to use other people’s content or
            they may be able to sue you for violating their legal rights.
          </div>
          <div className="flex">
            <div className="font-bold">WhereYouEat Claims No Ownership. </div>
            <div>
              The WhereYouEat Service may provide you with the ability to create
              or post content (“Your User Content”). WhereYouEat claims no
              ownership over Your User Content. You or a third-party licensor,
              as appropriate, retain all copyright, patent, and trademark rights
              to any of the content you post on or through the WhereYouEat
              Service. You are responsible for protecting those rights. You also
              acknowledge and agree that Your User Content is non-confidential
              and non-proprietary.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              WhereYouEat Use of Posted Content on the WhereYouEat Service.{" "}
            </div>
            <div>
              By creating, posting, or sharing Your User Content on or through
              the WhereYouEat Service, and subject to WhereYouEat Privacy
              Policy, you grant WhereYouEat a world-wide, non-exclusive,
              royalty-free license to use, modify, remove, publish, transmit, or
              display Your User Content for any purpose, including for the
              purpose of promoting WhereYouEat and its services. You waive any
              rights you may have regarding Your User Content being altered or
              manipulated in any way that may be objectionable to you.
              WhereYouEat will discontinue this licensed use within a
              commercially reasonable period after Your User Content is removed
              from the WhereYouEat Service. WhereYouEat reserves the right to
              refuse to accept, post, display, or transmit any User Content in
              its sole discretion.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              You Acquire No Ownership of Others’ Content.{" "}
            </div>
            <div>
              You understand and agree that you will not obtain, as a result of
              your use of the WhereYouEat Service, any right, title, or interest
              in or to such content delivered via the WhereYouEat Service or in
              any intellectual property rights (including, without limitation,
              any copyrights, patents, trademarks, trade secrets, or other
              rights) in the content.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">
              You Must Have Rights to the Content You Post.{" "}
            </div>
            <div>
              You represent and warrant that: (i) you own the content posted by
              you on or through the WhereYouEat Service or otherwise have the
              right to grant the license set forth in these Terms, (ii) the
              posting and use of Your User Content on or through the WhereYouEat
              Service does not violate the privacy rights, publicity rights,
              contract rights, intellectual property rights (including
              copyrights), or any other rights of any person, and (iii) the
              posting of Your User Content on the WhereYouEat Service does not
              result in a breach of contract between you and a third party. You
              agree to pay for all royalties, fees, and any other monies owing
              any person by reason of content you post on or through the
              WhereYouEat Service.
            </div>
          </div>
          <div>
            The WhereYouEat Service contains content from users and other
            WhereYouEat licensors. Except as provided within these Terms, you
            may not copy, modify, translate, publish, broadcast, transmit,
            distribute, perform, display, or sell any content appearing on or
            through the WhereYouEat Service.
          </div>
          <div className="font-bold text-lg">Copyright Policy</div>
          <div>
            Let us know if you think a user has violated your copyright using
            the WhereYouEat Service, or if you think someone incorrectly
            reported that you violated his or her copyright.
          </div>
          <div>
            The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
            recourse for copyright owners who believe that material appearing on
            the Internet infringes their rights under U.S. copyright law. If you
            believe in good faith that materials posted on the WhereYouEat
            Service infringe your copyright, you (or your agent) may send
            WhereYouEat a “Notification of Claimed Infringement” requesting that
            the material be removed, or access to it blocked. The notice must
            include the following information:
          </div>
          <div>
            1. A physical or electronic signature of a person authorized to act
            on behalf of the owner of the works that have been allegedly
            infringed;
          </div>
          <div>
            2. Identification of the copyrighted work alleged to have been
            infringed (or if multiple copyrighted works located on the
            WhereYouEat Service are covered by a single notification, a
            representative list of such works);
          </div>
          <div>
            3. Identification of the specific material alleged to be infringing
            or the subject of infringing activity, and information reasonably
            sufficient to allow WhereYouEat to locate the material on the
            WhereYouEat Service;
          </div>
          <div>
            4. Your name, address, telephone number, and email address (if
            available);
          </div>
          <div>
            5. A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </div>
          <div>
            6. A statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
          </div>
          <div>
            If you believe in good faith that a notice of copyright infringement
            has been wrongly filed against you, the DMCA permits you to send
            WhereYouEat a counter-notice.
          </div>
          <div className="font-bold text-lg">Suggestions and Submissions</div>
          <div>
            WhereYouEat welcomes your suggestions! If you’re happy, we’re happy.
            Please email us at alex@whereyoueat.com if you have any suggestions
            or comments. Use it, if you please! We disclaim: suggestions are
            anonymous and we may use them to improve WhereYouEat services
            without notice, compensation, or attribution.
          </div>
          <div>
            We appreciate hearing from our users and welcome your comments
            regarding the WhereYouEat Service. Please be advised, however, that
            we do not accept or consider creative ideas, suggestions,
            inventions, or materials (“creative ideas”) other than those which
            we have specifically requested. While we value your feedback on our
            services, please be specific in your comments and do not submit
            creative ideas. If, despite this request, you send us creative
            ideas, we:
          </div>
          <div>
            1. Shall own, exclusively, all now known or later discovered rights
            to the creative ideas;
          </div>
          <div>
            2. Shall not be subject to any obligation of confidentiality and
            shall not be liable for any use or disclosure of any creative ideas;
            and
          </div>
          <div>
            3. Shall be entitled to unrestricted use of the creative ideas for
            any purpose whatsoever, commercial or otherwise, without
            compensation to you or any other person.
          </div>
          <div className="font-bold text-lg">
            User Content Disclaimers, Limitations, and Prohibitions
          </div>
          <div>
            We do not represent or guarantee the truthfulness, accuracy, or
            reliability of content, posted by other users and third parties, and
            explicitly disclaim any representations or warranties regarding the
            accuracy of restaurant menus viewable through the WhereYouEat
            Service. You accept that any reliance on material posted by third
            parties or third-party service providers will be at your own risk.
          </div>
          <div>A. Prohibited WhereYouEat Service Uses</div>
          <div>
            Don’t do bad things with the WhereYouEat Service. Don’t do bad
            things to the WhereYouEat Service. Thank you kindly.
          </div>
          <div>
            You agree to use the WhereYouEat Service only for its intended
            purpose. You must use the WhereYouEat Service in compliance with all
            privacy, data protection, intellectual property, and other
            applicable laws. The following uses of the WhereYouEat Service are
            prohibited. You may not:
          </div>
          <div>1. Aggregate, copy, or duplicate any WhereYouEat Content;</div>
          <div>
            2. Use data mining, robots, or other data gathering devices on the
            WhereYouEat Service{" "}
          </div>
          <div>
            3. Remove, disable, damage, circumvent, or otherwise interfere with
            the security of the WhereYouEat Service;
          </div>
          <div>
            4. Interfere or attempt to interfere with the proper working of the
            WhereYouEat Service;
          </div>
          <div>
            5. Gain unauthorized access to the WhereYouEat Service or computers
            linked to the WhereYouEat Service;
          </div>
          <div>
            6. Attempt to reverse engineer, or reverse engineer the WhereYouEat
            Service;
          </div>
          <div>
            7. Frame or link to the WhereYouEat Service without permission;
          </div>
          <div>8. Attempt to or submit a virus to the WhereYouEat Service;</div>
          <div>
            9. Take any action imposing an unreasonable or disproportionately
            large load on WhereYouEat infrastructure;
          </div>
          <div>
            10. Attempt to or obtain unauthorized access to other users’
            accounts;
          </div>
          <div>
            11. Sell, transfer, or assign any of your rights to use the
            WhereYouEat Service to a third party without our express written
            consent;
          </div>
          <div>
            12. Use the WhereYouEat Service in a manner that results in or may
            result in complaints, disputes, claims, fines, penalties, and other
            liability to WhereYouEat or others;
          </div>
          <div>
            13. Use the WhereYouEat Service in an illegal way or to commit an
            illegal act; or
          </div>
          <div>
            14. Access the WhereYouEat Service from a jurisdiction where it is
            illegal, unauthorized, or penalized.
          </div>
          <div className="text-lg font-bold">
            Consequences of Violating These Terms
          </div>
          <div>
            If you violate these terms, we may refuse to provide the WhereYouEat
            Service to you.
          </div>
          <div>
            We reserve the right to suspend or terminate your account and
            prevent access to the WhereYouEat Service for any reason, at our
            discretion. We reserve the right to refuse to provide the
            WhereYouEat Service to you in the future. Perpetual licenses granted
            by you to WhereYouEat remain in full force and effect.
          </div>
          <div>
            You are responsible for any claims, fees, fines, penalties, and
            other liability incurred by us or others caused by or arising out of
            your breach of these Terms and your use of the WhereYouEat Service.
          </div>
          <div className="font-bold text-lg">WhereYouEat Liability</div>
          <div>
            We’re always working to improve WhereYouEat services. As we grow we
            may make changes to the WhereYouEat Service. These changes may occur
            at any time and without notice
          </div>
          <div className="flex">
            <div className="font-bold text-lg">
              Changes to the WhereYouEat Service.
            </div>
            <div>
              We may change, suspend, or discontinue any aspect of the
              WhereYouEat Service at any time, including hours of operation or
              availability of the WhereYouEat Service or any feature, without
              notice or liability.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold text-lg">User Disputes.</div>
            <div>
              We are not responsible for any disputes or disagreements between
              you and any third party you interact with using the WhereYouEat
              Service. You assume all risk associated with dealing with third
              parties. You agree to resolve disputes directly with the other
              party. You release WhereYouEat of all claims, demands, and damages
              in disputes among users of the WhereYouEat Service. You also agree
              not to involve us in such disputes. Use caution and common sense
              when using the WhereYouEat Service.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold text-lg">Content Accuracy.</div>
            <div>
              We make no representations about accuracy, reliability,
              completeness, or timeliness of any contents of the WhereYouEat
              Service, including menu item availability or pricing. Similarly,
              we make no representations about accuracy, reliability,
              completeness, or timeliness of any data from third-party service
              provider or the quality or nature of third-party products or
              services obtained through the WhereYouEat Service. Use the
              WhereYouEat Service at your own risk.
            </div>
          </div>
          <div className="flex">
            <div className="font-bold text-lg">Third-Party Websites.</div>
            <div>
              The WhereYouEat Service may include links to third party websites
              and applications. You are responsible for evaluating whether you
              want to access or use them. We are not responsible for and does
              not endorse any features, content, advertising, products, or other
              materials on other websites or applications. You assume all risk
              and we disclaim all liability arising from your use of them.
            </div>
          </div>
          <div>
            We make no promises and disclaim all liability of specific results
            from the use of the WhereYouEat Service.
          </div>
          <div className="flex">
            <div className="font-bold text-lg">Released Parties Defined.</div>
            <div>
              “Released Parties” include WhereYouEat and its affiliates,
              officers, employees, agents, partners, and licensors.
            </div>
          </div>
          <div>A. DISCLAIMER OF WARRANTIES</div>
          <div>
            We are not responsible for anything WhereYouEat users do when using
            the WhereYouEat Service. You use the WhereYouEat Service at your own
            risk. We make no warranties or guarantees.
          </div>
          <div>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE
            WHEREYOUEAT SERVICE IS AT YOUR SOLE RISK, AND THE WHEREYOUEAT
            SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND THE
            RELEASED PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            WARRANTIES AS TO MENU ITEMS OFFERED BY RESTAURANTS LISTED ON THE
            WHEREYOUEAT SERVICE OR RESULTS THAT MAY BE OBTAINED BY RESTAURANTS
            AS A RESULT OF BEING LISTED ON THE WHEREYOUEAT SERVICE, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i)
            THE WHEREYOUEAT SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE
            WHEREYOUEAT SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
            THE WHEREYOUEAT SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE
            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
            OBTAINED BY YOU THROUGH THE WHEREYOUEAT SERVICE WILL MEET YOUR
            EXPECTATIONS, OR (v) ANY ERRORS IN THE SERVICE WILL BE CORRECTED;
            AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE WHEREYOUEAT SERVICE IS ACCESSED AT YOUR OWN DISCRETION
            AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM
            THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.
          </div>
          <div>B. LIMITATION OF LIABILITY AND INDEMNIFICATION</div>
          <div>
            We are not responsible for any outcome of using the WhereYouEat
            Service. If you use the WhereYouEat Service in a way that causes us
            to be included in litigation, you agree to pay all of our legal fees
            and costs.
          </div>
          <div>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES SHALL
            NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES (EVEN IF WHEREYOUEAT HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE
            THE WHEREYOUEAT SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE
            GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR
            SERVICES OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
            THROUGH, FROM, OR AS A RESULT OF THE WHEREYOUEAT SERVICE; (iii)
            UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
            (iv) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON THE
            WHEREYOUEAT SERVICE; (v) YOUR RELIANCE ON CONTENT MADE AVAILABLE BY
            US; OR (vi) ANY OTHER MATTER RELATING TO THE WHEREYOUEAT SERVICE.
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
            OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN
            THIS PARAGRAPH MAY NOT APPLY TO YOU.
          </div>
          <div>
            TO THE FULLEST EXTENT POSSIBLE BY LAW, OUR MAXIMUM LIABILITY ARISING
            OUT OF OR IN CONNECTION WITH THE WHEREYOUEAT SERVICE OR YOUR USE OF
            WHEREYOUEAT CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN
            CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED
            $100.
          </div>
          <div>
            You agree to defend, indemnify, and hold harmless the Released
            Parties from and against any claims, actions, or demands, including
            without limitation reasonable legal and accounting fees, alleging or
            resulting from (i) your use of or reliance on any third-party
            content, (ii) your use of or reliance on any WhereYouEat Content, or
            (iii) your breach of these Terms. We shall provide notice to you
            promptly of any such claim, suit, or proceeding.
          </div>
          <div className="font-bold">General Terms</div>
          <div>
            These Terms constitute the entire agreement between you and
            WhereYouEat concerning your use of the WhereYouEat Service. Our
            failure to exercise or enforce any right or provision of these Terms
            shall not constitute a waiver of such right or provision. If any
            provision of these Terms is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties’ intentions as
            reflected in the provision, and the other provisions of these Terms
            remain in full force and effect. The section titles and annotations
            in these Terms are for convenience only and have no legal or
            contractual effect.
          </div>
          <div className="font-bold">
            Arbitration, Class Waiver, and Waiver of Jury Trial
          </div>
          <div>
            We are located in New York, so all disputes must be resolved there.
            We will use arbitration to resolve any problems, and you cannot join
            a class action lawsuit or obtain a jury trial for any disputes you
            have with us related to your use of the WhereYouEat Service.
          </div>
          <div>
            These Terms and the relationship between you and WhereYouEat shall
            be governed by the laws of the state of New York without regard to
            its conflict of law provisions. You and WhereYouEat agree to submit
            to the personal and exclusive arbitration of any disputes relating
            to your use of the WhereYouEat Service under the rules of the
            American Arbitration Association. Any such arbitration, to the
            extent necessary, shall be conducted in New York County, New York.
            You covenant not to sue WhereYouEat in any other forum.
          </div>
          <div>
            You also acknowledge and understand that, with respect to any
            dispute with the Released Parties arising out of or relating to your
            use of the Service
          </div>
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You"/>
      <About />
    </>
  );
}
