import React, { useState } from "react";
import About from "../common/components/about";
import Dining from "../common/components/dining";
import Header from "../common/components/header";
import LocationPage from "../common/components/locationPage";
import MainBottom from "../common/components/mainBottom";
import RealMenu from "../common/components/realMenu";
import "./css/style.css";
export default function GetStart() {
  return (
    <div className=" ">
      <Header />
      <div className="border-t-2   border-[#990000]"></div>
      <div className="bg-slate-200 shadow-black  ">
        <div className="my-element">
          <div className=" text-center lg:py-14
          "></div>

          <LocationPage />
          <Dining />
        </div>
      </div>
      <RealMenu />
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </div>
  );
}
