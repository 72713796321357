import React, { useEffect, useState } from "react";
import Header from "./header";
import MainBottom from "./mainBottom";
import About from "./about";
import { useNavigate, useParams } from "react-router-dom";
import Close from "../../img/noimage.jpg";
import { BASE_URL, token } from "../../component/token";
export default function NewOpening() {
  const navigate = useNavigate();
  let params = useParams();
  const cityName = params?.cityName;
  const [newOpeningResturant, setNewOpeningResturant] = useState([]);
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/cityRestaurantByNewOpenings`, {
      method: "POST",
      body: JSON.stringify({ city: "staten island" }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNewOpeningResturant(data?.result.restaurants);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div>
      <Header />
      <div className="border-b border-[#990000]"></div>
      <div className="my-elements">
        <div className=" container mx-auto p-10 lg:w-[1170px]  bg-[#f0f0f0] grid lg:grid-cols-2  lg:gap-12 gap-7">
          {newOpeningResturant.length > 0 ? (
            <>
              {newOpeningResturant.map((newres) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        const modifiedPanelName = newres.rname
                          .replace(/\W/g, "")
                          .toLowerCase();
                        navigate(`/menu/-${newres.id}-${modifiedPanelName}`);
                      }}
                      className="flex h-[170px] cursor-pointer  "
                    >
                      <div className="hover:bg-white w-full flex p-1">
                        {newres.image ? (
                          <>
                            <img
                              className=" h-[170px]  lg:w-[250px] w-full  bg-contain "
                              src={newres.image}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              className="  h-[170px]  lg:w-[250px] w-full bg-contain "
                              src={Close}
                            />
                          </>
                        )}
                        <div className="ml-2 py-2 w-full  ">
                          <div className="text-xl text-[#88001b] font-normal ">
                            {newres.rname}
                          </div>
                          <div className="text-[#505050] text-[14px]">
                            {newres.address}
                          </div>
                          <div className="flex justify-end mt-2">
                            <img src={newres.openClose} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <MainBottom />
      <About />
    </div>
  );
}
