import React from "react";
import Header from "./header";
import MainBottom from "./mainBottom";
import NoImage from "../../img/noimage.jpg";
import About from "./about";

export default function Account() {
  return (
    <>
      <Header />
      <div className="container mx-auto shadow-2xl p-2 mb-2   ">
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-5 mt-5 ">
          <div className="cursor-pointer border border-red-900 rounded-sm h-10 w-1/1  text-center p-2">
            FAVORITES(4)
          </div>
          <div className="cursor-pointer border border-red-900 rounded-sm h-10 w-1/1  text-center p-2">
            TRY LIST (7)
          </div>
          <div className="cursor-pointer border border-red-900 rounded-sm h-10 w-1/1  text-center p-2">
            REVIEWS (1)
          </div>
          <div className="cursor-pointer border border-red-900 rounded-sm h-10 w-1/1  text-center p-2">
            PHOTOS (1)
          </div>
        </div>
        <div className="lg:flex  justify-between  lg:mb-10 mt-2 mb-10 border-b border-slate-300 p-2 items-center  ">
          <div className="lg:flex items-center gap-4 lg:mb-2 mb-10">
            <img className="h-64 lg:w-1/2 w-full" src={NoImage} />
            <div>
              <div className="font-bold text-2xl leading-8 mt-2">
                Ali Baba Grill's
              </div>
              <div className="text-slate-400 leading-10">
                3271 richmond avenue
              </div>
              <div>
                <div className="flex gap-2 ">
                  <div className=" hover:bg-blue-800 text-white text-center bg-red-900 p-2 w-40 h-10 ">
                    Write Review
                  </div>
                  <div className=" hover:bg-red-900 text-center text-white bg-blue-900 p-2 w-40 h-10 ">
                    Move to Favorites
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-10  ">
            <div className="" >
              <div className="">
                <svg
                  className="h-10 w-10"
                  fill="#88001b"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </div>
              <div className="text-center ">Move Down </div>
            </div>
            <div className="">
              <svg
                className="h-10 w-10 "
                fill="#88001b"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
              </svg>
              <div className="text-center ">Move Up</div>
            </div>
            <div>
              <svg
                className="h-10 w-10"
                fill="#88001b"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
              <div className="text-center ">Remove</div>
            </div>
          </div>
        </div>
      </div>
      <MainBottom headingText="Search for Restaurants Near You" />
      <About />
    </>
  );
}
