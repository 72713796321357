import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { BASE_URL, token } from "../../../component/token";

export default function HeaderichmondAvenue() {
  const navigate = useNavigate();
  const [cuisines, setCuisines] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [showInputId, setShowInputId] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [isScrolling, setIsScrolling] = useState(false);
  const [searchCity, setSearchCity] = useState([]);
  const [cities, setCities] = useState([]);
  const [updateCity, setUpdateCity] = useState([]);
  const [news, setNews] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const cityName = localStorage.getItem("cityName");
  if (cityName !== null) {
    console.log(`The cityName from local storage is: ${cityName}`);
  } else {
    console.log("cityName not found in local storage");
  }

  const handleButtonClick = (id) => {
    setShowInputId(id);
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    window.location.href = selectedOption;
  };
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/getCity`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCities(data?.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${BASE_URL}/wye-api/cuisineFeatureService`, {
      method: "POST",
      body: JSON.stringify({ city: "all", updateCity }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCuisines(data?.result?.cusine);
        setStoreData(data?.result?.feature);
        setServiceData(data?.result?.service);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const CityRestaurants = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getCityRestaurants`, {
        method: "POST",
        body: JSON.stringify({ city: "all" }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const jsonData = await response.json();
      setNews(jsonData?.result.whatsNew);
    } catch (error) {}
  };
  useEffect(() => {
    CityRestaurants();
  }, []);
  const handleNum = async (event) => {
    const value = event.target.value;
    const sanitizedInput = value.replace(/[^\w\s]/gi, "");
    setSearchCity(sanitizedInput);
    if (value.length < 3) {
      setSearchData([]);
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/wye-api/getRestaurantByName`, {
        method: "POST",

        body: JSON.stringify({
          keyword: searchCity,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Add any other headers you need
        },
      });
      const data = await response.json();
      setSearchData(data.result.restaurant);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  useEffect(() => {
    handleNum();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="">
      <div className=" container mx-auto  lg:w-[1170px]   ">
        <div className=" px-2 w-full grid md:grid-cols-3 grid-cols-1 items-center  gap-2      ">
          <div className="flex gap-2 items-center  ">
            <div className="    ">
              <button
                onClick={() => handleButtonClick(1)}
                className="text-[#252424] text-left "
              >
                Nearby Search
              </button>
            </div>
            <select className="" onChange={handleSelectChange}>
              <option className="">City Dining Guide</option>
              <option
                className=" border border-b border-black"
                value="https://reactjs.orderingspace.net/city/staten-island"
              >
                Staten Island
              </option>
              <option
                className="border-b border-black"
                value="https://reactjs.orderingspace.net/city/brooklyn"
              >
                Brooklyn
              </option>
              <option
                className=" border-b border-black"
                value="https://reactjs.orderingspace.net/city/queens"
              >
                Queens
              </option>
              <option
                className=" border-b border-black"
                value="https://reactjs.orderingspace.net/city/the-bronx"
              >
                The Bronx
              </option>
              <option
                className=" border-b border-black"
                value="https://reactjs.orderingspace.net/city/manhattan"
              >
                Manhattan
              </option>
            </select>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-2 lg:gap-3  py-2 ">
            <div className="menu-wrap bg-[#e7e7e7]  border shadow-2xl  h-14 lg:rounded-full rounded-md   flex justify-center items-center">
              <ul className="menu">
                <li className="menu-item  ">
                  <div className="flex justify-center items-center gap-2 ">
                    <a href="">Cuisine</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                    </svg>
                  </div>
                  <ul className="drop-menu  ">
                    {cuisines.map((panel) => (
                      <li className="drop-menu-item " key={panel.id}>
                        <a
                          className="mb-4"
                          onClick={() => {
                            const modifi = panel.name.replace(/\s/g, "");
                            const modified = "cuisine"
                              .replace(/\s/g, "")
                              .toLowerCase();
                            const url = `/cuisine/-${modifi}-${modified}-${cityName}-${panel.id}`;
                            navigate(url);
                            window.location.reload();
                          }}
                        >
                          {panel.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-wrap bg-[#e7e7e7]  border shadow-2xl  h-14 lg:rounded-full rounded-md   flex justify-center items-center">
              <ul className="menu">
                <li className="menu-item">
                  <div className="flex justify-center items-center gap-2">
                    <a href="">Features</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                    </svg>
                  </div>
                  <ul className="drop-menu">
                    {storeData.map((use) => (
                      <li
                        onClick={() => {
                          const modified = use.feature_name.replace(/\s/g, "");
                          const url = `/cuisine/-${modified}-feature-${cityName}-${use.id}`;
                          navigate(url);
                          window.location.reload();
                        }}
                        className="drop-menu-item"
                        key={use.id}
                      >
                        <a className="mb-4">{use.feature_name}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="menu-wrap bg-[#e7e7e7]  border shadow-2xl  h-14 lg:rounded-full rounded-md   flex justify-center items-center">
              <ul className="menu">
                <li className="menu-item">
                  <div className="flex justify-center items-center gap-2">
                    <a href="">Services</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                    </svg>
                  </div>
                  <ul className="drop-menu">
                    {serviceData.map((services) => (
                      <li className="drop-menu-item" key={services.id}>
                        <a
                          className="mb-4"
                          onClick={() => {
                            const modified = services.service_name.replace(
                              /\s/g,
                              ""
                            );
                            const url = `/cuisine/-${modified}-service-${cityName}-${services.id}`;
                            navigate(url);
                            window.location.reload();
                          }}
                        >
                          {services.service_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className=" ">
            <div className="flex  lg:w-[350px] w-full lg:px-2 lg:ml-10 md:px-2   ">
              <input
                value={searchCity}
                onChange={handleNum}
                className="border placeholder:text-slate-400 placeholder:text-sm outline-none p-2 border-black rounded-l w-full"
                placeholder="Filter For Menu items or Restaurant"
              />
              <div className=" w-16 p-1  bg-red-900 rounded-r border-black border-t border-b border-r justify-center items-center flex ">
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                </svg>
              </div>
              <div className="scrollable-list fixed mt-14   z-50 lg:w-[300px]  bg-white overflow-hidden ">
                <div className="">
                  {searchData.length > 3 && !isScrolling ? (
                    <>
                      {searchData.map((pk, index) => (
                        <>
                          <div
                            onClick={() => {
                              const modified = pk.rname.replace(/\s/g, "");
                              navigate(`/menu/-${pk.id}-${modified}`);
                              window.location.reload();
                            }}
                            className="cursor-pointer "
                          >
                            <div
                              className=" leading-6 text-center  font-bold text-red-900 "
                              key={index}
                            >
                              {pk.rname}
                            </div>
                            <div
                              className="  text-center border-b border-slate-500 leading-6 mt-2 text-slate-600 "
                              key={index}
                            >
                              {pk.address}
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="m">
            {showInputId === 1 && (
              <div className="flex   gap-2">
                <input
                  className=" border border-black p-2 h-8 rounded-md"
                  type="text"
                  placeholder="Enter Your Loaction"
                />
                <div className="bg-red-900 hover:bg-green-600 h-8 text-white w-24 flex justify-center items-center  rounded-md">
                  <button className="">Search</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
